import React, { useState, useEffect, ReactText } from 'react';
import { Button, Modal, Form, Select, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

const AddToSubstation = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [substations, setSubstations] = useState([]);
    const [substation, setSubstation] = useState(undefined);

    useEffect(() => {
        setHasSelected(props.hasSelected);
        setSelectedRowKeys(props.selectedRowKeys);
    }, [props]);

    useEffect(() => {
        getSubstationList();
    }, []);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    async function getSubstationList() {
        const resp: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.SUBSTATION.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        });
        if (resp.status === RESPONSE.OK) {
            setSubstations(resp.data.content);
        }
    }

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = (e: any) => {
        setVisible(false);
    };

    const onChange = (value: any) => {
        setSubstation(value);
    };

    function showNotification(title: any, message: any, duration: any) {
        notification.success({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.ADD_TO_SUBSTATION, undefined, {
            projectId: NySession.getProjectId(),
            substationId: substation,
            devices: selectedRowKeys,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                props.onPopupSave();
                setSelectedRowKeys([]);
                setHasSelected(false);
                setVisible(false);
                okNotification();
            }
        });
    };

    return (
        <React.Fragment>
            <Button disabled={!hasSelected} type="primary" onClick={showModal}>
                {geti18nText('devices.table.addToSubstation')}
            </Button>

            <Modal
                title={geti18nText('devices.table.addToSubstation')}
                visible={visible}
                onCancel={handleCancel}
                onOk={onSubmit}
                okText={geti18nText('devices.table.addToSubstation')}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Form.Item label={geti18nText('lights.edit.substation')}>
                        <Select
                            showSearch
                            onChange={onChange}
                            notFoundContent={geti18nText('app.default.dropdown')}
                            filterOption={(input: any, option: any) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {substations != null &&
                                substations.map((option: any) => (
                                    <Select.Option value={option.id}>{option.substationId}</Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default AddToSubstation;

import { CONSTANTS_REQ, ENUMS_LIGHT_STATUS, ENUMS_LOAD_TYPE } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { hasAnyProjectRole } from '../../utils/Utils';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import React, { ReactText, useState } from 'react';
import LightImport from './LightImport';
import LightExport from './LightExport';
import AddToDevice from './AddToDevice';
import SetActive from './SetActive';
import { LightEdit } from './edit';

const LightIndex = (props: any) => {
    const [num, setNum] = useState(0);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    const ExportImportButtons = () => {
        return (
            <React.Fragment>
                {React.createElement(LightImport, { onPopupSave: () => setNum(num + 1) })}
                {React.createElement(LightExport)}
            </React.Fragment>
        );
    };

    const rowSelectionModals = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        return (
            <React.Fragment>
                {React.cloneElement(<AddToDevice />, {
                    hasSelected,
                    selectedRowKeys,
                    selectedRows,
                    onPopupSave,
                    clearSelectedRowKeys,
                })}
                {React.cloneElement(<SetActive />, {
                    hasSelected,
                    selectedRowKeys,
                    selectedRows,
                    onPopupSave,
                    clearSelectedRowKeys,
                })}
            </React.Fragment>
        );
    };

    const rowSelectionModalsEmpty = () => {
        return <></>;
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('lights.table.header')}
            url={props.remapUrl == null ? CONSTANTS_REQ.LIGHT.LIST : props.remapUrl}
            addNewButtonText={geti18nText('lights.table.add')}
            addedButtons={canCreate() ? ExportImportButtons : LightExport}
            showRecordModal={true}
            modalComponent={LightEdit}
            selectOnSave={false}
            showRowSelection={canCreate()}
            fetchWhenChange={num}
            rowSelectionModal={canCreate() ? rowSelectionModals : rowSelectionModalsEmpty}
            buttonsClassName={'buttons-sticky'}
            hideNewButton={!canCreate()}
            setDefaultFilterValue={setDefaultFilterValue}
            scroll={{ y: 10000, x: 1600 }}
            columns={[
                {
                    title: geti18nText('lights.table.column.id'),
                    dataIndex: 'id',
                    width: '5%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('lights.table.column.loadType'),
                    dataIndex: 'loadType',
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        return <div>{geti18nText('enum.loadType.' + record.loadType)}</div>;
                    },
                    ...getColumnSearchOption(ENUMS_LOAD_TYPE()),
                },
                {
                    title: geti18nText('lights.table.column.sequencerSerial'),
                    dataIndex: ['sequencerSerial'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.factoryId'),
                    dataIndex: ['factoryId'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.substationId'),
                    dataIndex: ['substation', 'substationId'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.clsmId'),
                    dataIndex: ['device', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.type'),
                    dataIndex: 'type',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.power'),
                    dataIndex: 'power',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    align: 'right',
                },
                {
                    title: geti18nText('lights.table.column.tolerance'),
                    dataIndex: 'powerTolerance',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('lights.table.column.phase'),
                    dataIndex: 'phase',
                    width: '5%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('lights.table.column.numLights'),
                    dataIndex: 'numLights',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('lights.table.column.lat'),
                    dataIndex: 'lat',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('lights.table.column.lng'),
                    dataIndex: 'lng',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('lights.table.column.status'),
                    dataIndex: 'status',
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        return <div>{geti18nText('app.enum.light.status.' + record.status)}</div>;
                    },
                    ...getColumnSearchOption(ENUMS_LIGHT_STATUS()),
                },
                {
                    title: geti18nText('lights.edit.initialHoldS'),
                    dataIndex: 'initialHoldS',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                ...(!NySession?.getProjectInfo()?.parent?.id
                    ? [
                          {
                              title: geti18nText('lights.table.column.project'),
                              dataIndex: ['project', 'projectName'],
                              sorter: (a: any, b: any) => {},
                              ...getColumnSearch('string'),
                          },
                      ]
                    : []),
                {
                    title: geti18nText('devices.table.column.active'),
                    dataIndex: 'active',
                    width: '5%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default LightIndex;

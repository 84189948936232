import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
} from '@nybble/nyreact';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../utils/Constants';
import CompanyTierChangeEdit from './edit';

const CompanyTierChangeIndex = () => {
    const setDefaultFilterValue = () => {
        return [];
    };

    return (
        <NyDataTable
            url={CONSTANTS_REQ.COMPANY_TIER_CHANGE.LIST}
            headerTitle={geti18nText('companyTierChange.table.header')}
            buttonsClassName={'buttons-sticky'}
            setDefaultFilterValue={setDefaultFilterValue}
            showRecordModal={true}
            modalComponent={CompanyTierChangeEdit}
            hideNewButton={true}
            scroll={{ y: 10000, x: 800 }}
            columns={[
                {
                    title: geti18nText('companyTierChange.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('companyTierChange.table.column.company'),
                    dataIndex: ['company', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('companyTierChange.table.column.requestTier'),
                    dataIndex: ['requestTier'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('companyTierChange.table.column.currentTier'),
                    dataIndex: ['company', 'tier'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('companyTierChange.table.column.requestDate'),
                    dataIndex: ['requestDate'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(),
                    render: (text: any, record: any) => {
                        if (record.requestDate) {
                            return moment(record.requestDate).format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                },
                {
                    title: geti18nText('companyTierChange.table.column.confirmed'),
                    dataIndex: ['confirmed'],
                    width: '10%',
                    render: (text: any, record: { confirmed: any }) => {
                        if (record.confirmed) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                },
            ]}
        />
    );
};

export default CompanyTierChangeIndex;

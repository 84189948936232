import { geti18nText, NyLanguageSelector, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import logoIcon from './assets/images/logo.png';
import './assets/scss/index.scss';
import LayoutIndex from './components/layout';
import Login from './components/login';
import Register from './components/register';
import { APPLICATION_KEY, CONSTANTS_REQ } from './utils/Constants';
import { LoadEnums } from './utils/Enums';

const App = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        setLoading(true);
        await NySession.init();
        await LoadEnums();
        await LoadAppSettings();
        if (NySession.isUserAuthenticated()) {
            await LoadUser();
        }
        setLoading(false);
    };

    const LoadUser = async function () {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.USER.CURRENT, { fetchDetails: true }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                let data = result.data;
                let session = NyUtils.loadSession();
                session.user = data;
                session.user.access_token = NySession.getUserToken();
                if (session.user.roles != undefined) {
                    session.user.roles = session.user.roles.map((role: any) => {
                        return role.authority;
                    });
                }
                if (session.user.expires_in != undefined) {
                    session.user.expires_at = Date.now() + (session.user.expires_in - 5) * 1000;
                }
                NyUtils.saveSession(session);
            }
        });
    };

    const LoadAppSettings = async function () {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.GET_SETTINGS, { group: 'application' }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    let groupedSettings: any = {};
                    for (const setting of result.data) {
                        if (!groupedSettings[setting.settingGroup]) {
                            groupedSettings[setting.settingGroup] = {};
                        }
                        groupedSettings[setting.settingGroup][setting.settingKey] = {
                            id: setting.id,
                            value: setting.settingValue,
                        };
                    }
                    sessionStorage.setItem(APPLICATION_KEY + 'settings', JSON.stringify(groupedSettings));
                }
            }
        );
    };

    const LoginComponent = () => (
        <Login
            logo={
                <Divider orientation="center">
                    <img src={logoIcon} alt={geti18nText('app.title')} style={{ maxWidth: '290px' }} />
                </Divider>
            }
            languageSelector={
                NySession.getAppValue('I18N') != null &&
                NySession.getAppValue('I18N').language && (
                    <NyLanguageSelector
                        style={{ marginBottom: '10px' }}
                        languages={NySession.getAppValue('I18N').language.available}
                        selectedLanguage={NyUtils.getSelectedLanguage()}
                        onSelect={(lang: object) => {
                            NyUtils.saveDefaults(lang);
                        }}
                        reloadPage={true}
                    />
                )
            }
        />
    );

    const RegisterComponent = () => (
        <Register
            logo={
                <Divider orientation="center">
                    <img src={logoIcon} alt={geti18nText('app.title')} style={{ maxWidth: '290px' }} />
                </Divider>
            }
            languageSelector={
                NySession.getAppValue('I18N') != null &&
                NySession.getAppValue('I18N').language && (
                    <NyLanguageSelector
                        style={{ marginBottom: '10px' }}
                        languages={NySession.getAppValue('I18N').language.available}
                        selectedLanguage={NyUtils.getSelectedLanguage()}
                        onSelect={(lang: object) => {
                            NyUtils.saveDefaults(lang);
                        }}
                        reloadPage={true}
                    />
                )
            }
        />
    );

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={() => <LoginComponent />} />
                    <Route exact path="/register" component={() => <RegisterComponent />} />
                    <Route path="/" component={LayoutIndex} />
                </Switch>
            </HashRouter>
        );
    }
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import enUS from 'antd/lib/locale/en_US';
import hrHR from 'antd/lib/locale/hr_HR';
import { APPLICATION_KEY, URL_PREFIX } from './utils/Constants';
import { NySettings, NyUtils } from '@nybble/nyreact';
import { ConfigProvider } from 'antd';
import './assets/styles/theme.less';

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={NyUtils.getSelectedLocale() === 'hr-HR' ? hrHR : enUS}>
            <NySettings applicationKey={APPLICATION_KEY} urlPreffix={URL_PREFIX} />
            <App />
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

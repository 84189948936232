import { geti18nText, NyDataEdit, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { hasAnyProjectRole } from '../../utils/Utils';

const SubstationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('substations.edit.new'));
    const [loading, setLoading] = useState(false);
    const [geoChange, setGeoChange] = useState(1);
    const [latitude, setLatitude] = useState<any>(undefined);
    const [longitude, setLongitude] = useState<any>(undefined);
    const [nameTaken, setNameTaken] = useState(false);
    const [project, setProject] = useState(NySession.getProjectId());

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (geoChange > 0 && longitude != undefined && latitude != undefined) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.SUBSTATION.GET_ZONE, {
                lat: latitude,
                lng: longitude,
            }).then((response: any) => {
                if (response && response.status === RESPONSE.OK) {
                    if (response.data) {
                        form.setFieldsValue({ timezone: response.data.timeZone });
                    } else {
                        form.setFieldsValue({ timezone: undefined });
                    }
                }
            });
        }
    }, [latitude, longitude]);

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('substations.edit.substationId') + ': ' + dataForm.substationId + ' (' + dataForm.id + ')'
        );
        if (dataForm.project) {
            setProject(dataForm.project.id);
        }

        setGeoChange(0);
        if (dataForm.lat != undefined) setLatitude('' + dataForm.lat);
        if (dataForm.lng != undefined) setLongitude('' + dataForm.lng);
        setTimeout(() => {
            setGeoChange(1);
        }, 0);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    function validateNameTaken(rule: any, value: any, callback: any) {
        if (nameTaken) {
            callback(geti18nText('validation.substationId.taken'));
        } else {
            callback();
        }
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('substations.edit.new'));
        setLatitude(undefined);
        setLongitude(undefined);
        setGeoChange(1);
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            buttonsClassName={'buttons-sticky'}
            setLoading={setLoading}
            url={CONSTANTS_REQ.SUBSTATION.EDIT}
            setValues={setValues}
            onModalClose={onModalClose}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                return { ...values, project: { id: project } };
            }}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="company" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="project" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('substations.edit.substationId')}
                            name="substationId"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('validation.substationId'),
                                },
                                { validator: validateNameTaken },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('substations.edit.lat')}
                            name="lat"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('validation.lat'),
                                },
                            ]}
                        >
                            <Input onChange={(e: any) => setLatitude(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('substations.edit.lng')}
                            name="lng"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('validation.lng'),
                                },
                            ]}
                        >
                            <Input onChange={(e: any) => setLongitude(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('substations.edit.zone')}
                            name="timezone"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('validation.timezone'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default SubstationEdit;

import { geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Row } from 'antd';
import { useEffect } from 'react';
import { ENUMS_PROGRAM_INPUT, ENUMS_PROGRAM_OUTPUT } from '../../utils/Constants';

const IOMatrix = (props: any) => {
    const {
        inputs = ENUMS_PROGRAM_INPUT().map((input: any) => {
            return { inputType: input.id, alarm: false };
        }),
        setInputs = undefined,
        relays = ENUMS_PROGRAM_OUTPUT().map((relay: any) => {
            return { relayType: relay.id, hd: false };
        }),
        setRelays = undefined,
        inputRelays = [],
        setInputRelays = undefined,
        disabled = false,
        checkable = false,
    } = props;

    useEffect(() => {
        if (setInputs) {
            setInputs(inputs);
        }
        if (setRelays) {
            setRelays(relays);
        }
        if (setInputRelays) {
            setInputRelays(inputRelays);
        }
    }, []);

    const setInputAlarmChecked = (type: number, checked: boolean) => {
        let newInputs = [...inputs];
        for (var i = 0; i < newInputs.length; i++) {
            if (newInputs[i].inputType == type) {
                newInputs[i].alarm = checked;
                break;
            }
        }
        if (setInputs) {
            setInputs(newInputs);
        }
    };

    const setRelayHDChecked = (type: number, checked: boolean) => {
        let newRelays = [...relays];
        for (var i = 0; i < newRelays.length; i++) {
            if (newRelays[i].relayType == type) {
                newRelays[i].hd = checked;
                break;
            }
        }
        if (setRelays) {
            setRelays(newRelays);
        }
    };

    const setInputRelayChecked = (inputType: number, relayType: number, checked: boolean) => {
        let newInputRelays = [...inputRelays];
        let index = -1;
        for (var i = 0; i < newInputRelays.length; i++) {
            if (newInputRelays[i].inputType == inputType && newInputRelays[i].relayType == relayType) {
                index = i;
                break;
            }
        }
        if (checked && index == -1) {
            newInputRelays.push({ inputType: inputType, relayType: relayType });
        } else if (!checked && index != -1) {
            newInputRelays.splice(index, 1);
        }
        if (setInputRelays) {
            setInputRelays(newInputRelays);
        }
    };

    const getInputAlarmChecked = (type: number) => {
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].inputType == type) {
                return inputs[i].alarm;
            }
        }
        return false;
    };

    const getRelayHDChecked = (type: number) => {
        for (var i = 0; i < relays.length; i++) {
            if (relays[i].relayType == type) {
                return relays[i].hd;
            }
        }
        return false;
    };

    const getInputRelayChecked = (inputType: number, relayType: number) => {
        let newInputRelays = [...inputRelays];
        let index = -1;
        for (var i = 0; i < newInputRelays.length; i++) {
            if (newInputRelays[i].inputType == inputType && newInputRelays[i].relayType == relayType) {
                index = i;
                break;
            }
        }
        return index > -1;
    };

    return (
        <Row gutter={10} className="ant-form ant-form-vertical">
            <Col>
                <table className="iomatrix-table">
                    <tr>
                        <td></td>
                        {ENUMS_PROGRAM_OUTPUT().map((relay: any) => {
                            return <td>{relay.text}</td>;
                        })}
                        <td>{geti18nText('programs.edit.matrix.alarm')}</td>
                    </tr>
                    {ENUMS_PROGRAM_INPUT().map((input: any) => {
                        return (
                            <tr>
                                <td>{input.text}</td>
                                {ENUMS_PROGRAM_OUTPUT().map((relay: any) => {
                                    return (
                                        <td>
                                            <Checkbox
                                                key={'IR_' + input.id + '_' + relay.id}
                                                disabled={disabled}
                                                checked={getInputRelayChecked(input.id, relay.id)}
                                                onChange={(e: any) => {
                                                    setInputRelayChecked(input.id, relay.id, e.target.checked);
                                                }}
                                            ></Checkbox>
                                        </td>
                                    );
                                })}
                                <td>
                                    <Checkbox
                                        key={'I_' + input.id}
                                        disabled={disabled}
                                        checked={getInputAlarmChecked(input.id)}
                                        onChange={(e: any) => {
                                            setInputAlarmChecked(input.id, e.target.checked);
                                        }}
                                    ></Checkbox>
                                </td>
                            </tr>
                        );
                    })}
                    <tr style={{ borderTop: '1px solid #eeeeee' }}>
                        <td>{geti18nText('programs.edit.matrix.hd')}</td>
                        {ENUMS_PROGRAM_OUTPUT().map((relay: any) => {
                            return (
                                <td>
                                    <Checkbox
                                        key={'R_' + relay.id}
                                        disabled={disabled}
                                        checked={getRelayHDChecked(relay.id)}
                                        onChange={(e: any) => {
                                            setRelayHDChecked(relay.id, e.target.checked);
                                        }}
                                    ></Checkbox>
                                </td>
                            );
                        })}
                    </tr>
                </table>
            </Col>
        </Row>
    );
};

export default IOMatrix;

import React, { useEffect, useState } from 'react';
import 'moment/locale/hr';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Button, notification, Row, Table } from 'antd';
import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { downloadFile } from '../../utils/Utils';
import { iResponse } from '@nybble/nyreact/build/utils/Types';
import moment from 'moment';

export const DeviceLightHealthCheck = (props: any) => {
    const { addedData } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [powerDisabled, setPowerDisabled] = useState(false);
    const [performedHealthCheck, setPerformedHealthCheck] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    useEffect(() => {
        getLightData();
    }, []);

    const getLightData = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.LIGHT.HEALTH_CHECK_LIST, { ...props.addedData }).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.CREATED) {
                    setData(result.data);
                }
            }
        );
    };

    const performHealthCheck = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.LIGHT.PERFORM_HEALTH_CHECK, { ...props.addedData }).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.CREATED) {
                    setData(result.data);
                    setPerformedHealthCheck(true);
                } else {
                    if (result.data.error) {
                        error(JSON.stringify(result.data.error));
                    }
                }
            }
        );
    };

    const exportData = () => {
        setLoading(true);
        requestPost(CONSTANTS_REQ.LIGHT.EXPORT_HEALTH_CHECK, {
            lights: data,
            lang: NyUtils.getSelectedLanguage(),
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                const projectInfo = NySession.getProjectInfo();
                if (projectInfo) {
                    const fileName =
                        projectInfo.projectName +
                        '_' +
                        addedData.deviceName +
                        '_' +
                        moment().format('YYYYMMDD_HHmmss') +
                        '.xlsx';
                    downloadFile(result, fileName);
                } else {
                    downloadFile(result, 'report-xls.xlsx');
                }
            }
        });
    };

    const powerControl = (state: any) => {
        setLoading(true);
        // setPowerDisabled(true);
        // setTimeout(() => {
        //     setPowerDisabled(false);
        // }, 20000);
        requestPost(CONSTANTS_REQ.DEVICE.POWER_CONTROL, {
            id: props.addedData.deviceId,
            state: state,
        }).then((result: any) => {
            setLoading(false);
        });
    };

    const updatePhases = () => {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.LIGHT.UPDATE_PHASES, {}, { lights: selectedRows }, false).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    ok();
                }
            }
        );
    };

    function ok() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.healthcheck.nok'),
            description: message,
            duration: 0,
        });
    }

    const requestPost = async function (
        url: string,
        body: object | FormData | undefined = undefined
    ): Promise<iResponse> {
        let retValue = { status: 0, data: {} } as iResponse;
        try {
            let response = await fetch(url, FETCH_OBJECT('POST', body));

            retValue.status = response.status;
            try {
                let fileObject: { filename: string; file: Blob | undefined } = {
                    filename: '',
                    file: undefined,
                };
                retValue.data = { filename: '', file: '' };
                if (response.headers.get('Content-Disposition')) {
                    const responseHeader = response.headers.get('Content-Disposition');
                    if (responseHeader != null) {
                        fileObject.filename = responseHeader.split('filename=')[1];
                    }
                    if (fileObject.filename) {
                        fileObject.filename = fileObject.filename.replace(/\"/g, '');
                    }
                }
                fileObject.file = await response.blob();
                retValue.data = fileObject;

                return retValue;
            } catch (e) {
                console.log('Error while waiting for response');
                console.log(e);
            }
        } catch (error) {
            console.error('Error while Request method!!!');
            console.error(error);
            //             retValue.data = error;
        }
        return retValue;
    };

    const FETCH_OBJECT = (method: string, body: object | FormData | undefined) => {
        interface iRequest {
            method: string;
            headers: { [index: string]: any };
            body: FormData | string | undefined;
        }

        let ret = {
            method: method,
        } as iRequest;
        ret.headers = {
            'Content-Type': 'application/json',
        };

        if (NySession.getUserToken()) {
            ret.headers['Authorization'] = 'Bearer ' + NySession.getUserToken();
        }
        if (body) {
            ret.body = JSON.stringify(body);
        }
        return ret;
    };

    const columns = [
        {
            title: geti18nText('lights.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('lights.table.column.phase'),
            dataIndex: 'detectedPhase',
        },
        {
            title: geti18nText('lights.table.column.status'),
            dataIndex: 'detectedStatus',
            render: (text: any, record: any) => {
                return <div>{geti18nText('app.enum.light.healthCheck.status.' + record.detectedStatus)}</div>;
            },
        },
    ];

    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Row style={{ marginBottom: '10px' }}>
                <Button
                    key="healthCheck"
                    onClick={performHealthCheck}
                    style={{ marginRight: '10px' }}
                    disabled={loading}
                >
                    {geti18nText('lights.table.column.healthCheck')}
                </Button>
                <Button
                    disabled={!performedHealthCheck || loading}
                    key="export"
                    onClick={exportData}
                    style={{ marginRight: '10px' }}
                >
                    {geti18nText('lights.table.column.exportExcel')}
                </Button>
                <Button
                    disabled={!performedHealthCheck || selectedRowKeys.length == 0 || loading}
                    key="savePhases"
                    type="primary"
                    onClick={updatePhases}
                    style={{ marginRight: '10px' }}
                >
                    {geti18nText('lights.table.column.savePhase')}
                </Button>
            </Row>
            <Table
                rowKey={(record) => record.id}
                dataSource={data}
                columns={columns}
                size="small"
                pagination={false}
                rowSelection={rowSelection}
            />
        </>
    );
};

import { CONSTANTS_REQ, ENUMS_LIGHT_STATUS } from '../../utils/Constants';
import moment from 'moment';
import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import LightStatusActivityReport from './LightStatusActivityReport';
import { LightEdit } from '../light/edit';
import React, { useState } from 'react';

const LightStatusActivityList = (props: any) => {
    const [lightEdit, setLightEdit] = useState(false);
    const [clickedId, setClickedId] = useState<any>(undefined);

    function setDefaultFilterValue() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'light.project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    }

    return (
        <React.Fragment>
            <NyDataTable
                url={props.remapUrl == null ? CONSTANTS_REQ.LIGHT_STATUS_ACTIVITY.LIST : props.remapUrl}
                addedData={{
                    projectId: NySession.getProjectId(),
                }}
                onRowSelect={(item) => {
                    setClickedId(item.light.id);
                    setLightEdit(true);
                }}
                hideNewButton={true}
                addedButtons={LightStatusActivityReport}
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={{ y: 10000, x: 800 }}
                columns={[
                    {
                        title: geti18nText('lights.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('lights.table.column.lightId'),
                        dataIndex: ['light', 'id'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('lights.table.column.time'),
                        dataIndex: 'ts',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(),
                        render: (text: any, record: any) => {
                            if (record.ts_converted) {
                                return moment(record.ts).format('DD.MM.YYYY HH:mm:ss');
                            }
                        },
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(ENUMS_LIGHT_STATUS()),
                        render: (text: any, record: any) => {
                            return <div>{geti18nText('app.enum.light.status.' + record.status)}</div>;
                        },
                    },
                    ...(!NySession?.getProjectInfo()?.parent?.id
                        ? [
                              {
                                  title: geti18nText('lights.edit.project'),
                                  dataIndex: ['project', 'projectName'],
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnSearch('string'),
                              },
                          ]
                        : []),
                ]}
            />
            {React.createElement(
                LightEdit,
                {
                    isModal: true,
                    visible: lightEdit,
                    setVisible: setLightEdit,
                    value: clickedId ? { id: clickedId } : undefined,
                    onSave: () => window.location.reload(),
                    onSaveAndGetID: () => window.location.reload(),
                    onSaveAndGetData: () => window.location.reload(),
                    addedData: undefined,
                },
                null
            )}
        </React.Fragment>
    );
};

export default LightStatusActivityList;

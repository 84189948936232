import React, { useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, notification, Modal, Button } from 'antd';
import { CONSTANTS_REQ, ENUMS_LIGHT_STATUS, LIGHT_STATUS, LOAD_TYPE, ENUMS_LOAD_TYPE } from '../../utils/Constants';
import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { getEnumArray, getEnumFormat, hasAnyProjectRole, setEnumFormat, setSearchFormat } from '../../utils/Utils';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { MaskedInput } from 'antd-mask-input';

export const LightEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('lights.edit.new'));
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(NySession.getProjectId());
    const [newSequencerSerial, setNewSequencerSerial] = useState('');
    const [sequencerMobile, setSequencerMobile] = useState(false);
    const [sequencerVisible, setSequencerVisible] = useState(false);
    const [light, setLight] = useState(null);
    const [active, setActive] = useState(false);

    const [form] = Form.useForm();

    const validateFactoryId = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value.length == 1) {
            callback(geti18nText('validation.factoryId.short'));
        } else {
            callback();
        }
    };

    function setValues(light: any) {
        setEditHeader(geti18nText('enum.loadType.' + light.loadType) + ' (' + light.id + ')');
        setLight(light.id);
        setActive(light.active);

        if (light.status != null) {
            light.status = setEnumFormat('LIGHT_STATUS', light.status, 'app.enum.light.status');
        }

        if (light.loadType != null) {
            light.loadType = setEnumFormat('LOAD_TYPE', light.loadType, 'enum.loadType');
        }

        if (light.device) {
            light.device = setSearchFormat(light.device);
        }

        if (light.substation) {
            light.substation = setSearchFormat(light.substation, 'substationId');
        }

        if (light.sequencerMobile) {
            setSequencerMobile(light.sequencerMobile);
        }

        if (light.project) {
            setProject(light.project.id);
        }

        delete light.active;
        form.setFieldsValue(light);
    }

    function isCreate() {
        return props.value == undefined;
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('lights.edit.new'));
        setSequencerMobile(false);
        setNewSequencerSerial('');
        setActive(false);
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function openSequencerModal() {
        setSequencerVisible(true);
    }

    function closeSequencerModal() {
        setSequencerVisible(false);
    }

    function sequencerChange(e: any) {
        setNewSequencerSerial(e.target.value);
    }

    function addSequencer() {
        if (newSequencerSerial && props.value.id) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.LIGHT.SEQUENCER + '/' + props.value.id, undefined, {
                sequencerSerial: newSequencerSerial.replace(/\:/g, ''),
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    setSequencerMobile(false);
                    form.setFieldsValue({ sequencerSerial: newSequencerSerial });
                    closeSequencerModal();
                }
            });
        }
    }

    function deleteLights(e: any) {
        e.preventDefault();
        setLoading(true);
        let fileId = form.getFieldsValue(['id']);
        NyRequestResolver.requestDelete(CONSTANTS_REQ.LIGHT.DELETE + fileId['id'], undefined, { id: fileId }).then(
            (result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                    props.setVisible(false);
                    if (props.onSave) {
                        props.onSave();
                    }
                    form.resetFields();
                } else {
                    var msg = '';
                    if (result.data) {
                        if (result.data.error) {
                            msg = JSON.stringify(result.data.error);
                            msg = msg.replace('null', '');
                            if (result.data.error.toString().startsWith('constraint_violation_exception')) {
                                msg = msg
                                    .replace(
                                        'constraint_violation_exception',
                                        geti18nText('lights.delete.constraint.violation.exception')
                                    )
                                    .replace(':', '');
                            }
                        } else if (result.data.message) {
                            if (result.data.message.toString().includes('violates foreign key constraint')) {
                                msg = geti18nText('lights.delete.constraint.violation.exception');
                            } else {
                                msg = result.data.message;
                            }
                        }
                    }
                    if (msg.length > 0) {
                        error(msg);
                    }
                }
            }
        );
    }

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    function onStatusChange(status: any) {
        if (!status || status.id < 0) {
            form.setFieldsValue({ status: undefined });
        }
    }

    return (
        <>
            <Modal
                title={geti18nText('lights.edit.button.addSequencer')}
                visible={sequencerVisible}
                onCancel={closeSequencerModal}
                destroyOnClose={true}
                // footer={null}
                onOk={addSequencer}
                okText={geti18nText('app.default.button.add')}
            >
                <MaskedInput mask="**:**:**:**:**:**:**" size="large" onChange={sequencerChange} />
            </Modal>
            <NyDataEdit
                editHeader={editHeader}
                loading={loading}
                onModalClose={onModalClose}
                form={form}
                setLoading={setLoading}
                url={CONSTANTS_REQ.LIGHT.EDIT}
                setValues={setValues}
                layout="vertical"
                width={900}
                {...props}
                hideSubmitButton={props.hideDataEditButtons || !canCreate()}
                hideActivationButtons={props.hideDataEditButtons || !canCreate()}
                customButtons={
                    isCreate() || props.hideDataEditButtons || !canCreate() || active
                        ? [
                              {
                                  text: geti18nText('lights.edit.button.addSequencer'),
                                  loading: props.loading,
                                  style: { backgroundColor: '#3990db', color: 'white' },
                                  className: '',
                                  onClick: openSequencerModal,
                              },
                          ]
                        : [
                              {
                                  text: geti18nText('app.default.button.delete'),
                                  loading: props.loading,
                                  style: { backgroundColor: '#c70000', color: 'white' },
                                  className: '',
                                  onClick: deleteLights,
                                  addPopconfirm: true,
                                  popConfirmMsg: geti18nText('app.default.destructive.confirm'),
                                  popConfirmOk: geti18nText('app.default.button.delete'),
                                  popConfirmCancel: geti18nText('app.default.button.cancel'),
                              },
                          ]
                }
                normalize={(values: any) => {
                    return {
                        ...values,
                        sequencerSerial:
                            values.sequencerSerial != null ? values.sequencerSerial.replace(/\:/g, '') : null,
                        sequencerMobile: sequencerMobile,
                        device:
                            values.device != null && values.device.constructor === String
                                ? { id: parseInt(values.device) }
                                : values.device,
                        substation:
                            values.substation != null && values.substation.constructor === String
                                ? { id: parseInt(values.substation) }
                                : values.substation,
                        status: values.active === false ? LIGHT_STATUS.INACTIVE : getEnumFormat(values.status),
                        loadType: getEnumFormat(values.loadType),
                        project: { id: project },
                    };
                }}
            >
                <React.Fragment>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="company" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="project" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('lights.edit.factoryId')}
                                name="factoryId"
                                rules={[{ validator: validateFactoryId }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={geti18nText('lights.edit.sequencerSerial')} name="sequencerSerial">
                                <MaskedInput mask="**:**:**:**:**:**:**" size="large" disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={geti18nText('lights.edit.sequencerMobile')} name="sequencerMobile">
                                <Checkbox checked={sequencerMobile} disabled={true}></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('lights.edit.lat')}
                                name="lat"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('validation.lat'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('lights.edit.lng')}
                                name="lng"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('validation.lng'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.street')} name="street">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.town')} name="town">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.loadType')} name="loadType">
                                <NySearchField
                                    options={ENUMS_LOAD_TYPE()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.type')} name="type">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.power')} name="power">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={(value) => `${value}`.replace(/(\.[^]+)|([^0-9])/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('lights.edit.tolerance')}
                                name="powerTolerance"
                                initialValue={15}
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={(value) => `${value}`.replace(/(\.[^]+)|([^0-9])/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.oldType')} name="oldType">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.device')} name="device">
                                <NySearchField
                                    url={CONSTANTS_REQ.DEVICE.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedFilter={{
                                        field: 'project_id',
                                        condition: 'equals',
                                        value: project,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.substation')} name="substation">
                                <NySearchField
                                    url={CONSTANTS_REQ.SUBSTATION.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="substationId"
                                    disabled={false}
                                    addedFilter={{
                                        field: 'project_id',
                                        condition: 'equals',
                                        value: project,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.numLights')} name="numLights">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={(value) => `${value}`.replace(/(\.[^]+)|([^0-9])/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.regime')} name="regime">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.phase')} name="phase">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('lights.edit.initialHoldS')} name="initialHoldS">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={(value) => `${value}`.replace(/(\.[^]+)|([^0-9])/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('lights.edit.status')}
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('validation.status'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    options={ENUMS_LIGHT_STATUS()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    onChange={onStatusChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </React.Fragment>
            </NyDataEdit>
        </>
    );
};

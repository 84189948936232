import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Form, Input, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, ENUMS_COMPANY_TYPE } from '../../utils/Constants';
import { getEnumFormat, setEnumFormat } from '../../utils/Utils';

const CompanyRegistrationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [registrationId, setRegistrationId] = useState<any>(undefined);
    const [rejectionExplanation, setRejectionExplanation] = useState(undefined);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('companyRegistration.edit.name') + ': ' + dataForm.name + ' (' + dataForm.id + ')');
        setRegistrationId(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(undefined);
        setRegistrationId(undefined);
        setRejectionExplanation(undefined);
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    const approval = (approved: boolean) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.COMPANY_REGISTRATION.APPROVAL + registrationId, undefined, {
            id: registrationId,
            approved: approved,
            rejectionExplanation: rejectionExplanation,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                onModalClose();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
            } else {
                if (result.data && result.data.error) {
                    error(JSON.stringify(result.data.error));
                }
            }
            setLoading(false);
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.COMPANY_REGISTRATION.EDIT}
            setValues={setValues}
            onModalClose={onModalClose}
            hideSubmitButton={true}
            hideActivationButtons={true}
            customButtons={
                rejectionExplanation
                    ? [
                          {
                              text: geti18nText('app.default.button.reject'),
                              loading: props.loading,
                              style: { backgroundColor: '#ff4d4f', color: 'white' },
                              className: '',
                              onClick: () => approval(false),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.reject.confirm'),
                              popConfirmOk: geti18nText('app.default.button.reject'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                          {
                              text: geti18nText('app.default.button.approve'),
                              loading: props.loading,
                              style: { backgroundColor: 'green', color: 'white' },
                              className: '',
                              onClick: () => approval(true),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.approve.confirm'),
                              popConfirmOk: geti18nText('app.default.button.approve'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
                    : [
                          {
                              text: geti18nText('app.default.button.reject'),
                              loading: props.loading,
                              style: { backgroundColor: '#f5f5f5', color: 'rgba(0, 0, 0, 0.25)' },
                              className: '',
                          },
                          {
                              text: geti18nText('app.default.button.confirm'),
                              loading: props.loading,
                              style: { backgroundColor: 'green', color: 'white' },
                              className: '',
                              onClick: () => approval(true),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.confirm.confirm'),
                              popConfirmOk: geti18nText('app.default.button.confirm'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
            }
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                return { ...values };
            }}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.name')} name="name">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.vatNumber')} name="vatNumber">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.email')} name="email">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.firstName')} name="firstName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.lastName')} name="lastName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.address')} name="address">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.phone')} name="phone">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.requestDate')} name="requestDate">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyRegistration.edit.lang')} name="lang">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('companyRegistration.edit.rejectionExplanation')}
                            name="rejectionExplanation"
                        >
                            <Input onChange={(e: any) => setRejectionExplanation(e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default CompanyRegistrationEdit;

import {
    geti18nText,
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { Col, Form, Row, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CONSTANTS_REQ, DEVICE_ADDITIONAL_TYPE, ENUMS_DEVICE_ADDITIONAL_TYPE } from '../../utils/Constants';
import { setEnumFormat } from '../../utils/Utils';

export const DeviceAdditionalData = (props: any) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [params, setParams] = useState<any>({});
    const [type, setType] = useState<any>();
    const [minDate, setMinDate] = useState<any>(moment());
    const [minTime, setMinTime] = useState<any>(moment().startOf('day'));
    const [maxDate, setMaxDate] = useState<any>(moment());
    const [maxTime, setMaxTime] = useState<any>(moment().add(1, 'hour').startOf('hour'));
    const [refreshTable, setRefreshTable] = useState<any>(0);

    const deviceId = props.addedData ? props.addedData.deviceId : undefined;

    useEffect(() => {
        if (props.addedData) {
            onTypeChange({ id: DEVICE_ADDITIONAL_TYPE.CURRENT_L1 });
            onRangeChange(minDate, minTime, maxDate, maxTime);
        }
    }, []);

    useEffect(() => {
        performFetch();
    }, [params]);

    function cleanData() {
        setData(null);
    }

    function performFetch() {
        if (params && params.type != undefined && params.timestampFrom) {
            fetch();
            setRefreshTable(refreshTable + 1);
        } else {
            cleanData();
        }
    }

    function fetch() {
        setLoading(true);
        var newParams = { ...params };
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE_DATA.LIST_GRAPH_ADDITIONAL, {
            ...newParams,
            ...props.addedData,
        }).then((response: any) => {
            setLoading(false);
            if (response.status === RESPONSE.OK) {
                if (
                    moment(maxDate.format('YYYY-MM-DD') + ' ' + maxTime.format('HH:mm')).diff(
                        minDate.format('YYYY-MM-DD') + ' ' + minTime.format('HH:mm')
                    ) > 86400000
                ) {
                    setData(interpolate(response.data, newParams.code, 0, 'DD.MM. HH:mm:ss', 120));
                } else {
                    setData(interpolate(response.data, newParams.code, 0, 'HH:mm:ss', 30));
                }
            } else {
                setData([]);
            }
        });
    }

    function onTypeChange(value: any) {
        setType(value.id);
        setParams((prevState: any) => {
            return { ...prevState, type: '' + value.id };
        });
    }

    function onRangeChange(minDate: any, minTime: any, maxDate: any, maxTime: any) {
        if (minDate && minTime) {
            let timestampFrom = minDate.format('YYYY-MM-DD') + ' ' + minTime.format('HH:mm');
            let timestampTo =
                (maxDate ? maxDate : moment()).format('YYYY-MM-DD') +
                ' ' +
                (maxTime ? maxTime : moment()).format('HH:mm');
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    timestampFrom: moment(timestampFrom).valueOf(),
                    timestampTo: moment(timestampTo).valueOf(),
                };
            });
        } else {
            cleanData();
        }
    }

    const onMinDateChange = (date: any) => {
        setMinDate(date);
        onRangeChange(date, minTime, maxDate, maxTime);
    };

    const onMinTimeChange = (time: any) => {
        setMinTime(time);
        onRangeChange(minDate, time, maxDate, maxTime);
    };

    const onMaxDateChange = (date: any) => {
        setMaxDate(date);
        onRangeChange(minDate, minTime, date, maxTime);
    };

    const onMaxTimeChange = (time: any) => {
        setMaxTime(time);
        onRangeChange(minDate, minTime, maxDate, time);
    };

    const CustomTooltip = (data: any) => {
        if (data.active) {
            return (
                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        padding: '5px',
                        borderRadius: '0.3em',
                    }}
                >
                    <Row>
                        <span>{data.label}</span>
                    </Row>
                    {data.payload != null &&
                        data.payload.map((entry: any) => (
                            <Row>
                                <span style={{ color: entry.color }}>
                                    {entry.name}: {entry.value} {getMeasurementUnit()}
                                </span>
                            </Row>
                        ))}
                </div>
            );
        }
        return null;
    };

    const DataChart = (props: any) => {
        const chartData = data;
        return (
            <>
                <Row style={{ marginTop: 10 }}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <Col span={24}>
                            <ResponsiveContainer height={400} width="95%">
                                <ComposedChart
                                    data={chartData}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 10,
                                        bottom: 5,
                                    }}
                                >
                                    <Tooltip content={<CustomTooltip />} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="converted" padding={{ right: 10 }} />
                                    <YAxis unit={' ' + getMeasurementUnit()} />
                                    {chartData && (
                                        <Bar
                                            name={geti18nText('devices.graph.value')}
                                            type="monotone"
                                            dataKey="value"
                                            fill="#8884d8"
                                        />
                                    )}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Col>
                    )}
                </Row>
            </>
        );
    };

    function setDefaultFilterValue() {
        let filter = [{ field: 'device_id', condition: 'equals', value: deviceId }];
        if (params.type != undefined) {
            filter.push({ field: 'type', condition: 'equals', value: params.type });
        } else {
            filter.push({ field: 'type', condition: 'equals', value: DEVICE_ADDITIONAL_TYPE.CURRENT_L1 });
        }
        if (params.timestampFrom) {
            filter.push({
                field: 'ts',
                condition: 'date_from',
                value: moment(params.timestampFrom).format('yyyy-MM-DD HH:mm:ss'),
            });
        }
        if (params.timestampTo) {
            filter.push({
                field: 'ts',
                condition: 'date_to',
                value: moment(params.timestampTo).format('yyyy-MM-DD HH:mm:ss'),
            });
        }
        return filter;
    }

    function getMeasurementUnit() {
        if (
            [
                DEVICE_ADDITIONAL_TYPE.CURRENT_L1,
                DEVICE_ADDITIONAL_TYPE.CURRENT_L2,
                DEVICE_ADDITIONAL_TYPE.CURRENT_L3,
            ].includes(Number(type))
        ) {
            return 'A';
        } else if (
            [
                DEVICE_ADDITIONAL_TYPE.VOLTAGE_L1,
                DEVICE_ADDITIONAL_TYPE.VOLTAGE_L2,
                DEVICE_ADDITIONAL_TYPE.VOLTAGE_L3,
            ].includes(Number(type))
        ) {
            return 'V';
        } else if (
            [
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L1,
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L2,
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L3,
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L1,
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L2,
                DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L3,
            ].includes(Number(type))
        ) {
            return 'VAr';
        }
        return '';
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={8} xl={3}>
                    <Form.Item
                        label={geti18nText('devices.graph.filter.type')}
                        name="filterType"
                        initialValue={setEnumFormat(
                            'DEVICE_ADDITIONAL_TYPE',
                            DEVICE_ADDITIONAL_TYPE.CURRENT_L1,
                            'device.additional.type'
                        )}
                    >
                        <NySearchField
                            options={ENUMS_DEVICE_ADDITIONAL_TYPE()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onTypeChange}
                        />
                    </Form.Item>
                </Col>
                <>
                    <Col xs={6} xl={3}>
                        <Form.Item label={geti18nText('devices.graph.filter.span.dateFrom')} required>
                            <NyDatePicker
                                disabledDate={(current: any) => {
                                    return moment().add(-10, 'days') >= current;
                                }}
                                style={{ width: '100%' }}
                                onChange={onMinDateChange}
                                value={minDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={5} xl={2}>
                        <Form.Item label={geti18nText('devices.graph.filter.span.timeFrom')} required>
                            <NyDatePicker
                                mode="time"
                                format="HH:mm:ss"
                                style={{ width: '100%' }}
                                onChange={onMinTimeChange}
                                value={minTime}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={6} xl={3}>
                        <Form.Item label={geti18nText('devices.graph.filter.span.dateTo')}>
                            <NyDatePicker
                                disabledDate={(current: any) => {
                                    return moment().add(-10, 'days') >= current;
                                }}
                                style={{ width: '100%' }}
                                value={maxDate}
                                onChange={onMaxDateChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={5} xl={2}>
                        <Form.Item label={geti18nText('devices.graph.filter.span.timeTo')}>
                            <NyDatePicker
                                mode="time"
                                format="HH:mm:ss"
                                style={{ width: '100%' }}
                                onChange={onMaxTimeChange}
                                value={maxTime}
                            />
                        </Form.Item>
                    </Col>
                </>
            </Row>
            <DataChart />
            <Row gutter={24}>
                <Col>
                    <NyDataTable
                        url={CONSTANTS_REQ.DEVICE_DATA.LIST_ADDITIONAL}
                        readonly={true}
                        hideButtons={true}
                        scroll={{ y: 10000, x: 800 }}
                        fetchWhenChange={refreshTable}
                        setDefaultFilterValue={setDefaultFilterValue}
                        columns={[
                            {
                                title: geti18nText('devices.additional.table.data.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                            },
                            {
                                title: geti18nText('devices.additional.table.data.column.date'),
                                dataIndex: 'ts',
                                render: (text: any, record: any) => {
                                    if (record.ts) {
                                        return moment(record.ts).format('DD.MM.YYYY HH:mm:ss');
                                    }
                                },
                                sorter: (a: any, b: any) => {},
                            },
                            {
                                title: geti18nText('devices.additional.table.data.column.value'),
                                dataIndex: 'value',
                                render: (text: any, record: any) => {
                                    if (record.value) {
                                        return (
                                            <span>
                                                {record.value} {getMeasurementUnit()}
                                            </span>
                                        );
                                    }
                                },
                                sorter: (a: any, b: any) => {},
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

const interpolate = (data: any, data_type: any, startFromIndex: any, pattern: string, diffMin: number) => {
    var skipZerosFor: any = [];
    const newArray = [];
    let i;
    for (i = startFromIndex ? startFromIndex : 0; i < data.length; i++) {
        if (!skipZerosFor.includes(data_type) && i > 0) {
            newArray.push(...addData(data[i - 1].converted, data[i].converted, pattern, diffMin, data[0].expected));
        }
        newArray.push(data[i]);
    }
    return newArray;
};

const addData = (time1: any, time2: any, pattern: string, diffMin: number, expected: number) => {
    var duration = moment.duration(moment(time2, pattern).diff(moment(time1, pattern)));
    var durationInMin = duration.asMinutes() > 0 ? duration.asMinutes() : duration.asMinutes() + 1440;
    if (durationInMin > diffMin + 1) {
        const retValue = [];
        let insertZeros = 0;
        let startingPoint = moment(time1, pattern);
        for (insertZeros = (durationInMin / diffMin) | 0; insertZeros > 0; insertZeros--) {
            startingPoint = startingPoint.add(diffMin, 'm');
            retValue.push({ value: 0, expected: expected, converted: startingPoint.format(pattern) });
        }
        return retValue;
    }
    return [];
};

export { interpolate };

import { geti18nText, NyDataEdit, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const CompanyTierChangeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [requestId, setRequestId] = useState<any>(undefined);
    const [confirmed, setConfirmed] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('companyTierChange.edit.company') + ': ' + dataForm.company.name + ' (' + dataForm.id + ')'
        );
        setRequestId(dataForm.id);
        setConfirmed(dataForm.confirmed);

        if (dataForm.requestUser) {
            dataForm.requestedBy = dataForm.requestUser.firstName + ' ' + dataForm.requestUser.lastName;
        }

        if (dataForm.company) {
            dataForm.requestCompany = dataForm.company.name;
            dataForm.currentTier = dataForm.company.tier;
        }

        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(undefined);
        setRequestId(undefined);
        setConfirmed(false);
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    const confirmation = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.COMPANY_TIER_CHANGE.CONFIRM + requestId, undefined, {
            id: requestId,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                onModalClose();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
            } else {
                error(JSON.stringify(result.data.error));
            }
            setLoading(false);
        });
    };

    const sendPaymentReminder = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.COMPANY_TIER_CHANGE.REMINDER + requestId, undefined, {
            id: requestId,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                onModalClose();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
            } else {
                error(JSON.stringify(result.data.error));
            }
            setLoading(false);
        });
    };

    const approval = (approved: boolean) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.COMPANY_TIER_CHANGE.APPROVAL + requestId, undefined, {
            id: requestId,
            approved: approved,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                onModalClose();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
            } else {
                error(JSON.stringify(result.data.error));
            }
            setLoading(false);
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.COMPANY_TIER_CHANGE.EDIT}
            setValues={setValues}
            onModalClose={onModalClose}
            hideSubmitButton={true}
            hideActivationButtons={true}
            customButtons={
                confirmed
                    ? [
                          {
                              text: geti18nText('companyTierChange.button.paymentReminder'),
                              loading: props.loading,
                              style: { backgroundColor: 'rgb(86, 183, 232)', color: 'white' },
                              className: '',
                              onClick: () => sendPaymentReminder(),
                          },
                          {
                              text: geti18nText('app.default.button.reject'),
                              loading: props.loading,
                              style: { backgroundColor: '#ff4d4f', color: 'white' },
                              className: '',
                              onClick: () => approval(false),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.reject.confirm'),
                              popConfirmOk: geti18nText('app.default.button.reject'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                          {
                              text: geti18nText('app.default.button.approve'),
                              loading: props.loading,
                              style: { backgroundColor: 'green', color: 'white' },
                              className: '',
                              onClick: () => approval(true),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.approve.confirm'),
                              popConfirmOk: geti18nText('app.default.button.approve'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
                    : [
                          {
                              text: geti18nText('app.default.button.confirm'),
                              loading: props.loading,
                              style: { backgroundColor: 'green', color: 'white' },
                              className: '',
                              onClick: () => confirmation(),
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.confirm.confirm'),
                              popConfirmOk: geti18nText('app.default.button.confirm'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
            }
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                return { ...values };
            }}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyTierChange.edit.company')} name="requestCompany">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyTierChange.edit.requestUser')} name="requestedBy">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyTierChange.edit.requestTier')} name="requestTier">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyTierChange.edit.currentTier')} name="currentTier">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('companyTierChange.edit.requestDate')} name="requestDate">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default CompanyTierChangeEdit;

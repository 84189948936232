import { geti18nText, NySession, NyUtils } from '@nybble/nyreact';
import { GetEnum, GetEnumNameForValue } from './Enums';

export const downloadFile = (result: any, filename: any = undefined) => {
    if (result.data) {
        const url = window.URL.createObjectURL(result.data.file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (filename) {
            a.download = filename;
        } else if (result.data.filename) {
            a.download = result.data.filename;
        } else {
            a.download = 'download.txt';
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
    } else {
        return false;
    }
};

export const setSearchFormat = function (value: any, text: any = 'name') {
    if (value === undefined || value === null) {
        return null;
    }

    return value.constructor.name === 'Object'
        ? value.hasOwnProperty('id') && value.hasOwnProperty(text)
            ? { id: value['id'], text: value[text] }
            : null
        : null;
};

export const getSearchFormat = function (value: any) {
    if (value === undefined || value === null) {
        return null;
    }

    return value.constructor.name === 'Object'
        ? value.hasOwnProperty('id')
            ? { id: value.id }
            : value.hasOwnProperty('key')
            ? { id: value.key }
            : null
        : { id: value };
};

export const hasAnyProjectRole = (roles: any, project: any = NySession.getProjectInfo()) => {
    if (NySession.isUserAuthenticated() && roles) {
        let role;
        let projectRole;
        if (project != null && NySession.getUser() != null && NySession.getUser().projectRoles != null) {
            for (projectRole of NySession.getUser().projectRoles) {
                if (
                    projectRole.id === project.id ||
                    (project.parent && project.parent.id && project.parent.id === projectRole.id)
                ) {
                    for (role of roles) {
                        if (projectRole.authority === role) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
};

export const hasAnyCompanyTier = (tiers: any = undefined, project: any = undefined) => {
    if (NySession.isUserAuthenticated()) {
        if (!tiers || NySession.hasAnyRole(['ROLE_SUPERADMIN'])) {
            return true;
        }
        if (NySession.getUser() != null && NySession.getUser().company != null) {
            let tier;
            let companyTier = NySession.getUser().company.tier;
            for (tier of tiers) {
                if (
                    companyTier === tier ||
                    (tier === 'PRO' &&
                        project &&
                        (project.demoProject || (project.parent && project.parent.demoProject)))
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const hasAnyProjectRoleIgnoreParent = (roles: any) => {
    if (NySession.isUserAuthenticated() && roles) {
        let role;
        let projectRole;
        if (
            NySession.getProjectInfo() != null &&
            NySession.getUser() != null &&
            NySession.getUser().projectRoles != null
        ) {
            const project: any = NySession.getProjectInfo();
            for (projectRole of NySession.getUser().projectRoles) {
                if (projectRole.id === project.id) {
                    for (role of roles) {
                        if (projectRole.authority === role) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
};

export const getEnumArray = function (enumName: any, enumi18nName?: string) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret = { id: undefined, text: undefined };
            ret.id = aTypes[key];
            ret.text = enumi18nName
                ? geti18nText(enumi18nName + '.' + ret.id)
                : geti18nText('app.enum.' + GetEnumNameForValue({ enumName: enumName, value: ret.id }) + '.' + ret.id);
            types.push(ret);
        }
    }
    return types;
};

export const setEnumFormat = function (enumName: any, value: any, enumi18nName?: string) {
    const enums: [] = getEnumArray(enumName, enumi18nName);

    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id === value;
    });
};

export const getEnumFormat = function (value: any) {
    if (value === undefined || value === null) {
        return null;
    }

    return value.constructor.name === 'Object'
        ? value.hasOwnProperty('id')
            ? value.id
            : value.hasOwnProperty('key')
            ? value.key
            : null
        : value;
};

export const byteFormat = (value: number, dec: number = 2) => {
    if (!value) {
        return '0 B';
    }

    const k = 1000;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.min(Math.floor(Math.log(value) / Math.log(k)), sizes.length - 1);

    return `${parseFloat((value / Math.pow(k, i)).toFixed(dec))} ${sizes[i]}`;
};

export const numberFormat = (value: number, dec: number = 2) => {
    return new Intl.NumberFormat('HR-hr', {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
    }).format(value);
};

export const setToSession = (key: string, value: any) => {
    let session = NyUtils.loadSession();
    if (session) {
        session[key] = value;
        NyUtils.saveSession(session);
    }
};

export const getFromSession = (key: string, defaultValue: any) => {
    let session = NyUtils.loadSession();
    if (session && session[key]) {
        return session[key];
    }
    return defaultValue;
};

import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../utils/Utils';

const { Option } = Select;

export let city: any;
export let country: any;

const ProjectEdit = (props: any) => {
    const parentId = props.addedData && props.addedData.parentId ? props.addedData.parentId : undefined;

    const [editHeader, setEditHeader] = useState(geti18nText('projects.edit.new'));
    const [loading, setLoading] = useState(false);
    const [cordinates, setCordinates] = useState<any>([]);
    const [active, setActive] = useState(false);
    const [project, setProject] = useState(null);
    const [projectCompany, setProjectCompany] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('projects.edit.projectName') + ': ' + dataForm.projectName + ' (' + dataForm.id + ')'
        );

        if (dataForm.parent) {
            dataForm.parent = setSearchFormat(dataForm.parent, 'projectName');
        }

        if (dataForm.company) {
            dataForm.company = setSearchFormat(dataForm.company);
            setProjectCompany(dataForm.company.id);
        }

        setActive(dataForm.active);
        setProject(dataForm.id);

        if (Array.isArray(dataForm.companies)) {
            dataForm.companies = dataForm.companies.map((item: any) => {
                let ret: any = {};
                ret.id = item.id + '';
                ret.key = item.id + '';
                ret.label = item.name;
                return ret;
            });
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    function setDefaultValues() {
        if (NySession.getUser().companyId) {
            form.setFieldsValue({
                company: {
                    id: NySession.getUser().companyId,
                    key: NySession.getUser().companyId,
                    label: NySession.getUser().companyName,
                },
            });
        }
        const project: any = NySession.getProjectInfo();
        if (project && parentId) {
            if (project.id === parentId) {
                form.setFieldsValue({
                    parent: setSearchFormat(project, 'projectName'),
                });
            } else if (project.parent && project.parent.id === parentId) {
                form.setFieldsValue({
                    parent: setSearchFormat(project.parent, 'projectName'),
                });
            }
        }
    }

    function isCreate() {
        if (!project) {
            return true;
        }
        return false;
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const deleteProject = () => {
        setLoading(true);
        let fileId = form.getFieldsValue(['id']);
        NyRequestResolver.requestDelete(CONSTANTS_REQ.PROJECT.DELETE + fileId['id']).then((result: any) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                okNotification();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
                form.resetFields();
                if (NySession.getProjectId() === project) {
                    NySession.setProject({});
                }
            } else {
                var msg = '';
                if (result.data) {
                    if (result.data.error) {
                        msg = JSON.stringify(result.data.error);
                        msg = msg.replace('null', '');
                        if (result.data.error.toString().startsWith('constraint_violation_exception')) {
                            msg = msg
                                .replace(
                                    'constraint_violation_exception',
                                    geti18nText('lights.delete.constraint.violation.exception')
                                )
                                .replace(':', '');
                        }
                    } else if (result.data.message) {
                        if (result.data.message.toString().includes('violates foreign key constraint')) {
                            msg = geti18nText('lights.delete.constraint.violation.exception');
                        } else {
                            msg = result.data.message;
                        }
                    }
                }
                if (msg.length > 0) {
                    // error(msg);
                }
            }
        });
    };

    const onModalClose = () => {
        form.resetFields();
        setActive(false);
        setProject(null);
        setEditHeader(geti18nText('projects.edit.new'));
    };

    useEffect(() => {
        NyRequestResolver.requestGet(
            `https://nominatim.openstreetmap.org/search?city=${city}&country=${country}&format=json`
        ).then((data: any) => {
            setCordinates(data.data[0]);
        });
    }, [country]);

    function setDefaultFilterValue() {
        let filter = [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'parent_id', condition: 'is_null', value: '' },
        ];
        if (projectCompany) {
            filter.push({ field: 'company_id', condition: 'equals', value: parseInt(`${projectCompany}`) });
        } else if (!NySession.hasAnyRole(['ROLE_SUPERADMIN']) && NySession.getUser().company) {
            filter.push({
                field: 'company_id',
                condition: 'equals',
                value: parseInt(`${NySession.getUser().company.id}`),
            });
        }
        if (project) {
            filter.push({ field: 'id', condition: 'not_equals', value: parseInt(`${project}`) });
        }
        return filter;
    }

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.PROJECT.EDIT}
            setValues={setValues}
            setDefaultValues={setDefaultValues}
            onModalClose={onModalClose}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            customButtons={
                isCreate() || active
                    ? []
                    : [
                          {
                              text: geti18nText('app.default.button.delete'),
                              loading: props.loading,
                              style: { backgroundColor: '#c70000', color: 'white' },
                              className: '',
                              onClick: deleteProject,
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.destructive.confirm'),
                              popConfirmOk: geti18nText('app.default.button.delete'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
            }
            buttonsClassName={id ? '' : 'buttons-sticky'}
            paramsId={id ? id : NySession.getProjectId()}
            normalize={(values: any) => {
                if (values.parent) {
                }
                return {
                    ...values,
                    centerLat: cordinates == null ? 0 : cordinates.lat,
                    centerLng: cordinates == null ? 0 : cordinates.lon,
                    centerDist: 0,
                    userId: NySession.getUser().id,
                    company:
                        values.company != null && values.company.constructor === String && parseInt(values.company) > 0
                            ? { id: parseInt(values.company) }
                            : values.company && values.company.id > 0
                            ? values.company
                            : null,
                    companies: Array.isArray(form.getFieldValue('companies'))
                        ? form.getFieldValue('companies').map((item: any) => {
                              let ret: any = {};
                              if (item.hasOwnProperty('value')) {
                                  ret.id = parseInt(item.value, 10);
                              } else if (item.hasOwnProperty('id')) {
                                  ret.id = parseInt(item.id, 10);
                              }
                              return ret;
                          })
                        : null,
                    parent:
                        values.parent != null && values.parent.constructor === String && parseInt(values.parent) > 0
                            ? { id: parseInt(values.parent) }
                            : values.parent && values.parent.id > 0
                            ? values.parent
                            : null,
                };
            }}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Form.Item name="createdBy" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('projects.edit.projectName')}
                            name="projectName"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('projects.validation.projectName'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('projects.edit.parent')} name="parent">
                            <NySearchField
                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="projectName"
                                disabled={false}
                                setDefaultFilterValue={setDefaultFilterValue}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('projects.edit.cityName')}
                            name="cityName"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('projects.validation.cityName'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('projects.edit.country')}
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('projects.validation.country'),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                filterOption={(input: any, option: any) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {countryList()
                                    .getLabels()
                                    .map((name, index) => (
                                        <Option key={index} value={name}>
                                            {name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {NySession.hasAnyRole(['ROLE_SUPERADMIN']) ? (
                        <>
                            <Col span={12}>
                                <Form.Item label={geti18nText('projects.edit.company')} name="company">
                                    <NySearchField
                                        url={CONSTANTS_REQ.COMPANY.SEARCH}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="name"
                                        onChange={(value: any) => {
                                            setProjectCompany(value ? value.id : undefined);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('projects.edit.demo')}
                                    name="demoProject"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Form.Item hidden={true} name="company">
                                <Input hidden={true} />
                            </Form.Item>
                            <Form.Item hidden={true} name="demoProject">
                                <Input hidden={true} />
                            </Form.Item>
                        </>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('projects.edit.companies')}
                            hidden={NySession.getUser().companyId != undefined}
                            name="companies"
                        >
                            <NySearchField
                                mode="multiple"
                                url={CONSTANTS_REQ.COMPANY.SEARCH}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="name"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default ProjectEdit;

import { CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { Button, Col, Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import ProjectEdit from './edit';

const ProjectIndex = (props: any) => {
    const history = useHistory();
    const { parentId } = props;

    const [projectEdit, setProjectEdit] = useState(false);
    const [clickedId, setClickedId] = useState<any>(undefined);

    const setDefaultFilterValue = () => {
        let filter: any = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (parentId) {
            filter.push({ field: 'parent_id', condition: 'equals', value: parentId });
        } else {
            filter.push({ field: 'parent_id', condition: 'is_null', value: '' });
        }
        return filter;
    };

    const onProjectSelect = (item: any) => {
        if (item.active == true) {
            NySession.setProject(item);
            history.push('/project/overview');
        }
    };

    const expandOrCollapseAction = (row: any, expand: boolean) => {
        console.log('expandOrCollapseAction', row, expand);
        row.expanded = expand;
    };

    const sameProjectCompany = (project: any) => {
        return project.company && NySession.getUser().company && project.company.id == NySession.getUser().company.id;
    };

    const columns = [
        {
            title: geti18nText('project.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('project.table.column.name'),
            dataIndex: 'projectName',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                return (
                    <Row>
                        <span>{record.projectName}</span>
                        {(record.demoProject || (record.parent && record.parent.demoProject)) && (
                            <Tag style={{ marginLeft: '10px' }} color="red">
                                {geti18nText('login.demo').toUpperCase()}
                            </Tag>
                        )}
                    </Row>
                );
            },
        },
        {
            title: geti18nText('project.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
        ...(NySession.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'])
            ? [
                  {
                      dataIndex: 'action',
                      width: '10%',
                      render: (text: string, record: any) => {
                          if (NySession.hasAnyRole(['ROLE_SUPERADMIN']) || sameProjectCompany(record))
                              return (
                                  <div
                                      style={{ textAlign: 'center' }}
                                      onClick={() => {
                                          setClickedId(record.id);
                                          setProjectEdit(true);
                                      }}
                                  >
                                      <SettingOutlined style={{ fontSize: '18px' }} />
                                  </div>
                              );
                          return null;
                      },
                  },
              ]
            : []),
    ];

    return (
        <React.Fragment>
            <NyDataTable
                setDefaultSortOrder={{ order: 'projectName', orderType: 'asc' }}
                url={CONSTANTS_REQ.PROJECT.LIST}
                headerTitle={geti18nText('users.projects.table.header')}
                addNewButtonText={geti18nText('users.projects.table.add')}
                hideButtons={true}
                onRowSelect={onProjectSelect}
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={{ y: 10000, x: 800 }}
                columns={columns}
            />
            {NySession.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN']) && (
                <Row className="buttons-sticky">
                    <Col offset={20} span={4}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setClickedId(undefined);
                                setProjectEdit(true);
                            }}
                        >
                            {geti18nText('users.projects.table.add')}
                        </Button>
                    </Col>
                </Row>
            )}
            {React.createElement(
                ProjectEdit,
                {
                    isModal: true,
                    visible: projectEdit,
                    setVisible: setProjectEdit,
                    value: clickedId ? { id: clickedId } : undefined,
                    onSave: () => window.location.reload(),
                    onSaveAndGetID: () => window.location.reload(),
                    onSaveAndGetData: () => window.location.reload(),
                    addedData: { parentId: parentId },
                },
                null
            )}
        </React.Fragment>
    );
};

export default ProjectIndex;

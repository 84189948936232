import { NySession } from '@nybble/nyreact';
import ImportCSV from '../../components/import-csv';
import { CONSTANTS_REQ } from '../../utils/Constants';

const LightImport = (props: any) => {
    return (
        <ImportCSV
            switchData={{ name: 'createSubstation', text: 'import.text.switch.substation' }}
            addedData={{ projectId: NySession.getProjectId() }}
            url={CONSTANTS_REQ.LIGHT.IMPORT_CSV}
            save={props.onPopupSave}
        />
    );
};

export default LightImport;

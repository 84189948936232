import { LockOutlined, SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { iResponse } from '@nybble/nyreact/build/utils/Types';
import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { APPLICATION_KEY, CONSTANTS_REQ } from '../../utils/Constants';
import INyLogin from './types';

const Login = ({
    languageSelector,
    logo,
    classNameBody,
    classNameFormDiv,
    classNameInput,
    classNameButton,
}: INyLogin) => {
    const [validateStatus, setValidateStatus] = useState<any>('');
    const usernameInput = useRef<Input>(null);
    const codeInput = useRef<Input>(null);
    const [validate2FA, setValidate2FA] = useState(false);
    const [verificationToken, setVerificationToken] = useState<any>(undefined);
    const [twoFaGracePeriodExpiration, setTwoFaGracePeriodExpiration] = useState<any>(undefined);
    const [twoFaGracePeriodExpirationVisible, setTwoFaGracePeriodExpirationVisible] = useState(false);
    const [authorized, setAuthorized] = useState(NySession.isUserAuthenticated());

    useEffect(() => {
        if (usernameInput != null && usernameInput.current != null) {
            usernameInput.current.focus();
        }
    }, []);

    const login = (values: any) => {
        if (!validate2FA) {
            setValidateStatus('validating');
            loginUser(values.username, values.password).then((isOk) => {
                if (isOk) {
                    setValidateStatus('success');
                } else {
                    setValidateStatus('error');
                    if (usernameInput != null && usernameInput.current != null) {
                        usernameInput.current.focus();
                    }
                }
            });
        } else {
            setValidateStatus('validating');
            validate2Fa(values.code).then((isOk) => {
                if (isOk) {
                    setValidateStatus('success');
                } else {
                    setValidateStatus('error');
                    if (codeInput != null && codeInput.current != null) {
                        codeInput.current.focus();
                    }
                }
            });
        }
    };

    async function loginUser(username: string, password: string) {
        const loginData = {
            username: username,
            password: password,
        };

        const response = await requestPost(CONSTANTS_REQ.AUTH.LOGIN, loginData, undefined);
        if (response && response.status === RESPONSE.OK && response.data instanceof Object) {
            const data: any = response.data;
            if (data.verification_token) {
                setVerificationToken(data.verification_token);
                setValidate2FA(true);
            } else {
                saveToSession(data);
                await fetchAppSettings();
                await fetchUserDetails(true);
            }
            return true;
        }
        return false;
    }

    async function validate2Fa(code: string) {
        const verifyData = {
            code: code,
        };

        const response = await requestPost(CONSTANTS_REQ.AUTH.VALIDATE_2FA, verifyData, verificationToken);
        if (response && response.status === RESPONSE.OK && response.data instanceof Object) {
            const data: any = response.data;
            setVerificationToken(undefined);
            setValidate2FA(false);
            saveToSession(data);
            await fetchAppSettings();
            await fetchUserDetails(false);
            return true;
        }
        return false;
    }

    async function fetchAppSettings() {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.GET_SETTINGS, { group: 'application' }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    let groupedSettings: any = {};
                    for (const setting of result.data) {
                        if (!groupedSettings[setting.settingGroup]) {
                            groupedSettings[setting.settingGroup] = {};
                        }
                        groupedSettings[setting.settingGroup][setting.settingKey] = {
                            id: setting.id,
                            value: setting.settingValue,
                        };
                    }
                    sessionStorage.setItem(APPLICATION_KEY + 'settings', JSON.stringify(groupedSettings));
                }
            }
        );
    }

    async function fetchUserDetails(checkTwoFaGracePeriodExpiration: boolean) {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.USER.CURRENT, { fetchDetails: true }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                let data = result.data;
                let session = NyUtils.loadSession();
                session.user = data;
                session.user.access_token = NySession.getUserToken();
                if (session.user.roles != undefined) {
                    session.user.roles = session.user.roles.map((role: any) => {
                        return role.authority;
                    });
                }
                if (session.user.expires_in != undefined) {
                    session.user.expires_at = Date.now() + (session.user.expires_in - 5) * 1000;
                }
                NyUtils.saveSession(session);
                if (checkTwoFaGracePeriodExpiration && data.twoFaGracePeriodStart) {
                    setTwoFaGracePeriodExpiration(
                        moment(data.twoFaGracePeriodStart, 'YYYY-MM-DD HH:mm:ss').add(
                            getTwoFaGracePeriod(),
                            'milliseconds'
                        )
                    );
                    setTwoFaGracePeriodExpirationVisible(true);
                } else {
                    setAuthorized(true);
                }
            }
        });
    }

    async function requestPost(url: string, body: any, token: string | undefined) {
        interface iRequest {
            method: string;
            headers: { [index: string]: any };
            body: FormData | string | undefined;
        }

        let req = {
            method: 'POST',
        } as iRequest;
        req.headers = { 'Content-Type': 'application/json' };
        if (token) {
            req.headers['Authorization'] = 'Bearer ' + token;
        }
        if (body) {
            req.body = JSON.stringify(body);
        }

        let retValue = { status: 0, data: {} } as iResponse;

        try {
            let response = await fetch(url, req);
            if (response.status === RESPONSE.UNAUTORIZED) {
                NySession.logoutUser();
            } else {
                retValue.status = response.status;
                try {
                    retValue.data = await response.json();
                    return retValue;
                } catch (e) {
                    console.log('Error while waiting for response');
                    console.log(e);
                }
            }
        } catch (error) {
            console.error('Error while Request method!!!');
            console.error(error);
            retValue.data = error;
        }
        return retValue;
    }

    function getTwoFaGracePeriod() {
        let parsedSettings: any = {};
        const sessionSettings = sessionStorage.getItem(APPLICATION_KEY + 'settings');
        if (sessionSettings && sessionSettings !== 'undefined') {
            parsedSettings = JSON.parse(sessionSettings);
        }
        if (
            parsedSettings &&
            parsedSettings['application'] &&
            parsedSettings['application']['app.2fa.grace.period'] &&
            parsedSettings['application']['app.2fa.grace.period']['value']
        ) {
            return Number(parsedSettings['application']['app.2fa.grace.period']['value']) * 86400000;
        }
        return 14;
    }

    function saveToSession(data: any) {
        let session = NyUtils.loadSession();
        session.user = data;
        if (session.user.expires_in != undefined) {
            session.user.expires_at = Date.now() + (session.user.expires_in - 5) * 1000;
        }
        NyUtils.saveSession(session);
    }

    const ShowError = ({ show, loginErrorText = '' }: { show: boolean; loginErrorText?: string }) => {
        const { Text } = Typography;

        if (show) {
            return (
                <Row gutter={[16, 8]}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Text type="danger">{loginErrorText}</Text>
                    </Col>
                </Row>
            );
        }
        return null;
    };

    if (authorized) {
        return <Redirect to={twoFaGracePeriodExpiration ? '/account?twoFa=true' : '/'} />;
    } else {
        return (
            <>
                <Modal
                    title={geti18nText('user.tab.2fa')}
                    visible={twoFaGracePeriodExpirationVisible}
                    onOk={() => {
                        setTwoFaGracePeriodExpirationVisible(false);
                        setAuthorized(true);
                    }}
                    destroyOnClose={true}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={500}
                    closable={false}
                >
                    {!moment().isBefore(moment(twoFaGracePeriodExpiration)) && (
                        <>
                            <Row style={{ textAlign: 'justify' }}>
                                <span>{geti18nText('users.edit.twoFa.gracePeriodExpired')}</span>
                            </Row>
                        </>
                    )}
                    {moment().isBefore(moment(twoFaGracePeriodExpiration)) && (
                        <>
                            <Row style={{ textAlign: 'justify' }}>
                                <span>{geti18nText('users.edit.twoFa.required')}</span>
                            </Row>
                            <br />
                            <Row style={{ textAlign: 'justify' }}>
                                <b>
                                    {geti18nText('users.edit.twoFa.gracePeriodExpiresIn', [
                                        '' + (moment(twoFaGracePeriodExpiration).diff(moment(), 'days') + 1),
                                    ])}
                                </b>
                            </Row>
                        </>
                    )}
                </Modal>
                <Row justify="center" align="middle" style={{ height: '100vh' }} className={classNameBody}>
                    <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 4 }}>
                        <div className={classNameFormDiv ? classNameFormDiv : 'ant-notification-notice'}>
                            <Form onFinish={login} className="login-form animated fadeIn faster">
                                {logo != undefined && logo}
                                {logo === undefined && (
                                    <Divider orientation="center">{geti18nText('app.default.login.title')}</Divider>
                                )}

                                {!validate2FA && (
                                    <>
                                        <Form.Item
                                            name="username"
                                            hasFeedback
                                            validateStatus={validateStatus}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.login.validation.username'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={classNameInput}
                                                disabled={validateStatus === 'validating'}
                                                ref={usernameInput}
                                                prefix={<UserOutlined />}
                                                placeholder={geti18nText('app.default.login.username.placeholder')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="password"
                                            hasFeedback
                                            validateStatus={validateStatus}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.login.validation.password'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={classNameInput}
                                                disabled={validateStatus === 'validating'}
                                                prefix={<LockOutlined type="lock" />}
                                                type="password"
                                                placeholder={geti18nText('app.default.login.password.placeholder')}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                                {validate2FA && (
                                    <>
                                        <Form.Item
                                            name="code"
                                            hasFeedback
                                            validateStatus={validateStatus}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.login.validation.code'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={classNameInput}
                                                disabled={validateStatus === 'validating'}
                                                ref={codeInput}
                                                prefix={<SafetyCertificateOutlined />}
                                                placeholder={geti18nText('app.default.login.code.placeholder')}
                                            />
                                        </Form.Item>
                                    </>
                                )}

                                {languageSelector != undefined && languageSelector}
                                <ShowError
                                    show={validateStatus === 'error'}
                                    loginErrorText={
                                        !validate2FA
                                            ? geti18nText('app.default.login.error')
                                            : geti18nText('app.default.verify.code.error')
                                    }
                                />
                                <Form.Item>
                                    <Button
                                        className={classNameButton}
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: classNameButton ? undefined : '100%' }}
                                        disabled={validateStatus === 'validating'}
                                        loading={validateStatus === 'validating'}
                                    >
                                        {!validate2FA
                                            ? geti18nText('app.default.login.button')
                                            : geti18nText('app.default.verify.code.button')}
                                    </Button>
                                </Form.Item>
                                {!validate2FA && (
                                    <Link to={'/register'}>
                                        <Button style={{ marginBottom: '10px' }} block>
                                            {geti18nText('companyRegistration.button')}
                                        </Button>
                                    </Link>
                                )}
                            </Form>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};

export default Login;

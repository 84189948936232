import { PoweroffOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, NySession } from '@nybble/nyreact';
import { Button, Form, Modal, Row, Switch } from 'antd';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import markerSubstation from '../../assets/images/marker-eplus.png';
import markerSubstationGreen from '../../assets/images/marker-eplus-green.png';
import markerSubstationRed from '../../assets/images/marker-eplus-red.png';
import markerSubstationYellow from '../../assets/images/marker-eplus-yellow.png';
import markerSequencer from '../../assets/images/marker-timer.png';
import markerBlackIcon from '../../assets/images/placemark_black@2x.png';
import markerBlueIcon from '../../assets/images/placemark_blue@2x.png';
import markerGreenIcon from '../../assets/images/placemark_green@2x.png';
import markerOrangeIcon from '../../assets/images/placemark_orange@2x.png';
import markerMagentaIcon from '../../assets/images/placemark_purple@2x.png';
import markerRedIcon from '../../assets/images/placemark_red@2x.png';
import markerWhiteIcon from '../../assets/images/placemark_white@2x.png';
import markerYellowIcon from '../../assets/images/placemark_yellow@2x.png';
import {
    APPLICATION_KEY,
    CONSTANTS_REQ,
    LIGHT_STATUS,
    LOAD_TYPE,
    REFRESH_RATE_OPTIONS_MS,
} from '../../utils/Constants';
import { loadFromSession } from '../../utils/Enums';
import { getFromSession, hasAnyCompanyTier, hasAnyProjectRole, setToSession } from '../../utils/Utils';
import DevicePowerControl from '../device/DevicePowerControl';
import DeviceEdit from '../device/edit';
import { LightEdit } from '../light/edit';
import SubstationEdit from '../substation/edit';

const greenIcon = new L.Icon({
    iconUrl: markerGreenIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const redIcon = new L.Icon({
    iconUrl: markerRedIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const blueIcon = new L.Icon({
    iconUrl: markerBlueIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const yellowIcon = new L.Icon({
    iconUrl: markerYellowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const orangeIcon = new L.Icon({
    iconUrl: markerOrangeIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const magentaIcon = new L.Icon({
    iconUrl: markerMagentaIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const blackIcon = new L.Icon({
    iconUrl: markerBlackIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const whiteIcon = new L.Icon({
    iconUrl: markerWhiteIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -50],
});

const substationIcon = new L.Icon({
    iconUrl: markerSubstation,
    iconSize: [28, 28],
    iconAnchor: [14, 28],
    popupAnchor: [1, -50],
});

const substationIconGreen = new L.Icon({
    iconUrl: markerSubstationGreen,
    iconSize: [56, 56],
    iconAnchor: [28, 56],
    popupAnchor: [1, -50],
});

const substationIconRed = new L.Icon({
    iconUrl: markerSubstationRed,
    iconSize: [56, 56],
    iconAnchor: [28, 56],
    popupAnchor: [1, -50],
});

const substationIconYellow = new L.Icon({
    iconUrl: markerSubstationYellow,
    iconSize: [56, 56],
    iconAnchor: [28, 56],
    popupAnchor: [1, -50],
});

const sequencerIcon = new L.Icon({
    iconUrl: markerSequencer,
    iconSize: [46, 46],
    iconAnchor: [23, 46],
    popupAnchor: [10, -23],
});

const OverviewIndex = (props: any) => {
    const { addedData } = props;

    const [items, setItems] = useState<any>();
    const [hdLoading, setHdLoading] = useState(false);
    const [substations, setSubstations] = useState<any>();
    const [popUp, setPopUp] = useState<any>(null);
    const [substationPopUp, setSubstationPopUp] = useState<any>(null);
    const [grouping, setGrouping] = useState<any>(true);
    const [lightVisible, setLightVisible] = useState(false);
    const [substationVisible, setSubstationVisible] = useState(false);
    const [devicePowerVisible, setDevicePowerVisible] = useState(false);
    const [deviceVisible, setDeviceVisible] = useState(false);
    const [light, setLight] = useState<any>(null);
    const [substation, setSubstation] = useState<any>(null);
    const [devicePowerSubstation, setDevicePowerSubstation] = useState<any>(null);
    const [device, setDevice] = useState(null);
    const [bounds, setBounds] = useState<L.LatLngBounds | undefined>(undefined);
    const [currentConsumption, setCurrentConsumption] = useState<any>({});
    const [lightsLoaded, setLightsLoaded] = useState(false);
    const [substationsLoaded, setSubstationsLoaded] = useState(false);
    const [hdMeasurements, setHdMeasurements] = useState<any>([]);
    const [selectedHd, setSelectedHd] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [refresh, setRefresh] = useState<any>(0);
    const [refreshPeriod, setRefreshPeriod] = useState<any>(getFromSession('refreshPeriod', 60000));

    const mapRef = useRef<Map>(null);
    const refreshRef = useRef(refresh);
    refreshRef.current = refresh;

    const [mapForm] = Form.useForm();

    const setDefaultFilterValue = () => {
        let filter = [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
        if (addedData && addedData.deviceId) {
            filter.push({ field: 'device_id', condition: 'equals', value: addedData.deviceId });
        }
        return filter;
    };

    useEffect(() => {
        let interval: any;
        clearInterval(interval);

        interval = setInterval(() => {
            let currRefresh: any = refreshRef.current;
            setRefresh((currRefresh: any) => currRefresh + 1);
        }, Number(refreshPeriod));

        if (props.setRefreshInterval) {
            props.setRefreshInterval(interval);
        }

        return () => clearInterval(interval);
    }, [refreshPeriod]);

    useEffect(() => {
        fetch();
        if (hasAnyCompanyTier(['PRO'], NySession.getProjectInfo())) getLatestHdMeasurements();
    }, [refresh]);

    useEffect(() => {
        if (lightsLoaded && ((addedData && addedData.deviceId) || substationsLoaded)) {
            extendBounds(substations, items);
        }
    }, [substationsLoaded, lightsLoaded]);

    const fetch = () => {
        setGrouping(loadFromSession(APPLICATION_KEY + 'grouping'));
        if (addedData && addedData.deviceId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CURRENT_CONSUMPTION, {
                deviceId: addedData.deviceId,
            }).then((result: any) => {
                setCurrentConsumption(result.data);
            });
            if (addedData.substationId) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.SUBSTATION.EDIT + '/' + addedData.substationId).then(
                    (result: any) => {
                        setSubstations([result.data]);
                        setSubstationsLoaded(true);
                    }
                );
            }
        } else {
            NyRequestResolver.requestGet(CONSTANTS_REQ.PROJECT.CURRENT_CONSUMPTION, {
                projectId: NySession.getProjectId(),
            }).then((result: any) => {
                setCurrentConsumption(result.data);
            });
            NyRequestResolver.requestGet(CONSTANTS_REQ.SUBSTATION.LIST, {
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                max: 100000,
                offset: 0,
            }).then((result: any) => {
                setSubstations(result.data.content);
                setSubstationsLoaded(true);
            });
        }
        if (hasAnyCompanyTier(['PRO'], NySession.getProjectInfo()) && !selectedHd) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.LIGHT.LIST, {
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                max: 100000,
                offset: 0,
            }).then((result: any) => {
                setItems(result.data.content);
                setLightsLoaded(true);
            });
        }
    };

    function onRefreshPeriodChange(period: any) {
        if (period && period.id > 0) {
            setRefreshPeriod(Number(period.id));
            setToSession('refreshPeriod', Number(period.id));
        } else {
            setRefreshPeriod(60000);
            setToSession('refreshPeriod', 60000);
        }
    }

    const getLatestHdMeasurements = () => {
        if (addedData && addedData.deviceId) {
            getLatestHdMeasurementsDevice(addedData.deviceId);
        } else if (selectedDate) {
            getLatestHdMeasurementsProject(selectedDate);
        }
    };

    function getLatestHdMeasurementsDevice(deviceId: any) {
        setHdLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.HD_MEASUREMENTS, {
            projectId: NySession.getProjectId(),
            deviceId: deviceId ? deviceId : undefined,
        }).then((result: any) => {
            setHdLoading(false);
            if (result && result.data) {
                setHdMeasurements(result.data);
            }
        });
    }

    function getLatestHdMeasurementsProject(date: any) {
        setHdLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.PROJECT.HD_MEASUREMENTS, {
            projectId: NySession.getProjectId(),
            date: date,
        }).then((result: any) => {
            setHdLoading(false);
            if (result && result.data) {
                setHdMeasurements(result.data);
            }
        });
    }

    const onHdMeasurementChange = (item: any) => {
        let filter = setDefaultFilterValue();
        if (item && item.id > 0) {
            filter.push({ field: 'ts_hd', condition: 'equals', value: item.id });
            setSelectedHd(item.id);
        } else {
            setSelectedHd(null);
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.LIGHT.LIST, {
            search: encodeURI(JSON.stringify(filter)),
            max: 100000,
            offset: 0,
        }).then((result: any) => {
            setItems(result.data.content);
        });
    };

    function extendBounds(substations: any[], lights: any[]) {
        let tempBounds = bounds;
        let totalNum = 0;
        if (substations && substations.length > 0) {
            totalNum += substations.length;
            tempBounds = extendItemBounds(tempBounds, substations);
        }
        if (lights && lights.length > 0) {
            totalNum += lights.length;
            tempBounds = extendItemBounds(tempBounds, lights);
        }
        if (tempBounds?.isValid() && totalNum > 1) {
            setBounds(tempBounds);
        }
    }

    function extendItemBounds(tempBounds: any, items: any[]) {
        items.map((item: any) => {
            if (tempBounds === undefined) {
                tempBounds = L.latLngBounds([item.lat, item.lng], [item.lat, item.lng]);
            } else {
                tempBounds.extend([item.lat, item.lng]);
            }
        });
        return tempBounds;
    }

    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    function addSubstationPopup(e: any, item: any) {
        setSubstationPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    const MarkerLight = (icon: any, item: any) => {
        return (
            <Marker
                option={{ item: item }}
                icon={icon}
                position={[item.lat, item.lng]}
                onClick={(e: any) => addPopup(e, item)}
            ></Marker>
        );
    };

    const MarkerSubstation = (icon: any, item: any) => {
        return (
            <Marker
                option={{ item: item }}
                icon={icon}
                position={[item.lat, item.lng]}
                onClick={(e: any) => addSubstationPopup(e, item)}
            ></Marker>
        );
    };

    const onGroupingChange = (checked: any) => {
        setGrouping(checked ? true : false);
        sessionStorage.setItem(APPLICATION_KEY + 'grouping', JSON.stringify(checked ? true : false));
    };

    const showLightModal = (light: any) => {
        setLight(light);
        setLightVisible(true);
    };

    const showSubstationModal = (substation: any) => {
        setSubstation(substation);
        setSubstationVisible(true);
    };

    const showDevicePowerModal = (substation: any) => {
        setDevicePowerSubstation(substation);
        setDevicePowerVisible(true);
    };

    const showDeviceModal = (device: any) => {
        if (addedData == undefined || addedData.deviceId == undefined) {
            setDevice(device);
            setDeviceVisible(true);
        }
    };

    const closeDevicePowerModal = () => {
        setDevicePowerSubstation(null);
        setDevicePowerVisible(false);
    };

    const MapMarkers = () => {
        return (
            <React.Fragment>
                {substations != null && (
                    <React.Fragment>
                        {substations.map((item: any, index: any) => (
                            <div key={index}>{MarkerSubstation(substationIcon, item)}</div>
                        ))}
                    </React.Fragment>
                )}
                {items != null && (
                    <React.Fragment>
                        {items.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    <React.Fragment>
                                        {item.loadType === LOAD_TYPE.SEQUENCER && MarkerLight(sequencerIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.IDLE &&
                                            MarkerLight(blueIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.NORMAL &&
                                            MarkerLight(greenIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.BROKEN &&
                                            MarkerLight(redIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.INACTIVE &&
                                            MarkerLight(yellowIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.IDLE_HOLD_0 &&
                                            MarkerLight(magentaIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.NORMAL_HOLD_0 &&
                                            MarkerLight(orangeIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.OTHER &&
                                            MarkerLight(whiteIcon, item)}
                                        {item.loadType === LOAD_TYPE.LUMINAIRE &&
                                            item.status === LIGHT_STATUS.PLAN &&
                                            MarkerLight(blackIcon, item)}
                                    </React.Fragment>
                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const customClusterIcons = (cluster: any) => {
        const items = cluster
            .getAllChildMarkers()
            .filter(
                (child: any) =>
                    child.options &&
                    child.options.option &&
                    child.options.option.item &&
                    child.options.option.item.loadType == LOAD_TYPE.LUMINAIRE
            )
            .map((child: any) => child.options.option.item);
        let allOk = true;
        if (items.length > 0) {
            const brokenCount = items.filter((item: any) => item.status == LIGHT_STATUS.BROKEN).length;
            if (brokenCount != 0) {
                allOk = false;
            }
        }
        const markerColorClass = allOk ? 'marker-cluster-green' : 'marker-cluster-red';
        return L.divIcon({
            html: '<div><span>' + cluster.getChildCount() + '</span></div>',
            className: 'marker-cluster ' + markerColorClass,
            iconSize: L.point(40, 40),
        });
    };

    const deviceActivity = (activity: any) => {
        if (activity && moment().diff(moment(activity), 'seconds') <= 7200) {
            return <PoweroffOutlined style={{ color: 'green' }} />;
        } else {
            return <PoweroffOutlined style={{ color: 'red' }} />;
        }
    };

    const availableDays = () => {
        let availableDays: any = [];
        for (var i = 0; i < 7; i++) {
            const day = moment().subtract(i, 'days');
            availableDays.push({ id: day.format('YYYY-MM-DD'), text: day.format('DD.MM.YYYY') });
        }
        return availableDays;
    };

    const onSelectedDayChange = (day: any) => {
        setHdLoading(true);
        mapForm.setFieldsValue({ hdMeasurement: null });
        onHdMeasurementChange(null);
        if (day && day.id != -1) {
            setSelectedDate(day.id);
            getLatestHdMeasurementsProject(day.id);
        } else {
            setSelectedDate(null);
            setHdMeasurements([]);
            setHdLoading(false);
        }
    };

    return (
        <div id="container" style={{ position: 'relative' }}>
            {currentConsumption && (
                <span style={{ position: 'absolute', bottom: '30px', left: '10px', zIndex: 900 }}>
                    {geti18nText('project.currentConsumption')}:{' '}
                    <b>
                        {currentConsumption.currentPower} {'kW'}
                    </b>
                </span>
            )}
            {currentConsumption && (
                <span style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 900 }}>
                    {geti18nText('project.expectedConsumption')}:{' '}
                    <b>
                        {currentConsumption.expectedPower} {'kW'}
                    </b>
                </span>
            )}
            <Form form={mapForm}>
                <Row>
                    {hasAnyCompanyTier(['PRO'], NySession.getProjectInfo()) && (
                        <>
                            {(!addedData || !addedData.deviceId) && (
                                <Form.Item label={geti18nText('light.status.activity.hd.date')} name="selectedDay">
                                    <NySearchField
                                        value={selectedDate}
                                        style={{ width: 150, marginLeft: 10, marginRight: 10 }}
                                        options={availableDays()}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        onChange={onSelectedDayChange}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item label={geti18nText('light.status.activity.hd.timestamps')} name="hdMeasurement">
                                <NySearchField
                                    value={selectedHd}
                                    style={{ width: 150, marginLeft: 10, marginRight: 10 }}
                                    disabled={hdLoading || ((!addedData || !addedData.deviceId) && !selectedDate)}
                                    options={hdMeasurements}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    onChange={onHdMeasurementChange}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item className="overview-refresh" label={geti18nText('refresh.period')} name="refreshPeriod">
                        <NySearchField
                            value={refreshPeriod}
                            defaultValue={REFRESH_RATE_OPTIONS_MS.map((item: any) => {
                                return { id: item.id, label: item.text };
                            }).find((item: any) => Number(item.id) == refreshPeriod)}
                            style={{ width: 150, marginLeft: 10, marginRight: 10 }}
                            options={REFRESH_RATE_OPTIONS_MS}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onRefreshPeriodChange}
                        />
                    </Form.Item>
                </Row>
            </Form>
            <Map
                className="map-size"
                style={
                    addedData && addedData.mapHeight
                        ? { maxHeight: addedData.mapHeight, height: '78vh' }
                        : { height: '78vh' }
                }
                center={[45.74497120551588, 16.10733032226563]}
                zoom={5}
                bounds={bounds}
            >
                {hasAnyCompanyTier(['PRO'], NySession.getProjectInfo()) && (
                    <Switch
                        style={{ width: '100px', top: '80px', left: '10px', zIndex: 900 }}
                        checkedChildren={geti18nText('map.show.normal.markers')}
                        unCheckedChildren={geti18nText('map.show.grouped.markers')}
                        defaultChecked={grouping}
                        onChange={onGroupingChange}
                    />
                )}
                <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {grouping && (
                    <MarkerClusterGroup
                        iconCreateFunction={customClusterIcons}
                        disableClusteringAtZoom={17}
                        animate={false}
                    >
                        {MapMarkers()}
                    </MarkerClusterGroup>
                )}
                {!grouping && <React.Fragment> {MapMarkers()} </React.Fragment>}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {geti18nText('lights.edit.factoryId')}: <b>{popUp.item.factoryId}</b> <br />
                        {geti18nText('lights.edit.power')}: <b>{popUp.item.power}</b> <br />
                        {geti18nText('lights.edit.type')}: <b>{popUp.item.type}</b> <br />
                        {geti18nText('lights.edit.initialHoldS')}: <b>{popUp.item.initialHoldS}</b> <br />
                        {geti18nText('lights.edit.phase')}: <b>{popUp.item.phase}</b> <br />
                        {geti18nText('lights.table.column.sequencerSerial')}: <b>{popUp.item.sequencerSerial}</b> <br />
                        {geti18nText('lights.edit.device')}:{' '}
                        <b>
                            {popUp.item.device && popUp.item.device.name && (
                                <a onClick={() => showDeviceModal(popUp.item.device.id)}>
                                    {popUp.item.device.name} {deviceActivity(popUp.item.device.lastActivity)}
                                </a>
                            )}
                        </b>{' '}
                        <br />
                        {geti18nText('lights.edit.substation')}:{' '}
                        <b>{popUp.item.substation && popUp.item.substation.substationId}</b> <br />
                        {geti18nText('lights.edit.status')}:{' '}
                        <b>{geti18nText('app.enum.light.status.' + popUp.item.status)}</b> <br />
                        {!NySession?.getProjectInfo()?.parent?.id && popUp.item.project && (
                            <>
                                {geti18nText('lights.edit.project')}: <b>{popUp.item.project.projectName}</b> <br />
                            </>
                        )}
                        <Button onClick={() => showLightModal(popUp.item.id)} type="primary">
                            {geti18nText('app.default.button.details')}
                        </Button>{' '}
                        <br />
                    </Popup>
                )}
                {substationPopUp && (
                    <Popup
                        key={substationPopUp.key}
                        offset={[0, -30]}
                        position={substationPopUp.position}
                        onClose={() => setSubstationPopUp(null)}
                    >
                        {geti18nText('substations.edit.substationId')}: <b>{substationPopUp.item.substationId}</b>{' '}
                        <br />
                        {!NySession?.getProjectInfo()?.parent?.id && substationPopUp.item.project && (
                            <>
                                {geti18nText('substations.edit.project')}:{' '}
                                <b>{substationPopUp.item.project.projectName}</b> <br />
                            </>
                        )}
                        {(NySession.hasAnyRole(['ROLE_SUPERADMIN']) ||
                            hasAnyProjectRole(['ROLE_PROJECT_ADMIN']) ||
                            hasAnyProjectRole(['ROLE_PROJECT_ADMIN'], substationPopUp.item.project)) &&
                            hasAnyCompanyTier(undefined, NySession.getProjectInfo()) && (
                                <>
                                    {geti18nText('substations.edit.devices')}:{' '}
                                    <b>
                                        <a onClick={() => showDevicePowerModal(substationPopUp.item)}>
                                            {geti18nText('app.default.show.all')}
                                        </a>
                                    </b>
                                    <br />
                                </>
                            )}
                        <>
                            <Button onClick={() => showSubstationModal(substationPopUp.item.id)} type="primary">
                                {geti18nText('app.default.button.details')}
                            </Button>
                            <br />
                        </>
                    </Popup>
                )}
            </Map>

            <Modal
                title={geti18nText('substations.edit.power')}
                visible={devicePowerVisible}
                onCancel={closeDevicePowerModal}
                onOk={closeDevicePowerModal}
                destroyOnClose={true}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={1000}
            >
                <DevicePowerControl substationId={devicePowerSubstation?.id} />
            </Modal>

            {React.createElement(
                LightEdit,
                {
                    isModal: true,
                    visible: lightVisible,
                    setVisible: setLightVisible,
                    value: light ? { id: light } : undefined,
                    onSave: () => fetch(),
                    addedData: undefined,
                },
                null
            )}
            {React.createElement(
                SubstationEdit,
                {
                    isModal: true,
                    visible: substationVisible,
                    setVisible: setSubstationVisible,
                    value: substation ? { id: substation } : undefined,
                    onSave: () => fetch(),
                    addedData: undefined,
                },
                null
            )}
            {React.createElement(
                DeviceEdit,
                {
                    isModal: true,
                    visible: deviceVisible,
                    setVisible: setDeviceVisible,
                    value: device ? { id: device } : undefined,
                    onSave: () => fetch(),
                    addedData: undefined,
                },
                null
            )}
        </div>
    );
};

export default OverviewIndex;

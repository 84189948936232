import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Statistic = (props: any) => {
    return (
        <div className="ant-statistic">
            <div className="ant-statistic-title">{props.title}</div>
            <div className="ant-statistic-content">{props.value}</div>
        </div>
    );
};

export default Statistic;

import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Input, notification, Row, Table, Tabs } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import React, { ReactText, useEffect, useState } from 'react';
import CustomTable from '../../components/custom-table';
import { CONSTANTS_REQ, ENUMS_PROGRAM_OUTPUT, ENUMS_PROGRAM_TYPE, PROGRAM_TYPE } from '../../utils/Constants';
import { hasAnyProjectRole } from '../../utils/Utils';
import DayOfWeek from './DayOfWeek';
import { ProgramEdit } from './edit';

const { TabPane } = Tabs;

const ProgramIndex = (props: any) => {
    return (
        <Tabs type="card">
            <TabPane tab={geti18nText('programs.tab.active')} key={1}>
                <ActiveProgramIndex {...props} />
            </TabPane>
            <TabPane tab={geti18nText('programs.tab.inactive')} key={2}>
                <InactiveProgramIndex {...props} />
            </TabPane>
        </Tabs>
    );
};

const ActiveProgramIndex = (props: any) => {
    const { deviceId } = props;
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
    const [deviceCount, setDeviceCount] = useState<any>(undefined);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);

    useEffect(() => {
        setSelectedRowKeys(props.selectedRows ? props.selectedRows : []);
    }, [props.selectedRows]);

    useEffect(() => {
        getPrograms();
        getDeviceCount();
    }, []);

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    function getPrograms() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.PROGRAM.PROJECT_PROGRAMS, {
            projectId: NySession.getProjectId(),
            activeOnly: true,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.CREATED) {
                setData(result.data);
            }
        });
    }

    function getDeviceCount() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.PROJECT.DEVICE_COUNT, {
            projectId: NySession.getProjectId(),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setDeviceCount(result.data);
            }
        });
    }

    function addToSelected(id: any) {
        if (id) {
            let existingProgram = data?.filter((program: any) => program.id == id)?.length;
            if (!existingProgram && !selectedRowKeys.includes(id)) {
                let tempSelected = [...selectedRowKeys];
                tempSelected.push(id);
                setSelectedRowKeys(tempSelected);
                if (props.setSelectedRows) {
                    props.setSelectedRows(tempSelected);
                }
            }
        }
    }

    function onSaveAndGetID(id: any) {
        addToSelected(id);
        getPrograms();
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: any) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        if (props.setSelectedRows) {
            props.setSelectedRows(selectedRowKeys);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: undefined,
        type: 'checkbox' as 'checkbox',
        preserveSelectedRowKeys: true,
    };

    function clearSelectedRowKeys() {
        setSelectedRowKeys([]);
    }

    function recordSelect(record: any) {
        if (record && record.id) {
            setPopupValue(record);
            setPopupVisible(true);
        }
    }

    function newProgram() {
        setPopupValue(undefined);
        setPopupVisible(true);
    }

    const rowSelectionModals = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        return (
            <>
                {canCreate() &&
                    (!deviceId ? (
                        <div className="ant-row buttons-sticky">
                            <div className="ant-col ant-col-4 ant-col-offset-20">
                                <Button className="ant-btn ant-btn-primary" onClick={newProgram}>
                                    {geti18nText('programs.table.add')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Button className="ant-btn ant-btn-primary" onClick={newProgram}>
                            {geti18nText('programs.table.add')}
                        </Button>
                    ))}
            </>
        );
    };

    const ProgramRow = (props: any) => {
        const { program } = props;
        return (
            <Row gutter={10}>
                <Col>
                    <FormItem label={geti18nText('programs.edit.type')}>
                        <Input
                            className="input-white"
                            value={geti18nText('enum.programType.' + program.type)}
                            disabled
                        />
                    </FormItem>
                </Col>
                {program.type == PROGRAM_TYPE.USER && (
                    <>
                        <Col>
                            <FormItem label={geti18nText('programs.edit.startDate')}>
                                <Input
                                    className="input-white"
                                    value={moment(program.startDate).format('DD.MM.YYYY')}
                                    disabled
                                />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label={geti18nText('programs.edit.endDate')}>
                                <Input
                                    className="input-white"
                                    value={
                                        program.endDate
                                            ? moment(program.endDate).format('DD.MM.YYYY')
                                            : geti18nText('programs.edit.date.never')
                                    }
                                    disabled
                                />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                                label={`${geti18nText('programs.edit.onTime')} (${
                                    program.turnOn
                                        ? geti18nText('programs.edit.turnOn')
                                        : geti18nText('programs.edit.turnOff')
                                })`}
                            >
                                <Input className="input-white" value={program.onTime} disabled />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                                label={`${geti18nText('programs.edit.offTime')} (${
                                    program.turnOn
                                        ? geti18nText('programs.edit.turnOn')
                                        : geti18nText('programs.edit.turnOff')
                                })`}
                            >
                                <Input className="input-white" value={program.offTime} disabled />
                            </FormItem>
                        </Col>
                        <Col>
                            <DayOfWeek value={program.daysOfWeek}></DayOfWeek>
                        </Col>
                    </>
                )}
                {program.type == PROGRAM_TYPE.ASTRO && (
                    <>
                        <Col>
                            <FormItem label={geti18nText('programs.edit.sunsetOffset')}>
                                <Input className="input-white" value={program.sunsetOffset} disabled />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label={geti18nText('programs.edit.sunriseOffset')}>
                                <Input className="input-white" value={program.sunriseOffset} disabled />
                            </FormItem>
                        </Col>
                    </>
                )}
                {(program.type == PROGRAM_TYPE.USER || program.type == PROGRAM_TYPE.ASTRO) && (
                    <Col>
                        <FormItem label={geti18nText('programs.edit.output')}>
                            <Input
                                className="input-white"
                                value={geti18nText('enum.programOutput.' + program.output)}
                                disabled
                            />
                        </FormItem>
                    </Col>
                )}
                {program.type == PROGRAM_TYPE.IO_MATRIX && (
                    <Col>
                        <FormItem label={geti18nText('programs.edit.name')}>
                            <Input className="input-white" value={program.name} disabled />
                        </FormItem>
                    </Col>
                )}
                {deviceCount && (
                    <Col>
                        <FormItem label={geti18nText('programs.edit.deviceCount')}>
                            <span>
                                {program.deviceCount} / {deviceCount}
                            </span>
                        </FormItem>
                    </Col>
                )}
            </Row>
        );
    };

    return (
        <React.Fragment>
            <Table
                locale={{ emptyText: geti18nText('programs.table.no.programs') }}
                className="ant-form ant-form-vertical"
                showHeader={false}
                rowKey={(record: any) => record.id}
                dataSource={data}
                pagination={false}
                columns={[
                    {
                        render: (text: any, record: any) => {
                            return <ProgramRow program={record} />;
                        },
                    },
                ]}
                rowSelection={deviceId ? rowSelection : undefined}
                onRow={(record: any) => {
                    return {
                        onDoubleClick: () => {
                            recordSelect(record);
                        },
                    };
                }}
                scroll={{ y: 10000, x: 800 }}
            />
            {rowSelectionModals != undefined &&
                rowSelectionModals(
                    selectedRowKeys.length > 0,
                    selectedRowKeys,
                    selectedRows,
                    () => {
                        onSelectChange([], []);
                        getPrograms();
                    },
                    clearSelectedRowKeys
                )}
            {popupVisible &&
                React.createElement(
                    ProgramEdit,
                    {
                        isModal: true,
                        visible: popupVisible,
                        setVisible: setPopupVisible,
                        value: popupValue,
                        onSaveAndGetID: onSaveAndGetID,
                        addedData: {
                            deviceId: deviceId,
                            currNum: data ? data.length : 0,
                        },
                    },
                    null
                )}
        </React.Fragment>
    );
};

const InactiveProgramIndex = (props: any) => {
    const { deviceId } = props;
    const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState<ReactText[]>(
        props.selectedRows ? props.selectedRows : []
    );

    useEffect(() => {
        setDefaultSelectedRowKeys(props.selectedRows);
    }, [props.selectedRows]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'end_date', condition: 'date_to', value: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss') },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    const onSelect = (selectedRowKeys: any, selectedRows: any) => {
        if (props.setSelectedRows) {
            props.setSelectedRows(selectedRowKeys);
        }
    };

    return (
        <CustomTable
            url={CONSTANTS_REQ.PROGRAM.LIST}
            addNewButtonText={geti18nText('programs.table.add')}
            showRecordModal={true}
            modalComponent={ProgramEdit}
            selectOnSave={false}
            buttonsClassName={!deviceId ? 'buttons-sticky' : ''}
            hideNewButton={!canCreate()}
            setDefaultFilterValue={setDefaultFilterValue}
            scroll={{ y: 10000, x: 1600 }}
            showRowSelection={deviceId}
            onSelect={onSelect}
            setDefaultSelectedRowKeys={defaultSelectedRowKeys}
            preserveSelectedRowKeys={true}
            columns={[
                {
                    title: geti18nText('programs.table.column.id'),
                    dataIndex: 'id',
                    width: '7%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('programs.table.column.type'),
                    dataIndex: 'type',
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        return <div>{geti18nText('enum.programType.' + record.type)}</div>;
                    },
                    ...getColumnSearchOption(ENUMS_PROGRAM_TYPE()),
                },
                {
                    title: geti18nText('programs.table.column.startDate'),
                    dataIndex: 'startDate',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(),
                    render: (text: any, record: any) => {
                        if (record.startDate) {
                            return moment(record.startDate).format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                },
                {
                    title: geti18nText('programs.table.column.endDate'),
                    dataIndex: 'endDate',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(),
                    render: (text: any, record: any) => {
                        if (record.endDate) {
                            return moment(record.endDate).format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                },
                {
                    title: geti18nText('programs.table.column.onTime'),
                    dataIndex: ['onTime'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('programs.table.column.offTime'),
                    dataIndex: ['offTime'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('programs.table.column.output'),
                    dataIndex: 'output',
                    sorter: (a: any, b: any) => {},
                    render: (text: any, record: any) => {
                        return <div>{geti18nText('enum.programOutput.' + record.output)}</div>;
                    },
                    ...getColumnSearchOption(ENUMS_PROGRAM_OUTPUT()),
                },
            ]}
        />
    );
};

export default ProgramIndex;

import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const Register = (props: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [rn, setRn] = useState(0);
    const [messageResponse, setMessageResponse] = useState(undefined);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { logo, languageSelector } = props;

    const confirmationLink = searchParams ? searchParams.get('c') : undefined;

    useEffect(() => {
        if (rn == 0 && confirmationLink) {
            confirm(confirmationLink);
        }
    }, [confirmationLink]);

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    const register = (values: any) => {
        setLoading(true);
        const body = { ...values, lang: NyUtils.getSelectedLanguage() };
        NyRequestResolver.requestPost(CONSTANTS_REQ.COMPANY_REGISTRATION.REGISTER, undefined, body).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    setMessageResponse(geti18nText('companyRegistration.confirmation.sent'));
                } else {
                    error(JSON.stringify(result.data.error));
                }
                setLoading(false);
            }
        );
    };

    const confirm = (confirmationLink: any) => {
        console.log('confirm', confirmationLink, rn);
        setRn(rn + 1);
        setLoading(true);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.COMPANY_REGISTRATION.CONFIRM + confirmationLink,
            undefined,
            undefined
        ).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                setMessageResponse(geti18nText('companyRegistration.confirmation.confirmed'));
            } else {
                if (result.data && result.data.error) {
                    switch (result.data.error) {
                        case 'not_valid':
                            setMessageResponse(geti18nText('companyRegistration.confirmation.notValid'));
                            return;
                        case 'already_confirmed':
                            setMessageResponse(geti18nText('companyRegistration.confirmation.alreadyConfirmed'));
                            return;
                        default:
                            error(JSON.stringify(result.data.error));
                            break;
                    }
                } else {
                    error(JSON.stringify(result.data.error));
                }
            }
            setLoading(false);
        });
    };

    const RegisterForm = () => (
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} xxl={{ span: 8 }}>
                <div className={'custom-notification-notice'}>
                    <Form
                        form={form}
                        onFinish={register}
                        className="login-form animated fadeIn faster"
                        layout="vertical"
                    >
                        {logo != undefined && logo}

                        <div style={{ height: '0px', overflow: 'hidden' }}>
                            Password <input type="password" name="password" />
                            Email <input type="text" name="email" />
                        </div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.firstName')}
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.firstName'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.lastName')}
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.lastName'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.password')}
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.password'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || value.length >= 6) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        geti18nText('companyRegistration.validation.passwordShort')
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.passwordConfirm')}
                                    name="passwordConfirm"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.passwordConfirm'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        geti18nText('companyRegistration.validation.passwordMatch')
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.name'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.vatNumber')}
                                    name="vatNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.vatNumber'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('companyRegistration.edit.email')}
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('companyRegistration.validation.email'),
                                        },
                                        {
                                            type: 'email',
                                            message: geti18nText('companyRegistration.validation.emailInvalid'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('companyRegistration.edit.address')} name="address">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('companyRegistration.edit.phone')} name="phone">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        {languageSelector != undefined && languageSelector}
                        <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                            {geti18nText('companyRegistration.edit.button.submit')}
                        </Button>
                        <Link to={'/login'}>
                            <Button style={{ float: 'right', marginRight: '12px' }}>
                                {geti18nText('companyRegistration.edit.button.login')}
                            </Button>
                        </Link>
                    </Form>
                </div>
            </Col>
        </Row>
    );

    const MessageResponse = (props: any) => (
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} xxl={{ span: 8 }}>
                <div className={'custom-notification-notice'} style={{ textAlign: 'center' }}>
                    {logo != undefined && logo}
                    <h3>{props.message}</h3>
                    <br />
                    <Link to={'/login'}>
                        <Button>{geti18nText('companyRegistration.edit.button.login')}</Button>
                    </Link>
                </div>
            </Col>
        </Row>
    );

    if (messageResponse) {
        return <MessageResponse message={messageResponse} />;
    } else if (confirmationLink) {
        return <></>;
    } else {
        return <RegisterForm />;
    }
};

export default Register;

import { geti18nText } from '@nybble/nyreact';

export const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '/';

export const APPLICATION_KEY = 'CLSM';

export const CONSTANTS_REQ = {
    ENUMS: URL_PREFIX + 'api/enums',
    REGISTER: URL_PREFIX + 'api/register',
    AUTH: {
        LOGIN: URL_PREFIX + 'api/auth/login',
        VALIDATE_2FA: URL_PREFIX + 'api/auth/validate2fa',
        REGISTER: URL_PREFIX + 'api/auth/register',
        GENERATE_SECRET: URL_PREFIX + 'api/auth/generateSecret',
    },
    ADMIN: {
        COMPANY_LIST: URL_PREFIX + 'api/company/list',
        COMPANY_EDIT: URL_PREFIX + 'api/company',
        USERS_LIST: URL_PREFIX + 'api/users/list',
        USERS_EDIT: URL_PREFIX + 'api/users',
        USER_BY_UNAME: URL_PREFIX + 'api/users/uName',
        DEVICE_LIST: URL_PREFIX + 'api/device/list',
        DEVICE_EDIT: URL_PREFIX + 'api/device',
    },
    USER: {
        CURRENT: URL_PREFIX + 'api/users/current',
        UPDATE_ACCOUNT: URL_PREFIX + 'api/users/updateAccount',
        UPDATE_PASSWORD: URL_PREFIX + 'api/users/updatePassword',
        PROJECT_LIST: URL_PREFIX + 'api/users/userProjectList',
        PROJECT_ADD: URL_PREFIX + 'api/users/addProjectRole',
        PROJECT_REMOVE: URL_PREFIX + 'api/users/removeProjectRole',
        DELETE: URL_PREFIX + 'api/users/delete/',
        ACCESS_TOKEN_LIST: URL_PREFIX + 'api/users/accessTokens',
        ACCESS_TOKEN_GENERATE: URL_PREFIX + 'api/users/generateAccessToken',
    },
    USER_ROLE: {
        FOR_USER_ID: URL_PREFIX + 'api/role/getRoleForUserId',
        USER_PROJECT_ROLES: URL_PREFIX + 'api/role/getProjectRoles',
        USER_ROLES: URL_PREFIX + 'api/role/getUserRoles',
    },
    DEVICE: {
        LIST: URL_PREFIX + 'api/device/list',
        EDIT: URL_PREFIX + 'api/device',
        SEARCH: URL_PREFIX + 'api/device/search',
        DEACTIVATE: URL_PREFIX + 'api/device/deactivate',
        ADD_TO_SUBSTATION: URL_PREFIX + 'api/device/addToSubstation',
        CURRENT_CONSUMPTION: URL_PREFIX + 'api/device/currentConsumption',
        ACTIVITY: URL_PREFIX + 'api/device/activity',
        POWER_CONTROL: URL_PREFIX + 'api/device/powerControl',
        HD_MEASUREMENTS: URL_PREFIX + 'api/device/lastHdMeasurements',
        SYNC: URL_PREFIX + 'api/device/sync',
        NOTIFICATIONS: URL_PREFIX + 'api/device/notifications',
        LATEST_DEVICE_STATUS: URL_PREFIX + 'api/device/latestDeviceStatus',
        DEVICE_STATUSES: URL_PREFIX + 'api/device/deviceStatuses',
        SEND_POWER_CONTROL_REQUEST: URL_PREFIX + 'api/device/sendPowerControlRequest',
        SEND_STATUS_REQUEST: URL_PREFIX + 'api/device/sendStatusRequest',
        SEND_ADDITIONAL_DATA_REQUEST: URL_PREFIX + 'api/device/sendAdditionalDataRequest',
        SEND_EMERGENCY: URL_PREFIX + 'api/device/sendEmergency',
        LISTEN_DEVICE_STATUS: URL_PREFIX + 'api/device/listenDeviceStatus',
    },
    DEVICE_DATA: {
        LIST: URL_PREFIX + 'api/deviceData/list',
        LIST_GRAPH: URL_PREFIX + 'api/deviceData/listGraph',
        EXPORT: URL_PREFIX + 'api/deviceData/export',
        LIST_GRAPH_HD_SD: URL_PREFIX + 'api/deviceData/listHdSdGraph',
        EXPORT_HD_SD: URL_PREFIX + 'api/deviceData/exportHdSd',
        LIST_GRAPH_ADDITIONAL: URL_PREFIX + 'api/deviceData/listAdditionalGraph',
        LIST_ADDITIONAL: URL_PREFIX + 'api/deviceData/listAdditional',
        LIST_ARCHIVE: URL_PREFIX + 'api/deviceData/listArchive',
    },
    COMPANY: {
        LIST: URL_PREFIX + 'api/company/list',
        EDIT: URL_PREFIX + 'api/company',
        SEARCH: URL_PREFIX + 'api/company/search',
        DELETE: URL_PREFIX + 'api/company/delete/',
    },
    COMPANY_REGISTRATION: {
        LIST: URL_PREFIX + 'api/companyRegistration/list',
        EDIT: URL_PREFIX + 'api/companyRegistration',
        REGISTER: URL_PREFIX + 'api/companyRegistration/register/',
        CONFIRM: URL_PREFIX + 'api/companyRegistration/confirm/',
        APPROVAL: URL_PREFIX + 'api/companyRegistration/approval/',
    },
    COMPANY_TIER_CHANGE: {
        LIST: URL_PREFIX + 'api/companyTierChange/list',
        EDIT: URL_PREFIX + 'api/companyTierChange',
        TIER_CHANGE: URL_PREFIX + 'api/companyTierChange/tierChange/',
        CONFIRM: URL_PREFIX + 'api/companyTierChange/confirm/',
        APPROVAL: URL_PREFIX + 'api/companyTierChange/approval/',
        REMINDER: URL_PREFIX + 'api/companyTierChange/sendPaymentReminder/',
    },
    LIGHT: {
        LIST: URL_PREFIX + 'api/light/list',
        EDIT: URL_PREFIX + 'api/light',
        SEQUENCER: URL_PREFIX + 'api/light/sequencer',
        IMPORT_CSV: URL_PREFIX + 'api/light/importCSV',
        EXPORT_CSV: URL_PREFIX + 'api/light/exportCSV',
        ADD_TO_DEVICE: URL_PREFIX + 'api/light/addToDevice',
        SET_ACTIVE: URL_PREFIX + 'api/light/setActive',
        DELETE: URL_PREFIX + 'api/light/delete/',
        HEALTH_CHECK_LIST: URL_PREFIX + 'api/light/healthCheckList/',
        PERFORM_HEALTH_CHECK: URL_PREFIX + 'api/light/performHealthCheck/',
        EXPORT_HEALTH_CHECK: URL_PREFIX + 'api/light/exportHealthCheck/',
        UPDATE_PHASES: URL_PREFIX + 'api/light/updatePhases/',
    },
    PROJECT: {
        LIST: URL_PREFIX + 'api/project/list',
        EDIT: URL_PREFIX + 'api/project',
        SEARCH: URL_PREFIX + 'api/project/search',
        USER_LIST: URL_PREFIX + 'api/project/userList',
        ADD_USER: URL_PREFIX + 'api/project/addProjectUser',
        REMOVE_USER: URL_PREFIX + 'api/project/removeProjectUser',
        DELETE: URL_PREFIX + 'api/project/delete/',
        UPDATE_PROJECT_USER_ROLE: URL_PREFIX + 'api/project/updateProjectUserRole',
        CURRENT_CONSUMPTION: URL_PREFIX + 'api/project/currentConsumption',
        HD_MEASUREMENTS: URL_PREFIX + 'api/project/lastHdMeasurements',
        DEVICE_COUNT: URL_PREFIX + 'api/project/deviceCount',
    },
    SUBSTATION: {
        LIST: URL_PREFIX + 'api/substation/list',
        EDIT: URL_PREFIX + 'api/substation',
        IMPORT_CSV: URL_PREFIX + 'api/substation/importCSV',
        EXPORT_CSV: URL_PREFIX + 'api/substation/exportCSV',
        SEARCH: URL_PREFIX + 'api/substation/search',
        GET_ZONE: URL_PREFIX + 'api/substation/getZone',
    },
    REPORT: {
        CONSUMPTION_POWER: URL_PREFIX + 'api/deviceData/chartsConsumption',
        MEASUREMENT_REPORT: URL_PREFIX + 'api/report/measurementReport',
        SEARCH: URL_PREFIX + 'api/substation/search',
        APP_USAGE: URL_PREFIX + 'api/report/appUsage',
    },
    LIGHT_STATUS_ACTIVITY: {
        LIST: URL_PREFIX + 'api/lightStatusActivity/list',
        REPORT: URL_PREFIX + 'api/lightStatusActivity/reportCSV',
    },
    SETTINGS: {
        GET_SETTINGS: URL_PREFIX + 'api/settings/getSettings',
        SET_SETTINGS: URL_PREFIX + 'api/settings/setSettings',
    },
    SYSTEM: {
        DATABASE_TABLES: URL_PREFIX + 'api/system/databaseTables',
    },
    PROGRAM: {
        PROJECT_PROGRAMS: URL_PREFIX + 'api/program/projectPrograms',
        LIST: URL_PREFIX + 'api/program/list',
        EDIT: URL_PREFIX + 'api/program',
        DEVICES: URL_PREFIX + 'api/program/devices',
        ADD_DEVICES: URL_PREFIX + 'api/program/addDevices',
        REMOVE_DEVICES: URL_PREFIX + 'api/program/removeDevices',
    },
};

export const CONSTANTS_STORAGE = {
    DEFAULTS: 'hr.nybble.clsm.defaults',
    SESSION: 'hr.nybble.clsm.session',
};

export const OBIS_CODE = {
    POWER: {
        PLUS: {
            TOTAL: '1-0: 1.4.0*255',
            L1: '1-0:21.4.0*255',
            L2: '1-0:41.4.0*255',
            L3: '1-0:61.4.0*255',
        },
    },
    ENERGY: {
        PLUS: {
            TOTAL: '1-0: 1.8.0*255',
            L1: '1-0:21.8.0*255',
            L2: '1-0:41.8.0*255',
            L3: '1-0:61.8.0*255',
        },
    },
};

export const LOAD_TYPE = {
    LUMINAIRE: 0,
    SEQUENCER: 1,
};

export const ENUMS_LOAD_TYPE = () => {
    return [
        { id: LOAD_TYPE.LUMINAIRE, text: geti18nText('enum.loadType.' + LOAD_TYPE.LUMINAIRE) },
        { id: LOAD_TYPE.SEQUENCER, text: geti18nText('enum.loadType.' + LOAD_TYPE.SEQUENCER) },
    ];
};

export const LIGHT_STATUS = {
    IDLE: 0,
    NORMAL: 1,
    BROKEN: 2,
    INACTIVE: 3,
    IDLE_HOLD_0: 4,
    NORMAL_HOLD_0: 5,
    OTHER: 6,
    PLAN: 7,
};

export const DEVICE_HD_SD_TYPE = {
    HD: 0,
    SD: 1,
};

export const ENUMS_DEVICE_HD_SD_TYPE = () => {
    return [
        { id: -1, text: geti18nText('devices.graph.filter.hdsddata.all') },
        { id: DEVICE_HD_SD_TYPE.HD, text: geti18nText('devices.graph.filter.hdsddata.hd') },
        { id: DEVICE_HD_SD_TYPE.SD, text: geti18nText('devices.graph.filter.hdsddata.sd') },
    ];
};

export const ENUMS_LIGHT_STATUS = () => {
    return [
        { id: LIGHT_STATUS.IDLE, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.IDLE) },
        { id: LIGHT_STATUS.NORMAL, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.NORMAL) },
        { id: LIGHT_STATUS.BROKEN, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.BROKEN) },
        { id: LIGHT_STATUS.INACTIVE, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.INACTIVE) },
        {
            id: LIGHT_STATUS.IDLE_HOLD_0,
            text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.IDLE_HOLD_0),
        },
        {
            id: LIGHT_STATUS.NORMAL_HOLD_0,
            text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.NORMAL_HOLD_0),
        },
        { id: LIGHT_STATUS.OTHER, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.OTHER) },
        { id: LIGHT_STATUS.PLAN, text: geti18nText('app.enum.light.status.' + LIGHT_STATUS.PLAN) },
    ];
};

export const REFRESH_RATE_MS = 120000;

export const REFRESH_RATE_OPTIONS_MS = [
    { id: '10000', text: geti18nText('refresh.period.10s') },
    { id: '20000', text: geti18nText('refresh.period.20s') },
    { id: '30000', text: geti18nText('refresh.period.30s') },
    { id: '60000', text: geti18nText('refresh.period.1m') },
    { id: '120000', text: geti18nText('refresh.period.2m') },
];

export const PROGRAM_TYPE = {
    USER: 0,
    ASTRO: 1,
    IO_MATRIX: 2,
};

export const PROGRAM_OUTPUT = {
    R1: 1,
    R2: 2,
    CB: 0,
};

export const PROGRAM_INPUT = {
    I1: 1,
    I2: 2,
};

export const ENUMS_PROGRAM_TYPE = () => {
    return [
        { id: PROGRAM_TYPE.USER, text: geti18nText('enum.programType.' + PROGRAM_TYPE.USER) },
        { id: PROGRAM_TYPE.ASTRO, text: geti18nText('enum.programType.' + PROGRAM_TYPE.ASTRO) },
        { id: PROGRAM_TYPE.IO_MATRIX, text: geti18nText('enum.programType.' + PROGRAM_TYPE.IO_MATRIX) },
    ];
};

export const ENUMS_PROGRAM_OUTPUT = () => {
    return [
        { id: PROGRAM_OUTPUT.R1, text: geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.R1) },
        { id: PROGRAM_OUTPUT.R2, text: geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.R2) },
        { id: PROGRAM_OUTPUT.CB, text: geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.CB) },
    ];
};

export const ENUMS_PROGRAM_INPUT = () => {
    return [
        { id: PROGRAM_INPUT.I1, text: geti18nText('enum.programInput.' + PROGRAM_INPUT.I1) },
        { id: PROGRAM_INPUT.I2, text: geti18nText('enum.programInput.' + PROGRAM_INPUT.I2) },
    ];
};

export const DEVICE_NOTIFICATION_TYPE = {
    I1: 'I1',
    I2: 'I2',
};

export const ENUMS_DEVICE_NOTIFICATION_TYPE = () => {
    return [
        {
            id: DEVICE_NOTIFICATION_TYPE.I1,
            text: geti18nText('enum.deviceNotificationType.' + DEVICE_NOTIFICATION_TYPE.I1),
        },
        {
            id: DEVICE_NOTIFICATION_TYPE.I2,
            text: geti18nText('enum.deviceNotificationType.' + DEVICE_NOTIFICATION_TYPE.I2),
        },
    ];
};

export const COMPANY_TYPE = {
    STANDARD: 0,
    DESIGN: 1,
};

export const ENUMS_COMPANY_TYPE = () => {
    return [
        {
            id: COMPANY_TYPE.STANDARD,
            text: geti18nText('enum.companyType.' + COMPANY_TYPE.STANDARD),
        },
        {
            id: COMPANY_TYPE.DESIGN,
            text: geti18nText('enum.companyType.' + COMPANY_TYPE.DESIGN),
        },
    ];
};

export const COMPANY_TIER = {
    LITE: 'LITE',
    COMPACT: 'COMPACT',
    PRO: 'PRO',
};

export const ENUMS_COMPANY_TIER = () => {
    return [
        {
            id: COMPANY_TIER.LITE,
            text: geti18nText('enum.companyTier.' + COMPANY_TIER.LITE),
        },
        {
            id: COMPANY_TIER.COMPACT,
            text: geti18nText('enum.companyTier.' + COMPANY_TIER.COMPACT),
        },
        {
            id: COMPANY_TIER.PRO,
            text: geti18nText('enum.companyTier.' + COMPANY_TIER.PRO),
        },
    ];
};

export const DEVICE_ADDITIONAL_TYPE = {
    CURRENT_L1: 0,
    CURRENT_L2: 1,
    CURRENT_L3: 2,
    VOLTAGE_L1: 3,
    VOLTAGE_L2: 4,
    VOLTAGE_L3: 5,
    REACT_POWER_MINUS_L1: 6,
    REACT_POWER_MINUS_L2: 7,
    REACT_POWER_MINUS_L3: 8,
    REACT_POWER_PLUS_L1: 9,
    REACT_POWER_PLUS_L2: 10,
    REACT_POWER_PLUS_L3: 11,
};

export const ENUMS_DEVICE_ADDITIONAL_TYPE = () => {
    return [
        {
            id: DEVICE_ADDITIONAL_TYPE.CURRENT_L1,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.CURRENT_L1),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.CURRENT_L2,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.CURRENT_L2),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.CURRENT_L3,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.CURRENT_L3),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.VOLTAGE_L1,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.VOLTAGE_L1),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.VOLTAGE_L2,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.VOLTAGE_L2),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.VOLTAGE_L3,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.VOLTAGE_L3),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L1,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L1),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L2,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L2),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L3,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_MINUS_L3),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L1,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L1),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L2,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L2),
        },
        {
            id: DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L3,
            text: geti18nText('device.additional.type.' + DEVICE_ADDITIONAL_TYPE.REACT_POWER_PLUS_L3),
        },
    ];
};

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';

const AddToDevice = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [light, setLight] = useState<any>(undefined);
    const [device, setDevice] = useState(undefined);
    const [devices, setDevices] = useState([]);
    const [phase, setPhase] = useState(undefined);

    useEffect(() => {
        setHasSelected(props.hasSelected);
        setSelectedRowKeys(props.selectedRowKeys);
    }, [props]);

    useEffect(() => {
        getLight();
    }, [hasSelected]);

    async function getLight() {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            const resp: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.LIGHT.EDIT + '/' + selectedRowKeys[0]);
            if (resp.status === RESPONSE.OK) {
                setLight(resp.data);
            }
        }
    }

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = (e: any) => {
        setVisible(false);
    };

    const onChange = (value: any) => {
        setDevice(value.id);
    };

    const onChangePhase = (value: any) => {
        setPhase(value);
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        NyRequestResolver.requestPost(CONSTANTS_REQ.LIGHT.ADD_TO_DEVICE, undefined, {
            projectId: NySession.getProjectId(),
            lights: selectedRowKeys,
            deviceId: Number(device),
            phase: phase,
        }).then((result) => {
            if (result.status === RESPONSE.CREATED) {
                props.onPopupSave();
                setSelectedRowKeys([]);
                setHasSelected(false);
                setVisible(false);
                okNotification();
            }
        });
    };

    function setDefaultFilterValue() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'substation_id',
                condition: 'equals',
                value: light != null && light.substation != null ? light.substation.id : null,
            },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    }

    return (
        <React.Fragment>
            <Button disabled={!hasSelected} type="primary" onClick={showModal}>
                {geti18nText('lights.table.addToDevice')}
            </Button>

            <Modal
                title={geti18nText('lights.table.addToDevice')}
                visible={visible}
                onCancel={handleCancel}
                onOk={onSubmit}
                okText={geti18nText('lights.table.addToDevice')}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Form.Item label={geti18nText('lights.edit.substation')}>
                        {light != null && light.substation != null && light.substation.substationId}
                    </Form.Item>
                    <Form.Item label={geti18nText('lights.edit.device')}>
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name"
                            onChange={onChange}
                            disabled={false}
                            setDefaultFilterValue={setDefaultFilterValue}
                        />
                    </Form.Item>
                    <Form.Item label={geti18nText('lights.edit.phase')}>
                        <Select
                            showSearch
                            onChange={onChangePhase}
                            notFoundContent={geti18nText('app.default.dropdown')}
                            filterOption={(input: any, option: any) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="L1">L1</Select.Option>
                            <Select.Option value="L2">L2</Select.Option>
                            <Select.Option value="L3">L3</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default AddToDevice;

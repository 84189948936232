import { CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Modal, notification, Tabs } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { hasAnyProjectRole } from '../../utils/Utils';
import AddToProject from './AddToProject';
import DeleteFromProject from './DeleteFromProject';

const { TabPane } = Tabs;

const AccountIndex = () => {
    const [activeKey, setActiveKey] = useState(1);
    const [clickedId, setClickedId] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [role, setRole] = useState<any>(undefined);

    const translate_map_option_text = 'users.role.';

    function setDefaultFilterValueInProject() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
            { field: 'in_project', condition: 'equals_bool', value: 1 },
        ];
    }

    function setDefaultFilterValueOutProject() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
            { field: 'in_project', condition: 'equals_bool', value: 0 },
        ];
    }

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const setUserRole = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.PROJECT.UPDATE_PROJECT_USER_ROLE, undefined, {
            projectId: NySession.getProjectId(),
            userId: clickedId,
            roleId: role.id,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                setVisible(false);
                setRole(undefined);
                setActiveKey(3);
            }
        });
    };

    return (
        <React.Fragment>
            <Tabs type="card" onChange={(key: any) => setActiveKey(key)}>
                <TabPane tab={geti18nText('users.projects.tab.inProject')} key={1}>
                    <NyDataTable
                        url={CONSTANTS_REQ.PROJECT.USER_LIST}
                        buttonsClassName={'buttons-sticky'}
                        hideNewButton={true}
                        hideButtons={!canCreate}
                        setDefaultFilterValue={setDefaultFilterValueInProject}
                        fetchWhenChange={activeKey}
                        onRowSelect={() => {}}
                        showRowSelection={true}
                        selectOnSave={false}
                        rowSelectionModal={DeleteFromProject}
                        scroll={{ y: 10000, x: 800 }}
                        columns={[
                            {
                                title: geti18nText('users.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('users.table.column.username'),
                                dataIndex: 'username',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.last_name'),
                                dataIndex: 'last_name',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.first_name'),
                                dataIndex: 'first_name',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.role'),
                                dataIndex: 'role',
                            },
                            {
                                title: geti18nText('users.edit.company'),
                                dataIndex: ['company', 'name'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.active'),
                                dataIndex: 'active',
                                width: '10%',
                                render: (text: any, record: { active: any }) => {
                                    if (record.active) {
                                        return <CheckOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <CloseOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValueInProject()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValueInProject(),
                            },
                            {
                                dataIndex: 'action',
                                width: '10%',
                                render: (text: string, record: any) => {
                                    return (
                                        <div
                                            style={{ textAlign: 'center' }}
                                            onClick={() => {
                                                setClickedId(record.id);
                                                setVisible(true);
                                            }}
                                        >
                                            <SettingOutlined style={{ fontSize: '18px' }} />
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </TabPane>
                <TabPane tab={geti18nText('users.projects.tab.outProject')} key={2}>
                    <NyDataTable
                        url={CONSTANTS_REQ.PROJECT.USER_LIST}
                        hideNewButton={true}
                        buttonsClassName={'buttons-sticky'}
                        onRowSelect={() => {}}
                        setDefaultFilterValue={setDefaultFilterValueOutProject}
                        fetchWhenChange={activeKey}
                        showRowSelection={true}
                        selectOnSave={false}
                        rowSelectionModal={AddToProject}
                        columns={[
                            {
                                title: geti18nText('users.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('users.table.column.username'),
                                dataIndex: 'username',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.last_name'),
                                dataIndex: 'last_name',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.first_name'),
                                dataIndex: 'first_name',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.edit.company'),
                                dataIndex: ['company', 'name'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('users.table.column.active'),
                                dataIndex: 'active',
                                width: '10%',
                                render: (text: any, record: { active: any }) => {
                                    if (record.active) {
                                        return <CheckOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <CloseOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValueOutProject()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValueOutProject(),
                            },
                        ]}
                    />
                </TabPane>
            </Tabs>
            <Modal
                title={geti18nText('users.edit.role')}
                visible={visible}
                onOk={setUserRole}
                okText={geti18nText('app.default.button.save')}
                onCancel={() => {
                    setVisible(false);
                    setRole(undefined);
                }}
            >
                <NySearchField
                    value={role}
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.USER_ROLE.USER_PROJECT_ROLES}
                    map={{ id: 'id', label: 'text' }}
                    searchBy="authority"
                    onChange={(item: any) => {
                        setRole(item);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

export default AccountIndex;

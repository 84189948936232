import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, ENUMS_COMPANY_TIER, ENUMS_COMPANY_TYPE } from '../../utils/Constants';
import { getEnumFormat, setEnumFormat } from '../../utils/Utils';

const CompanyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('company.edit.new'));
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('company.edit.name') + ': ' + dataForm.name + ' (' + dataForm.id + ')');

        if (dataForm.type != null) {
            dataForm.type = setEnumFormat('COMPANY_TYPE', dataForm.type, 'enum.companyType');
        }
        if (dataForm.tier != null) {
            dataForm.tier = setEnumFormat('COMPANY_TIER', dataForm.tier, 'enum.companyTier');
        }

        setActive(dataForm.active);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('projects.edit.new'));
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function isCreate() {
        return props.value == undefined;
    }

    const deleteCompany = () => {
        setLoading(true);
        let fileId = form.getFieldsValue(['id']);
        NyRequestResolver.requestDelete(CONSTANTS_REQ.COMPANY.DELETE + fileId['id']).then((result: any) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                okNotification();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
                form.resetFields();
            } else {
                var msg = '';
                if (result.data) {
                    if (result.data.error) {
                        msg = JSON.stringify(result.data.error);
                        msg = msg.replace('null', '');
                        if (result.data.error.toString().startsWith('constraint_violation_exception')) {
                            msg = msg
                                .replace(
                                    'constraint_violation_exception',
                                    geti18nText('lights.delete.constraint.violation.exception')
                                )
                                .replace(':', '');
                        }
                    } else if (result.data.message) {
                        if (result.data.message.toString().includes('violates foreign key constraint')) {
                            msg = geti18nText('lights.delete.constraint.violation.exception');
                        } else {
                            msg = result.data.message;
                        }
                    }
                }
                if (msg.length > 0) {
                    // error(msg);
                }
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.COMPANY.EDIT}
            setValues={setValues}
            onModalClose={onModalClose}
            customButtons={
                isCreate() || active
                    ? []
                    : [
                          {
                              text: geti18nText('app.default.button.delete'),
                              loading: props.loading,
                              style: { backgroundColor: '#c70000', color: 'white' },
                              className: '',
                              onClick: deleteCompany,
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.destructive.confirm'),
                              popConfirmOk: geti18nText('app.default.button.delete'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
            }
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                return { ...values, type: getEnumFormat(values.type), tier: getEnumFormat(values.tier) };
            }}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('company.edit.name')}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('company.validation.name'),
                                },
                            ]}
                        >
                            <Input disabled={!NySession.hasAnyRole(['ROLE_SUPERADMIN'])} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('company.edit.vatNumber')}
                            name="vatNumber"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('company.validation.vatNumber'),
                                },
                            ]}
                        >
                            <Input disabled={!NySession.hasAnyRole(['ROLE_SUPERADMIN'])} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('company.edit.address')} name="address">
                            <Input disabled={!NySession.hasAnyRole(['ROLE_SUPERADMIN'])} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('company.edit.phone')} name="phone">
                            <Input disabled={!NySession.hasAnyRole(['ROLE_SUPERADMIN'])} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('company.edit.type')} name="type">
                            <NySearchField
                                disabled={!NySession.hasAnyRole(['ROLE_ACCOUNT_ADMIN'])}
                                options={ENUMS_COMPANY_TYPE()}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('company.edit.tier')} name="tier">
                            <NySearchField
                                disabled={!NySession.hasAnyRole(['ROLE_FINANCE_ADMIN'])}
                                options={ENUMS_COMPANY_TIER()}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default CompanyEdit;

import { geti18nText, NyRequestResolver, NySession, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, notification, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

const { TabPane } = Tabs;

const SettingsIndex = (props: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    const boolSettings = ['app.2fa.force'];

    useEffect(() => {
        loadSettings();
    }, []);

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function loadSettings() {
        setFetching(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.GET_SETTINGS, { group: 'application' }).then(
            (result: any) => {
                setFetching(false);
                if (result.status === RESPONSE.OK) {
                    groupSettings(result.data);
                }
            }
        );
    }

    function groupSettings(ungroupedSettings: any[]) {
        let groupedSettings: any = {};
        for (const setting of ungroupedSettings) {
            if (!groupedSettings[setting.settingGroup]) {
                groupedSettings[setting.settingGroup] = {};
            }
            if (boolSettings.includes(setting.settingKey)) {
                groupedSettings[setting.settingGroup][setting.settingKey] = {
                    id: setting.id,
                    value: setting.settingValue == true || setting.settingValue == 'true',
                };
            } else {
                groupedSettings[setting.settingGroup][setting.settingKey] = {
                    id: setting.id,
                    value: setting.settingValue,
                };
            }
        }
        form.setFieldsValue(groupedSettings);
    }

    function ungroupSettings(groupedSettings: any) {
        let ungroupedSettings: any[] = [];
        for (const settingGroup in groupedSettings) {
            if (groupedSettings[settingGroup]) {
                for (const settingKey in groupedSettings[settingGroup]) {
                    if (groupedSettings[settingGroup][settingKey]) {
                        const setting = {
                            id: groupedSettings[settingGroup][settingKey].id,
                            settingGroup: settingGroup,
                            settingKey: settingKey,
                            settingValue: groupedSettings[settingGroup][settingKey].value,
                        };
                        ungroupedSettings.push(setting);
                    }
                }
            }
        }
        return ungroupedSettings;
    }

    function saveSettings() {
        const settings = ungroupSettings(form.getFieldsValue());
        setLoading(true);
        const body = { settings: settings };
        NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.SET_SETTINGS, undefined, body).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
            } else {
                if (result.data && result.data.error) {
                    error(JSON.stringify(result.data.error));
                }
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Form layout="vertical" form={form}>
                {fetching ? (
                    <NySpinner />
                ) : (
                    <Tabs type="card">
                        <TabPane tab={geti18nText('setting.application.tab.general')} key="1">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item style={{ display: 'none' }} name={['application', 'app.url', 'id']}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.url')}
                                        name={['application', 'app.url', 'value']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                {NySession.getAppValue('I18N').language.available.map((lang: any) => {
                                    return (
                                        <Col span={8}>
                                            <Form.Item
                                                style={{ display: 'none' }}
                                                name={['application', 'app.privacy.policy.url.' + lang, 'id']}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={
                                                    geti18nText('setting.application.app.privacy.policy.url') +
                                                    ' (' +
                                                    lang +
                                                    ')'
                                                }
                                                name={['application', 'app.privacy.policy.url.' + lang, 'value']}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </TabPane>
                        <TabPane tab={geti18nText('setting.application.tab.contact')} key="2">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.contact.info.phone', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.contact.info.phone')}
                                        name={['application', 'app.contact.info.phone', 'value']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.contact.info.email', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.contact.info.email')}
                                        name={['application', 'app.contact.info.email', 'value']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.contact.sales.info.phone', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.contact.sales.info.phone')}
                                        name={['application', 'app.contact.sales.info.phone', 'value']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.contact.sales.info.email', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.contact.sales.info.email')}
                                        name={['application', 'app.contact.sales.info.email', 'value']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={geti18nText('setting.application.tab.2fa')} key="3">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.2fa.force', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        valuePropName="checked"
                                        label={geti18nText('setting.application.app.2fa.force')}
                                        name={['application', 'app.2fa.force', 'value']}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={['application', 'app.2fa.grace.period', 'id']}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={geti18nText('setting.application.app.2fa.grace.period')}
                                        name={['application', 'app.2fa.grace.period', 'value']}
                                        initialValue={14}
                                    >
                                        <Input type="number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                )}
                <div className="ant-row buttons-sticky">
                    <div className="ant-col ant-col-4 ant-col-offset-20">
                        <Button
                            type="primary"
                            onClick={() => saveSettings()}
                            disabled={loading || fetching}
                            style={{ float: 'right' }}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default SettingsIndex;

import { Col, Row } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { NySpinner } from '@nybble/nyreact';

const MyLineChart = (props: any) => {
    const formatter = (value: any) => `${value}` + (props.yAxisUnit ? props.yAxisUnit : '');

    const CustomTooltip = (data: any) => {
        if (data.active) {
            return (
                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        padding: '5px',
                        borderRadius: '0.3em',
                    }}
                >
                    <Row>
                        <span>{data.label}</span>
                    </Row>
                    {data.payload != null &&
                        data.payload.map((entry: any) => (
                            <Row>
                                <span style={{ color: entry.color }}>{entry.value}</span>
                            </Row>
                        ))}
                </div>
            );
        }
        return null;
    };

    return (
        <Row style={{ minWidth: '400px' }}>
            <Col span={24}>
                <div className="ant-table-small" style={{ marginLeft: 5, marginRight: 5 }}>
                    <Row style={{ marginTop: 20, marginLeft: 30 }}>
                        <Col span={12}>
                            <div style={{ fontSize: 36, fontWeight: 'bold' }}>{props.title}</div>
                        </Col>
                        <Col span={6}>{props.statistic}</Col>
                        <Col span={6}></Col>
                    </Row>
                    <ResponsiveContainer width="100%" height={props.height}>
                        {props.loading ? (
                            <NySpinner />
                        ) : (
                            <LineChart
                                data={props.data}
                                margin={{
                                    top: 20,
                                    right: 10,
                                    left: 10,
                                    bottom: 5,
                                }}
                            >
                                {props.showTooltip && <Tooltip content={<CustomTooltip />} />}
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey={props.xAxisDataKey ? props.xAxisDataKey : 'XAxisValue'} />
                                <YAxis tickFormatter={formatter} />
                                <Line
                                    type="monotone"
                                    dataKey="value"
                                    stroke={props.lineColor ? props.lineColor : '#8884d8'}
                                    dot={false}
                                />
                            </LineChart>
                        )}
                    </ResponsiveContainer>
                    <Row style={{ marginLeft: 50, marginBottom: 10 }}>
                        {props.customButton && props.customButton()}
                        {props.customSwitch && props.customSwitch()}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default MyLineChart;

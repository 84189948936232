import { PoweroffOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Statistic from '../../components/statistic';
import { CONSTANTS_REQ } from '../../utils/Constants';

const DeviceStatus = (props: any) => {
    const { deviceId } = props;
    const [deviceStatus, setDeviceStatus] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getLatestDeviceStatus();
    }, []);

    function getLatestDeviceStatus() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.LATEST_DEVICE_STATUS, {
            deviceId: deviceId,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setDeviceStatus(result.data);
            }
        });
    }

    function setDefaultFilterValue() {
        let filter = [{ field: 'device_id', condition: 'equals', value: deviceId }];
        return filter;
    }

    function getValueWithMeasurementUnit(value: any) {
        if (value) {
            if (value > 1000) {
                return (value / 1000).toFixed(2) + ' kWh';
            }
            return value + ' Wh';
        }
        return '';
    }

    return (
        <>
            <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.createDate')}
                            value={
                                deviceStatus.createDate
                                    ? moment(deviceStatus.createDate).format('DD.MM.YYYY HH:mm:ss')
                                    : ''
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.insertedDate')}
                            value={
                                deviceStatus.insertedDate
                                    ? moment(deviceStatus.insertedDate).format('DD.MM.YYYY HH:mm:ss')
                                    : ''
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.activeEnergy')}
                            value={getValueWithMeasurementUnit(deviceStatus.activeEnergy)}
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.version')}
                            value={deviceStatus.version}
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.emergency')}
                            value={
                                deviceStatus.emergency ? (
                                    <span style={{ color: 'red' }}>{geti18nText('app.default.button.yes')}</span>
                                ) : (
                                    <span style={{ color: 'green' }}>{geti18nText('app.default.button.no')}</span>
                                )
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.cb')}
                            value={
                                deviceStatus.cb ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.r1')}
                            value={
                                deviceStatus.r1 ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                            loading={!loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.r2')}
                            value={
                                deviceStatus.r2 ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.i1')}
                            value={
                                deviceStatus.i1 ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                        <Statistic
                            title={geti18nText('devices.status.table.column.i2')}
                            value={
                                deviceStatus.i2 ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                            loading={loading}
                        ></Statistic>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '24px' }}>
                <Col>
                    <NyDataTable
                        url={CONSTANTS_REQ.DEVICE.DEVICE_STATUSES}
                        readonly={false}
                        hideButtons={true}
                        scroll={{ y: 10000, x: 800 }}
                        setDefaultFilterValue={setDefaultFilterValue}
                        onRowSelect={(record) => {
                            setDeviceStatus(record);
                        }}
                        columns={[
                            {
                                title: geti18nText('devices.status.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('devices.status.table.column.insertedDate'),
                                dataIndex: 'insertedDate',
                                render: (text: any, record: any) => {
                                    if (record.insertedDate) {
                                        return moment(record.insertedDate).format('DD.MM.YYYY HH:mm:ss');
                                    }
                                },
                                sorter: (a: any, b: any) => {},
                                ...getColumnDateOption(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.activeEnergy'),
                                dataIndex: 'activeEnergy',
                                render: (text: any, record: any) => {
                                    if (record.activeEnergy) {
                                        return getValueWithMeasurementUnit(record.activeEnergy);
                                    }
                                },
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('devices.status.table.column.version'),
                                dataIndex: 'version',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('devices.status.table.column.emergency'),
                                dataIndex: 'emergency',
                                width: '10%',
                                render: (text: any, record: { emergency: any }) => {
                                    if (record.emergency) {
                                        return (
                                            <span style={{ color: 'red' }}>
                                                {geti18nText('app.default.button.yes')}
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <span style={{ color: 'green' }}>
                                                {geti18nText('app.default.button.no')}
                                            </span>
                                        );
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.cb'),
                                dataIndex: 'cb',
                                width: '10%',
                                render: (text: any, record: { cb: any }) => {
                                    if (record.cb) {
                                        return <PoweroffOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <PoweroffOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.i1'),
                                dataIndex: 'i1',
                                width: '10%',
                                render: (text: any, record: { i1: any }) => {
                                    if (record.i1) {
                                        return <PoweroffOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <PoweroffOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.i2'),
                                dataIndex: 'i2',
                                width: '10%',
                                render: (text: any, record: { i2: any }) => {
                                    if (record.i2) {
                                        return <PoweroffOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <PoweroffOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.r1'),
                                dataIndex: 'r1',
                                width: '10%',
                                render: (text: any, record: { r1: any }) => {
                                    if (record.r1) {
                                        return <PoweroffOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <PoweroffOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                            {
                                title: geti18nText('devices.status.table.column.r2'),
                                dataIndex: 'r2',
                                width: '10%',
                                render: (text: any, record: { r2: any }) => {
                                    if (record.r2) {
                                        return <PoweroffOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <PoweroffOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(
                                    ENUMS.ACTIVE(),
                                    'equals_bool',
                                    setDefaultFilterValue()[0]['value']
                                ),
                                defaultFilteredValue: setDefaultFilterValue(),
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default DeviceStatus;

import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { APPLICATION_KEY, CONSTANTS_REQ } from './Constants';

export interface IEnum {
    enumName: 'DEVICE_HD_SD_TYPE' | 'LIGHT_STATUS' | 'MEASUREMENT_RANGE_TYPE';
}

export interface IEnumValue extends IEnum {
    value: any;
}

export const GetEnum = ({ enumName }: IEnum) => {
    const en = loadFromSession(APPLICATION_KEY + 'enums');

    if (en !== undefined && en[enumName] !== undefined) {
        return en[enumName];
    } else {
        return undefined;
    }
};

export const LoadEnums = async function () {
    await NyRequestResolver.requestGet(CONSTANTS_REQ.ENUMS).then((response: any) => {
        if (response.status === RESPONSE.OK) {
            sessionStorage.setItem(APPLICATION_KEY + 'enums', JSON.stringify(response.data));
        }
    });
};

export const GetEnumNameForValue = function ({ enumName, value }: IEnumValue) {
    const enums = GetEnum({ enumName: enumName });

    if (enums === undefined) {
        return undefined;
    }

    for (const [key, enumValue] of Object.entries(enums)) {
        if (enumValue === value) {
            return key;
        }
    }

    return undefined;
};

export function loadFromSession(key: string) {
    const values = sessionStorage.getItem(key);
    if (values && values !== 'undefined') {
        return JSON.parse(values);
    }
    return undefined;
}

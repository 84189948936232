import React, { useState } from 'react';
import { Upload, message, Button, Modal, Switch } from 'antd';
import { geti18nText, NySession } from '@nybble/nyreact';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const ImportCSV = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(props.addedData);

    const showModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };

    const switchChange = (checked: any) => {
        if (props.switchData) {
            let temp = data;
            temp[props.switchData.name] = checked;
            setData(temp);
        }
    };

    const overwriteChange = (checked: any) => {
        let temp = data;
        temp['overwriteData'] = checked;
        setData(temp);
    };

    const uploadFile = {
        name: 'file',
        action: props.url,
        showUploadList: false,
        multiple: false,
        data: data,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                props.save();
                setVisible(false);
            } else if (info.file.status === 'error') {
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    return (
        <React.Fragment>
            <Button onClick={showModal} type="primary">
                {geti18nText('import.title')}
            </Button>

            <Modal
                title={geti18nText('import.title')}
                visible={visible}
                onCancel={closeModal}
                destroyOnClose={true}
                footer={null}
            >
                {props.switchData && (
                    <p>
                        <Switch onChange={switchChange}></Switch> {geti18nText(props.switchData.text)}
                    </p>
                )}
                <p>
                    <Switch onChange={overwriteChange}></Switch> {geti18nText('import.text.switch.overwrite')}
                </p>
                <Dragger {...uploadFile}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-hint">{geti18nText('import.text')}</p>
                </Dragger>
            </Modal>
        </React.Fragment>
    );
};

export default ImportCSV;

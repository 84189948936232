import { NySession } from '@nybble/nyreact';
import ImportCSV from '../../components/import-csv';
import { CONSTANTS_REQ } from '../../utils/Constants';

const SubstationImport = (props: any) => {
    return (
        <ImportCSV
            addedData={{ projectId: NySession.getProjectId() }}
            url={CONSTANTS_REQ.SUBSTATION.IMPORT_CSV}
            save={props.onPopupSave}
        />
    );
};

export default SubstationImport;

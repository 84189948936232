import { NySession } from '@nybble/nyreact';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { hasAnyCompanyTier } from '../../utils/Utils';

const HomeIndex = () => {
    const history = useHistory();

    useEffect(() => {
        if (
            NySession.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER']) &&
            hasAnyCompanyTier(['LITE', 'COMPACT', 'PRO'])
        ) {
            history.push('/project');
        } else if (NySession.hasAnyRole(['ROLE_COMPANY_ACCOUNT_HOLDER'])) {
            history.push('/companyAccountSettings');
        }
    }, []);

    return <></>;
};

export default HomeIndex;

import { useState } from 'react';
import { Button } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { downloadFile } from '../../utils/Utils';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

const LightExport = (props: any) => {
    const [loading, setLoading] = useState(false);

    function exportCSV() {
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.LIGHT.EXPORT_CSV,
            { projectId: NySession.getProjectId() },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK && downloadFile(result, 'Lights.csv')) {
            } else {
                console.log(JSON.stringify(result.data.error));
            }
        });
    }

    return (
        <Button disabled={loading} onClick={exportCSV} type="primary">
            {geti18nText('export.title')}
        </Button>
    );
};

export default LightExport;

import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, notification, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

const { TextArea } = Input;

const NotificationSettingsIndex = (props: any) => {
    const [form] = Form.useForm();
    const [projectId, setProjectId] = useState(NySession.getProjectId());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadSettings();
    }, []);

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function loadSettings() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.GET_SETTINGS, {
            group: 'mail-report',
            projectId: projectId,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                groupSettings(result.data);
            }
        });
    }

    function groupSettings(ungroupedSettings: any[]) {
        let groupedSettings: any = {};
        for (const setting of ungroupedSettings) {
            if (!groupedSettings[setting.settingGroup]) {
                groupedSettings[setting.settingGroup] = {};
            }
            groupedSettings[setting.settingGroup][setting.settingKey] = { id: setting.id, value: setting.settingValue };
        }
        form.setFieldsValue(groupedSettings);
    }

    function ungroupSettings(groupedSettings: any) {
        let ungroupedSettings: any[] = [];
        for (const settingGroup in groupedSettings) {
            if (groupedSettings[settingGroup]) {
                for (const settingKey in groupedSettings[settingGroup]) {
                    if (groupedSettings[settingGroup][settingKey]) {
                        const setting = {
                            id: groupedSettings[settingGroup][settingKey].id,
                            projectId: projectId,
                            settingGroup: settingGroup,
                            settingKey: settingKey,
                            settingValue: groupedSettings[settingGroup][settingKey].value,
                        };
                        ungroupedSettings.push(setting);
                    }
                }
            }
        }
        return ungroupedSettings;
    }

    function saveSettings() {
        const settings = ungroupSettings(form.getFieldsValue());
        setLoading(true);
        const body = { settings: settings };
        NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.SET_SETTINGS, undefined, body).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
            } else {
                if (result.data && result.data.error) {
                    error(JSON.stringify(result.data.error));
                }
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Form layout="vertical" form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'energy.last-24h', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.energy.last-24h')}
                            name={['mail-report', 'energy.last-24h', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'energy.last-month', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.energy.last-month')}
                            name={['mail-report', 'energy.last-month', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'energy.last-year', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.energy.last-year')}
                            name={['mail-report', 'energy.last-year', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'power.last-24h', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.power.last-24h')}
                            name={['mail-report', 'power.last-24h', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'power.last-month', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.power.last-month')}
                            name={['mail-report', 'power.last-month', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ display: 'none' }} name={['mail-report', 'power.last-year', 'id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.power.last-year')}
                            name={['mail-report', 'power.last-year', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            style={{ display: 'none' }}
                            name={['mail-report', 'light-status-activity.notification', 'id']}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.light-status-activity.notification')}
                            name={['mail-report', 'light-status-activity.notification', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            style={{ display: 'none' }}
                            name={['mail-report', 'light-status-activity.today', 'id']}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.light-status-activity.today')}
                            name={['mail-report', 'light-status-activity.today', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            style={{ display: 'none' }}
                            name={['mail-report', 'light-status-activity.this_week', 'id']}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.light-status-activity.this_week')}
                            name={['mail-report', 'light-status-activity.this_week', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            style={{ display: 'none' }}
                            name={['mail-report', 'light-status-activity.this_month', 'id']}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('setting.mail-report.light-status-activity.this_month')}
                            name={['mail-report', 'light-status-activity.this_month', 'value']}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="ant-row buttons-sticky">
                    <div className="ant-col ant-col-4 ant-col-offset-20">
                        <Button
                            type="primary"
                            onClick={() => saveSettings()}
                            disabled={loading}
                            style={{ float: 'right' }}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default NotificationSettingsIndex;

import { geti18nText } from '@nybble/nyreact';
import { Col, Form, Radio, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import FormItem from 'antd/lib/form/FormItem';

const DayOfWeek = (props: any) => {
    const { value = '0123456', setValue = undefined, disabled = '', checkable = false } = props;
    const dayNums = [1, 2, 3, 4, 5, 6, 0];

    function setDayCheck(dayNum: number, checked: boolean) {
        if (setValue) {
            if (checked && !value.includes('' + dayNum)) {
                setValue(value + dayNum);
            } else if (!checked) {
                setValue(value.replace('' + dayNum, ''));
            }
        }
    }

    return (
        <Row gutter={10} className="ant-form ant-form-vertical">
            {dayNums.map((dayNum: number) => {
                return (
                    <Col key={dayNum}>
                        <FormItem label={geti18nText('dayOfWeek.day.' + dayNum).substring(0, 1)}>
                            {!checkable && (
                                <Radio
                                    key={'r_' + dayNum}
                                    checked={value ? value.includes('' + dayNum) : false}
                                ></Radio>
                            )}
                            {checkable && (
                                <Checkbox
                                    key={'cb_' + dayNum}
                                    disabled={disabled.includes('' + dayNum)}
                                    checked={value ? value.includes('' + dayNum) : false}
                                    onChange={(e: any) => {
                                        setDayCheck(dayNum, e.target.checked);
                                    }}
                                ></Checkbox>
                            )}
                        </FormItem>
                    </Col>
                );
            })}
        </Row>
    );
};

export default DayOfWeek;

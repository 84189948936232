import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, message, Modal, notification, Row, Tooltip } from 'antd';
import moment, { duration } from 'moment';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { CONSTANTS_REQ } from '../../utils/Constants';

const AccessTokenIndex = () => {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [token, setToken] = useState<any>(undefined);
    const [tokenModalVisible, setTokenModalVisible] = useState(false);

    const setDefaultFilterValue = () => {
        return [];
    };

    const addedButtons = () => {
        return (
            <Button type="primary" disabled={loading} onClick={generateToken}>
                {geti18nText('accessToken.button')}
            </Button>
        );
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function generateToken() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER.ACCESS_TOKEN_GENERATE, {}).then((response: any) => {
            if (response && response.status === RESPONSE.CREATED) {
                okNotification();
                setRefresh(refresh + 1);
            } else {
                error(JSON.stringify(response.data.error));
            }
            setLoading(false);
        });
    }

    function copyTokenToClipboard() {
        navigator.clipboard.writeText(token);
        notification.info({ message: geti18nText('app.default.copy.clipboard'), description: '', duration: 3 });
    }

    function getQrCode() {
        let value: any = { access_token: token };
        if (NySession.getUser()) {
            value.id = NySession.getUser().id;
            value.username = NySession.getUser().username;
            value.company = NySession.getUser().company;
        }
        return JSON.stringify(value);
    }

    return (
        <>
            <NyDataTable
                url={CONSTANTS_REQ.USER.ACCESS_TOKEN_LIST}
                headerTitle={geti18nText('accessToken.table.header')}
                buttonsClassName={'buttons-sticky'}
                setDefaultFilterValue={setDefaultFilterValue}
                showRecordModal={false}
                hideNewButton={true}
                fetchWhenChange={refresh}
                addedButtons={addedButtons}
                scroll={{ y: 10000, x: 800 }}
                onRowSelect={(record: any) => {
                    setToken(record.accessToken);
                    setTokenModalVisible(true);
                }}
                columns={[
                    {
                        title: geti18nText('accessToken.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('accessToken.table.column.token'),
                        dataIndex: ['accessToken'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: any, record: any) => {
                            if (record.accessToken) {
                                return (
                                    <>
                                        <div className="table-column-ellipsis">{record.accessToken}</div>
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('accessToken.table.column.createDate'),
                        dataIndex: ['createDate'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(),
                        render: (text: any, record: any) => {
                            if (record.createDate) {
                                return moment(record.createDate).format('DD.MM.YYYY HH:mm:ss');
                            }
                        },
                    },
                    {
                        title: geti18nText('accessToken.table.column.expireDate'),
                        dataIndex: ['expireDate'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(),
                        render: (text: any, record: any) => {
                            if (record.expireDate) {
                                return moment(record.expireDate).format('DD.MM.YYYY HH:mm:ss');
                            }
                        },
                    },
                    {
                        title: geti18nText('accessToken.table.column.valid'),
                        dataIndex: 'valid',
                        width: '10%',
                        render: (text: any, record: { valid: any }) => {
                            if (record.valid) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                    },
                ]}
            />
            <Modal
                title={geti18nText('accessToken.table.column.token')}
                visible={tokenModalVisible}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => {
                    setTokenModalVisible(false);
                    setToken(undefined);
                }}
                width="70vw"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Tooltip title={geti18nText('app.default.copy')}>
                            <Button style={{ marginLeft: '5px' }} onClick={() => copyTokenToClipboard()}>
                                <CopyOutlined />
                            </Button>
                        </Tooltip>
                        <div style={{ marginTop: '5px', border: '1px solid #eeeeee', padding: '10px' }}>
                            {token && <span>{token}</span>}
                        </div>
                    </Col>
                    <Col span={24}>
                        <span></span>
                        <div style={{ marginTop: '5px', padding: '10px' }}>
                            <QRCode
                                value={getQrCode()}
                                size={256}
                                style={{
                                    height: 'auto',
                                    maxWidth: '256px',
                                    width: '90%',
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default AccessTokenIndex;

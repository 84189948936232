import React, { useState } from 'react';
import { Col, Row, Button, DatePicker } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import moment from 'moment';
import 'moment/locale/hr';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { downloadFile } from '../../utils/Utils';

const { RangePicker } = DatePicker;

const ReportIndex = () => {
    const [params, setParams] = useState({});

    function onRangeChange(dates: any) {
        console.log(dates, params);
        const timestampFrom = dates[0] ? moment(dates[0]).startOf('day').valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).endOf('day').valueOf() : null;
        setParams((prevState) => {
            return { ...prevState, timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
    }

    function downloadReport() {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.REPORT.APP_USAGE,
            { ...params, lang: NyUtils.getSelectedLanguage() },
            false,
            true
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                downloadFile(result, 'report-xls.xlsx');
            } else {
                console.log(JSON.stringify(result.data.error));
            }
        });
    }

    return (
        <React.Fragment>
            <Row>
                <Col span={24}>
                    {geti18nText('reports.appUsage.range')}
                    <br />
                    <RangePicker defaultValue={[moment(), moment()]} format="DD.MM.YYYY" onChange={onRangeChange} />
                </Col>
                <Col span={24}>
                    <Button type="primary" onClick={downloadReport} style={{ marginTop: '20px' }}>
                        {geti18nText('reports.appUsage.download')}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ReportIndex;

import { ReloadOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, Modal, notification, Row, Tabs } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { APPLICATION_KEY, CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../utils/Utils';

const { TabPane } = Tabs;

export const AccountEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [username, setUsername] = useState(undefined);
    const [twoFaForced, setTwoFaForced] = useState(false);
    const [twoFaGracePeriod, setTwoFaGracePeriod] = useState(14);
    const [twoFaEnabled, setTwoFaEnabled] = useState(false);
    const [twoFaSecret, setTwoFaSecret] = useState(undefined);
    const [twoFaRegisterRequired, setTwoFaRegisterRequired] = useState(false);
    const [twoFaCode, setTwoFaCode] = useState(false);
    const [şhowTwoFaCodeError, setShowTwoFaCodeError] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);

    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const urlQuery = new URLSearchParams(props.location.search);
    const goToTwoFa = urlQuery.get('twoFa');

    useEffect(() => {
        loadSettings();
        fetch();
    }, []);

    useEffect(() => {
        if (twoFaGracePeriodExpired()) {
            setActiveTab('3');
        }
    }, [twoFaGracePeriodExpired()]);

    function loadSettings() {
        let parsedSettings: any = {};
        const sessionSettings = sessionStorage.getItem(APPLICATION_KEY + 'settings');
        if (sessionSettings && sessionSettings !== 'undefined') {
            parsedSettings = JSON.parse(sessionSettings);
        }
        if (parsedSettings && parsedSettings['application']) {
            if (
                parsedSettings['application']['app.2fa.force'] &&
                parsedSettings['application']['app.2fa.force']['value']
            ) {
                setTwoFaForced(parsedSettings['application']['app.2fa.force']['value'] == 'true');
            }
            if (
                parsedSettings['application']['app.2fa.grace.period'] &&
                parsedSettings['application']['app.2fa.grace.period']['value']
            ) {
                setTwoFaGracePeriod(Number(parsedSettings['application']['app.2fa.grace.period']['value']));
            }
        }
    }

    function fetch() {
        setFetching(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER.CURRENT).then((result: any) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                let resultObject = result.data;
                if (resultObject.id) {
                    setValues(resultObject);
                    if (goToTwoFa) {
                        setActiveTab('3');
                    }
                }
            }
        });
    }

    function fetchTwoFaSecret() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.AUTH.GENERATE_SECRET).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                let resultObject = result.data;
                if (resultObject.secret) {
                    form.setFieldsValue({ twoFaSecret: resultObject.secret });
                    setTwoFaSecret(resultObject.secret);
                    setTwoFaRegisterRequired(true);
                    setShowTwoFaCodeError(false);
                }
            }
        });
    }

    function setValues(account: any) {
        setEditHeader(geti18nText('navbar.user.account'));
        setAccount(account.id);
        setUsername(account.username);
        setTwoFaEnabled(account.twoFaEnabled);
        setTwoFaSecret(account.twoFaSecret);

        if (account.company) {
            account.company = setSearchFormat(account.company);
        }
        if (!account.twoFaEnabled) {
            setTwoFaRegisterRequired(true);
        }

        delete account.active;
        form.setFieldsValue(account);
    }

    function save(e: any) {
        e.preventDefault();
        if (props.onBeforeSave) {
            props.onBeforeSave();
        }
        form.validateFields().then((values: any) => {
            setLoading(true);
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.USER.UPDATE_ACCOUNT + '/' + account,
                undefined,
                normalize(values)
            ).then((result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                } else {
                    if (result.data && result.data.error && result.data.error == 'username_exists') {
                        error(geti18nText('register.validation.username.taken'));
                    } else {
                        error('');
                    }
                }
            });
        });
    }

    function changePassword(e: any) {
        e.preventDefault();
        passwordForm.validateFields().then((values: any) => {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.USER.UPDATE_PASSWORD + '/' + account, undefined, values).then(
                (result: any) => {
                    setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        setChangePasswordVisible(false);
                    } else {
                        if (result.data && result.data.error && result.data.error == 'wrong_password') {
                            error(geti18nText('register.validation.password.wrong'));
                        } else {
                            error('');
                        }
                    }
                }
            );
        });
    }

    function register2Fa() {
        setShowTwoFaCodeError(false);
        const paramsBody = {
            twoFaSecret: twoFaSecret,
            code: twoFaCode,
        };

        NyRequestResolver.requestPost(CONSTANTS_REQ.AUTH.REGISTER, undefined, paramsBody).then((result) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                let session = NyUtils.loadSession();
                if (session.user.twoFaEnabled != undefined) {
                    session.user.twoFaEnabled = true;
                }
                NyUtils.saveSession(session);
                setTwoFaRegisterRequired(false);
            } else {
                setShowTwoFaCodeError(true);
            }
        });
    }

    function twoFaEnabledChange(checked: any) {
        if (checked && !twoFaSecret) {
            fetchTwoFaSecret();
        }
        setTwoFaEnabled(checked);
    }

    function getAuthenticatorQrCode() {
        return 'otpauth://totp/' + geti18nText('app.title') + '?secret=' + twoFaSecret;
    }

    function twoFaGracePeriodExpired() {
        if (
            twoFaForced &&
            !NySession.getUser().twoFaEnabled &&
            NySession.getUser().twoFaGracePeriodStart &&
            !moment().isBefore(
                moment(NySession.getUser().twoFaGracePeriodStart, 'YYYY-MM-DD HH:mm:ss').add(
                    twoFaGracePeriod * 86400000,
                    'milliseconds'
                )
            )
        ) {
            return true;
        }
        return false;
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    const normalize = (values: any) => {
        let normalized = {
            ...values,
            roles: Array.isArray(form.getFieldValue('roles'))
                ? form.getFieldValue('roles').map((item: any) => {
                      let ret: any = {};
                      if (item.hasOwnProperty('value')) {
                          ret.id = parseInt(item.value, 10);
                      } else if (item.hasOwnProperty('id')) {
                          ret.id = parseInt(item.id, 10);
                      }
                      return ret;
                  })
                : null,
        };

        normalized.twoFaEnabled = twoFaEnabled;

        if (normalized.company) {
            normalized.company = getSearchFormat(normalized.company);
        }

        return normalized;
    };

    const setDefaultFilterValue = () => {
        let filter: any = [
            { field: 'project.active', condition: 'equals_bool', value: 1 },
            { field: 'user.id', condition: 'equals', value: account },
        ];
        return filter;
    };

    const validatePassword = (rule: any, value: any, callback: any) => {
        if (value === undefined || value.length < 6) {
            callback(geti18nText('register.validation.password.short'));
        } else {
            callback();
        }
    };

    return (
        <>
            <Modal
                title={geti18nText('users.edit.password_change')}
                visible={changePasswordVisible}
                onCancel={() => setChangePasswordVisible(false)}
                onOk={changePassword}
                cancelText={geti18nText('app.default.button.cancel')}
                okText={geti18nText('app.default.button.save')}
                destroyOnClose={true}
                width={300}
            >
                <Form layout="vertical" form={passwordForm}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('users.edit.oldPassword')}
                                name="oldPassword"
                                rules={[{ required: true, message: geti18nText('register.validation.password') }]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('users.edit.newPassword')}
                                name="newPassword"
                                rules={[
                                    { required: true, message: geti18nText('register.validation.password') },
                                    {
                                        validator: validatePassword,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('users.edit.confirmPassword')}
                                name="confirmPassword"
                                dependencies={['newPassword']}
                                rules={[
                                    { required: true, message: geti18nText('register.validation.password') },
                                    {
                                        validator: validatePassword,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(geti18nText('companyRegistration.validation.passwordMatch'))
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Form layout="vertical" form={form}>
                {fetching ? (
                    <NySpinner />
                ) : (
                    <Tabs type="card" activeKey={activeTab} onChange={(tab: any) => setActiveTab(tab)}>
                        <TabPane
                            tab={geti18nText('user.tab.general')}
                            key="1"
                            disabled={twoFaGracePeriodExpired()}
                            style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        >
                            <Row gutter={24}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="password" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="forcePassChange" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="twoFaEnabled" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="twoFaSecret" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="twoFaGracePeriodStart" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Col xs={12} xl={6}>
                                    <Form.Item
                                        label={
                                            geti18nText('users.edit.username') +
                                            ' (' +
                                            geti18nText('users.table.column.username') +
                                            ')'
                                        }
                                        name="username"
                                        rules={
                                            username !== 'admin'
                                                ? [
                                                      {
                                                          type: 'email',
                                                          message: geti18nText('users.validation.username.email'),
                                                      },
                                                      {
                                                          required: true,
                                                          message: geti18nText('users.validation.username'),
                                                      },
                                                  ]
                                                : []
                                        }
                                    >
                                        <Input disabled={fetching || loading} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} xl={6}>
                                    <Form.Item label={geti18nText('users.edit.first_name')} name="firstName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <Form.Item label={geti18nText('users.edit.last_name')} name="lastName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <Form.Item label={geti18nText('users.edit.company')} name="company">
                                        <NySearchField
                                            disabled={true}
                                            url={CONSTANTS_REQ.COMPANY.SEARCH}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="name"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={geti18nText('user.tab.projects')}
                            key="2"
                            disabled={fetching || !account || twoFaGracePeriodExpired()}
                            style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        >
                            <NyDataTable
                                setDefaultSortOrder={{ order: 'projectName', orderType: 'asc' }}
                                url={CONSTANTS_REQ.USER.PROJECT_LIST}
                                hideButtons={true}
                                readonly={true}
                                setDefaultFilterValue={setDefaultFilterValue}
                                scroll={{ y: 10000, x: 800 }}
                                columns={[
                                    {
                                        title: geti18nText('project.table.column.id'),
                                        dataIndex: ['project', 'id'],
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('project.table.column.name'),
                                        dataIndex: ['project', 'projectName'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('project.table.column.parent'),
                                        dataIndex: ['project', 'parent', 'projectName'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('users.projects.table.column.role'),
                                        dataIndex: ['role', 'authority'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                ]}
                            />
                        </TabPane>
                        <TabPane
                            tab={geti18nText('user.tab.2fa')}
                            key="3"
                            style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        >
                            <Row gutter={24}>
                                <Col>
                                    <Form.Item name="twoFaEnabled">
                                        <span>{geti18nText('users.edit.twoFa.enabled')}</span>
                                        <Checkbox
                                            disabled={loading || fetching || (twoFaForced && twoFaEnabled)}
                                            style={{ marginLeft: '10px' }}
                                            checked={twoFaEnabled}
                                            onChange={(e: any) => {
                                                twoFaEnabledChange(e.target.checked);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col>
                                    {twoFaEnabled && (
                                        <>
                                            <Row gutter={24}>
                                                <Col style={{ padding: '12px 36px' }}>
                                                    <QRCode
                                                        value={getAuthenticatorQrCode()}
                                                        size={256}
                                                        style={{
                                                            height: 'auto',
                                                            maxWidth: '256px',
                                                            width: '90%',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        color: 'black',
                                                        width: 'calc(100% - 28px)',
                                                        maxWidth: '500px',
                                                    }}
                                                >
                                                    <Form.Item
                                                        label={geti18nText('users.edit.twoFa.secret')}
                                                        name="twoFaSecret"
                                                    >
                                                        <Input disabled={true} style={{ color: 'black' }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Button
                                                        style={{ marginTop: '24px' }}
                                                        disabled={loading}
                                                        icon={<ReloadOutlined />}
                                                        onClick={fetchTwoFaSecret}
                                                    ></Button>
                                                </Col>
                                            </Row>

                                            {twoFaRegisterRequired && (
                                                <Row gutter={12}>
                                                    <Col>
                                                        <Form.Item
                                                            label={geti18nText('users.edit.twoFa.codeInput')}
                                                            name="twoFaCodeInput"
                                                        >
                                                            <Input
                                                                onChange={(e: any) => setTwoFaCode(e.target.value)}
                                                            />
                                                            {şhowTwoFaCodeError && (
                                                                <Text type="danger">
                                                                    {geti18nText('app.default.verify.code.error')}
                                                                </Text>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            style={{ marginTop: '24px' }}
                                                            type="primary"
                                                            onClick={(e: any) => register2Fa()}
                                                            disabled={loading || fetching || !twoFaCode}
                                                        >
                                                            {geti18nText('users.edit.twoFa.register')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </Col>
                                <Col>
                                    <Row gutter={12}>
                                        <b>{geti18nText('users.edit.twoFa.activation.steps')}</b>
                                    </Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.1')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.2')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.3')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.4')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.5')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.activation.steps.6')}</Row>
                                    <Row gutter={12}>
                                        <b>{geti18nText('users.edit.twoFa.deactivation.steps')}</b>
                                    </Row>
                                    <Row>{geti18nText('users.edit.twoFa.deactivation.steps.1')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.deactivation.steps.2')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.deactivation.steps.3')}</Row>
                                    <Row gutter={12}>
                                        <b>{geti18nText('users.edit.twoFa.changeSecret.steps')}</b>
                                    </Row>
                                    <Row>{geti18nText('users.edit.twoFa.changeSecret.steps.1')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.changeSecret.steps.2')}</Row>
                                    <Row>{geti18nText('users.edit.twoFa.changeSecret.steps.3')}</Row>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                )}
                <div className="ant-row buttons-sticky">
                    <div className="ant-col ant-col-4 ant-col-offset-20">
                        {!twoFaGracePeriodExpired() && (
                            <>
                                <Button
                                    type="primary"
                                    onClick={(e: any) => save(e)}
                                    disabled={loading || fetching}
                                    style={{ float: 'right' }}
                                >
                                    {geti18nText('app.default.button.save')}
                                </Button>
                                <Button
                                    onClick={(e: any) => setChangePasswordVisible(true)}
                                    disabled={loading || fetching}
                                    style={{ float: 'right' }}
                                >
                                    {geti18nText('users.edit.password_change')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Form>
        </>
    );
};

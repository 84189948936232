import React, { useState, useEffect, ReactText } from 'react';
import { Button, Modal, Form, Select, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

const DeviceSync = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        setHasSelected(props.hasSelected);
        setSelectedRowKeys(props.selectedRowKeys);
    }, [props]);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = (e: any) => {
        setVisible(false);
    };

    function showNotification(title: any, message: any, duration: any) {
        notification.success({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3);
    }

    function syncDevices() {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SYNC, undefined, {
            projectId: NySession.getProjectId(),
            deviceIds: hasSelected ? selectedRowKeys : undefined,
        }).then((result) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                props.clearSelectedRowKeys();
                props.onPopupSave();
                setSelectedRowKeys([]);
                setHasSelected(false);
                setVisible(false);
                okNotification();
            }
        });
    }

    function onSubmit() {
        if (hasSelected) {
            syncDevices();
        } else {
            showModal();
        }
    }

    return (
        <React.Fragment>
            <Button type="primary" onClick={onSubmit} style={{ marginLeft: '10px' }}>
                {geti18nText('app.default.button.sync')}
            </Button>

            <Modal
                title={geti18nText('app.default.sync')}
                visible={visible}
                onCancel={handleCancel}
                onOk={syncDevices}
                okText={geti18nText('app.default.button.yes')}
                cancelText={geti18nText('app.default.button.no')}
                destroyOnClose
            >
                {geti18nText('devices.table.dialog.sync.all')}
            </Modal>
        </React.Fragment>
    );
};

export default DeviceSync;

import { CONSTANTS_REQ } from '../../utils/Constants';
import { hasAnyProjectRole } from '../../utils/Utils';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import SubstationEdit from './edit';
import SubstationExport from './SubstationExport';
import SubstationImport from './SubstationImport';
import React, { useState } from 'react';

const SubstationIndex = (props: any) => {
    const [num, setNum] = useState(0);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    const ExportImportButtons = () => {
        return (
            <React.Fragment>
                {React.createElement(SubstationImport, { onPopupSave: () => setNum(num + 1) })}
                {React.createElement(SubstationExport)}
            </React.Fragment>
        );
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('substations.table.header')}
            url={props.remapUrl == null ? CONSTANTS_REQ.SUBSTATION.LIST : props.remapUrl}
            addNewButtonText={geti18nText('substations.table.add')}
            buttonsClassName={'buttons-sticky'}
            addedButtons={canCreate() ? ExportImportButtons : SubstationExport}
            showRecordModal={true}
            modalComponent={SubstationEdit}
            fetchWhenChange={num}
            hideNewButton={!canCreate()}
            setDefaultFilterValue={setDefaultFilterValue}
            scroll={{ y: 10000, x: 800 }}
            columns={[
                {
                    title: geti18nText('lights.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('substations.table.colum.name'),
                    dataIndex: 'substationId',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('substations.table.column.lat'),
                    dataIndex: 'lat',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('substations.table.column.lng'),
                    dataIndex: 'lng',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    align: 'right',
                },
                {
                    title: geti18nText('substations.table.colum.zone'),
                    dataIndex: 'timezone',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                ...(!NySession?.getProjectInfo()?.parent?.id
                    ? [
                          {
                              title: geti18nText('lights.edit.project'),
                              dataIndex: ['project', 'projectName'],
                              sorter: (a: any, b: any) => {},
                              ...getColumnSearch('string'),
                          },
                      ]
                    : []),
                {
                    title: geti18nText('devices.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default SubstationIndex;

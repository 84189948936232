import { geti18nText, NyDataEdit, NyDatePicker, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row, Switch, Tabs, TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    CONSTANTS_REQ,
    ENUMS_PROGRAM_OUTPUT,
    ENUMS_PROGRAM_TYPE,
    PROGRAM_OUTPUT,
    PROGRAM_TYPE,
} from '../../utils/Constants';
import { hasAnyCompanyTier, hasAnyProjectRole, setEnumFormat } from '../../utils/Utils';
import DayOfWeek from './DayOfWeek';
import IOMatrix from './IOMatrix';
import ProgramDevicesIndex from './ProgramDevices';

const allDays = '0123456';

export const ProgramEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('programs.edit.new'));
    const [program, setProgram] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [nameTaken, setNameTaken] = useState(false);
    const [project, setProject] = useState(NySession.getProjectId());
    const [type, setType] = useState<any>(
        hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) ? PROGRAM_TYPE.USER : PROGRAM_TYPE.ASTRO
    );
    const [nameDirty, setNameDirty] = useState(false);
    const [daysOfWeekValue, setDaysOfWeekValue] = useState<any>(allDays);
    const [daysOfWeekValid, setDaysOfWeekValid] = useState(true);
    const [daysOfWeekDisabled, setDaysOfWeekDisabled] = useState<any>(undefined);
    const [startDate, setStartDate] = useState<any>(moment());
    const [activeTab, setActiveTab] = useState('1');
    const [devicesRefresh, setDevicesRefresh] = useState(0);
    const [inputs, setInputs] = useState(undefined);
    const [relays, setRelays] = useState(undefined);
    const [inputRelays, setInputRelays] = useState(undefined);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const { addedData } = props;
    const { currNum } = addedData ? addedData : { currNum: undefined };

    const { TabPane } = Tabs;

    function canCreate() {
        return (
            (NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN'])) &&
            (hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) || type == PROGRAM_TYPE.ASTRO)
        );
    }

    function isCreate() {
        return props.value == undefined;
    }

    function validateNameTaken(rule: any, value: any, callback: any) {
        if (nameTaken) {
            callback(geti18nText('programs.validation.name.taken'));
        } else {
            callback();
        }
    }

    function validateDaysOfWeek(value: string) {
        if (type == PROGRAM_TYPE.USER && value.length < 1) {
            setDaysOfWeekValid(false);
            return false;
        } else {
            setDaysOfWeekValid(true);
        }
        return true;
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('programs.edit.new'));
        setProgram(undefined);
        setType(
            hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) ? PROGRAM_TYPE.USER : PROGRAM_TYPE.ASTRO
        );
        setNameDirty(false);
        setDaysOfWeekValue(allDays);
        setDaysOfWeekDisabled(undefined);
        setStartDate(moment());
        setDaysOfWeekValid(true);
        setActiveTab('1');
        setInputs(undefined);
        setRelays(undefined);
        setInputRelays(undefined);
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('programs.edit.name') + ': ' + dataForm.name + ' (' + dataForm.id + ')');
        setProgram(dataForm.id);
        setNameDirty(true);
        if (dataForm.project != undefined) {
            setProject(dataForm.project.id);
        }
        if (dataForm.daysOfWeek != undefined) {
            setDaysOfWeekValue(dataForm.daysOfWeek);
        }
        if (dataForm.startDate != undefined) {
            dataForm.startDate = moment(dataForm.startDate);
            setStartDate(dataForm.startDate);
        }
        if (dataForm.endDate != undefined) {
            dataForm.endDate = moment(dataForm.endDate);
        }
        if (dataForm.onTime != undefined) {
            dataForm.onTime = moment(dataForm.onTime, 'HH:mm');
        }
        if (dataForm.offTime != undefined) {
            dataForm.offTime = moment(dataForm.offTime, 'HH:mm');
        }

        if (dataForm.type != undefined) {
            setType(dataForm.type);
            if (dataForm.type == PROGRAM_TYPE.USER) {
                onDatesChange(dataForm.startDate, dataForm.endDate, dataForm.daysOfWeek);
            }
            dataForm.type = setEnumFormat('PROGRAM_TYPE', dataForm.type, 'enum.programType');
        }

        if (dataForm.output != undefined) {
            dataForm.output = setEnumFormat('PROGRAM_OUTPUT', dataForm.output, 'enum.programOutput');
        }

        if (dataForm.inputs != undefined) {
            setInputs(dataForm.inputs);
        }
        if (dataForm.relays != undefined) {
            setRelays(dataForm.relays);
        }
        if (dataForm.inputRelays != undefined) {
            setInputRelays(dataForm.inputRelays);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    function normalize(values: any) {
        let normalizedValues = { ...props.addedData };
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                if (values[key] instanceof Array) {
                    if (values[key].length === 1) {
                        normalizedValues[key] = values[key][0].key;
                    } else {
                        normalizedValues[key] = values[key].map((element: any) => '' + element.key);
                    }
                } else if (values[key] instanceof moment) {
                    if (['offTime', 'onTime'].includes(key)) {
                        normalizedValues[key] = values[key].format('HH:mm');
                    } else {
                        normalizedValues[key] = values[key].format('YYYY-MM-DD');
                    }
                } else if (values[key] instanceof Object && values[key]['id'] != undefined) {
                    normalizedValues[key] = values[key]['id'];
                } else {
                    if (values[key] === undefined) {
                        normalizedValues[key] = null;
                    } else {
                        normalizedValues[key] = values[key];
                    }
                }
            }
        }

        normalizedValues.project = { id: project };
        if (type != PROGRAM_TYPE.USER) {
            delete normalizedValues.startDate;
            delete normalizedValues.endDate;
            delete normalizedValues.onTime;
            delete normalizedValues.offTime;
            delete normalizedValues.daysOfWeek;
        } else {
            normalizedValues.daysOfWeek = daysOfWeekValue;
        }
        if (type != PROGRAM_TYPE.ASTRO) {
            delete normalizedValues.sunriseOffset;
            delete normalizedValues.sunsetOffset;
        }
        if (type != PROGRAM_TYPE.IO_MATRIX) {
            delete normalizedValues.inputs;
            delete normalizedValues.relays;
            delete normalizedValues.inputRelays;
        } else {
            delete normalizedValues.output;
            normalizedValues.inputs = inputs;
            normalizedValues.relays = relays;
            normalizedValues.inputRelays = inputRelays;
        }

        return normalizedValues;
    }

    function onDatesChange(startDate: any, endDate: any, daysOfWeek: any = undefined) {
        let newDaysOfWeek = '';
        if (!startDate) {
            setDaysOfWeekDisabled(allDays);
        } else if (!endDate || endDate.diff(startDate, 'days') >= 7) {
            setDaysOfWeekDisabled('');
            newDaysOfWeek = allDays;
        } else if (!endDate || endDate.diff(startDate, 'days') == 0) {
            setDaysOfWeekDisabled(allDays);
            newDaysOfWeek = '' + startDate.day();
        } else {
            let tempDisabled = '';
            let startDateDayOfWeek = startDate.day();
            let endDateDayOfWeek = endDate.day();
            if (startDateDayOfWeek <= endDateDayOfWeek) {
                tempDisabled = allDays.substring(0, startDateDayOfWeek) + allDays.substring(endDateDayOfWeek + 1);
            } else {
                tempDisabled = allDays.substring(endDateDayOfWeek + 1, startDateDayOfWeek);
            }
            setDaysOfWeekDisabled(tempDisabled);
            if (daysOfWeek) {
                setDaysOfWeekValue(daysOfWeek);
            } else {
                for (var i = 0; i < allDays.length; i++) {
                    if (!tempDisabled.includes(allDays[i])) {
                        newDaysOfWeek += allDays[i];
                    }
                }
            }
        }
        if (daysOfWeek) {
            setDaysOfWeekValue(daysOfWeek);
        } else {
            setDaysOfWeekValue(newDaysOfWeek);
        }
    }

    function changeName(type: any) {
        if (!nameDirty) {
            form.setFieldsValue({
                name:
                    'Program ' + geti18nText('enum.programType.' + type) + ' ' + (currNum != undefined ? currNum : ''),
            });
        }
    }

    function onTypeChange(type: any) {
        if (type && type.id > -1) {
            setType(type.id);
            changeName(type.id);
        } else {
            setType(undefined);
        }
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            buttonsClassName={'buttons-sticky'}
            setLoading={setLoading}
            url={CONSTANTS_REQ.PROGRAM.EDIT}
            setValues={setValues}
            onModalClose={onModalClose}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={normalize}
            checkBeforeSave={() => validateDaysOfWeek(daysOfWeekValue)}
            onBeforeSave={() => {
                setNameTaken(false);
            }}
            customErrorNotification={(result: any) => {
                if (result && result.status === RESPONSE.BAD_REQUEST) {
                    if (result.data && result.data.error) {
                        switch (result.data.error) {
                            case 'name_exist':
                                setNameTaken(true);
                                form.validateFields(['name']);
                                return;
                            default:
                                break;
                        }
                    }
                }
                if (result.data && result.data.error) {
                    error(JSON.stringify(result.data.error));
                }
            }}
            {...props}
        >
            <Tabs type="card" onChange={(tab: any) => setActiveTab(tab)}>
                <TabPane tab={geti18nText('programs.tab.general')} key="1">
                    <React.Fragment>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="project" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    label={geti18nText('programs.edit.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('validation.name'),
                                        },
                                        { validator: validateNameTaken },
                                    ]}
                                    initialValue={
                                        'Program ' +
                                        geti18nText('enum.programType.' + type) +
                                        ' ' +
                                        (currNum != undefined ? currNum : '')
                                    }
                                >
                                    <Input
                                        onChange={() => {
                                            setNameDirty(true);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    label={geti18nText('programs.edit.type')}
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('validation.type'),
                                        },
                                    ]}
                                    initialValue={setEnumFormat(
                                        'PROGRAM_TYPE',
                                        hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo())
                                            ? PROGRAM_TYPE.USER
                                            : PROGRAM_TYPE.ASTRO,
                                        'enum.programType'
                                    )}
                                >
                                    <NySearchField
                                        disabled={!hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo())}
                                        options={ENUMS_PROGRAM_TYPE()}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        onChange={onTypeChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}></Col>
                            {(type == PROGRAM_TYPE.USER || type == PROGRAM_TYPE.ASTRO) && (
                                <Col span={10}>
                                    <Form.Item
                                        label={geti18nText('programs.edit.output')}
                                        name="output"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('validation.output'),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value && value.id != -1) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(geti18nText('validation.output')));
                                                },
                                            }),
                                        ]}
                                        initialValue={setEnumFormat(
                                            'PROGRAM_OUTPUT',
                                            PROGRAM_OUTPUT.R1,
                                            'enum.programOutput'
                                        )}
                                    >
                                        <NySearchField
                                            options={ENUMS_PROGRAM_OUTPUT()}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                            {type == PROGRAM_TYPE.USER && (
                                <>
                                    <Col span={10}>
                                        <Form.Item
                                            label={geti18nText('programs.edit.startDate')}
                                            name="startDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('validation.startDate'),
                                                },
                                            ]}
                                            initialValue={startDate}
                                        >
                                            <NyDatePicker
                                                disabledDate={(current: any) => {
                                                    return moment().add(-1, 'days') >= current;
                                                }}
                                                style={{ width: '100%' }}
                                                onChange={(value: any) => {
                                                    setStartDate(value);
                                                    if (form.getFieldValue('endDate') < value) {
                                                        form.setFieldsValue({ endDate: value });
                                                    }
                                                    onDatesChange(value, form.getFieldValue('endDate'));
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}></Col>
                                    <Col span={10}>
                                        <Form.Item label={geti18nText('programs.edit.endDate')} name="endDate">
                                            <NyDatePicker
                                                disabledDate={(current: any) => {
                                                    return moment().add(-1, 'days') >= current || startDate >= current;
                                                }}
                                                style={{ width: '100%' }}
                                                onChange={(value: any) => {
                                                    onDatesChange(form.getFieldValue('startDate'), value);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label={geti18nText('programs.edit.onTime')}
                                            name="onTime"
                                            rules={[
                                                {
                                                    required: type == PROGRAM_TYPE.USER,
                                                    message: geti18nText('validation.onTime'),
                                                },
                                            ]}
                                            initialValue={moment('01:00', 'HH:mm')}
                                        >
                                            <TimePicker style={{ width: '100%' }} format={'HH:mm'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                                        <Form.Item name="turnOn" valuePropName="checked" initialValue={true}>
                                            <Switch
                                                className="program-switch"
                                                style={{
                                                    width: '100%',
                                                    marginTop: '29px',
                                                }}
                                                checkedChildren={geti18nText('programs.edit.turnOn')}
                                                unCheckedChildren={geti18nText('programs.edit.turnOff')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label={geti18nText('programs.edit.offTime')}
                                            name="offTime"
                                            rules={[
                                                {
                                                    required: type == PROGRAM_TYPE.USER,
                                                    message: geti18nText('validation.offTime'),
                                                },
                                            ]}
                                            initialValue={moment('00:00', 'HH:mm')}
                                        >
                                            <TimePicker style={{ width: '100%' }} format={'HH:mm'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <DayOfWeek
                                            value={daysOfWeekValue}
                                            setValue={(value: string) => {
                                                setDaysOfWeekValue(value);
                                                validateDaysOfWeek(value);
                                            }}
                                            disabled={daysOfWeekDisabled}
                                            checkable={true}
                                        ></DayOfWeek>
                                        {!daysOfWeekValid && (
                                            <span className="ant-form-item-explain-error">
                                                {geti18nText('programs.validation.daysOfWeek')}
                                            </span>
                                        )}
                                    </Col>
                                </>
                            )}
                            {type == PROGRAM_TYPE.ASTRO && (
                                <>
                                    <Col span={10}>
                                        <Form.Item
                                            label={geti18nText('programs.edit.sunsetOffset')}
                                            name="sunsetOffset"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('validation.sunsetOffset'),
                                                },
                                                {
                                                    pattern: new RegExp(/^[-,+]0([0-5]:[0-5][0-9]|6:00)$/),
                                                    message: geti18nText('validation.timeOffset.pattern'),
                                                },
                                            ]}
                                            initialValue="+00:30"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}></Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label={geti18nText('programs.edit.sunriseOffset')}
                                            name="sunriseOffset"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('validation.sunriseOffset'),
                                                },
                                                {
                                                    pattern: new RegExp(/^[-,+]0([0-5]:[0-5][0-9]|6:00)$/),
                                                    message: geti18nText('validation.timeOffset.pattern'),
                                                },
                                            ]}
                                            initialValue="-00:30"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            {type == PROGRAM_TYPE.IO_MATRIX && (
                                <>
                                    <Col span={24}>
                                        <IOMatrix
                                            inputs={inputs}
                                            setInputs={setInputs}
                                            relays={relays}
                                            setRelays={setRelays}
                                            inputRelays={inputRelays}
                                            setInputRelays={setInputRelays}
                                            disabled={false}
                                            checkable={true}
                                        ></IOMatrix>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </React.Fragment>
                </TabPane>
                <TabPane tab={geti18nText('programs.tab.devices')} key="2" disabled={isCreate() || loading}>
                    <Tabs type="card">
                        <TabPane
                            tab={geti18nText('programs.tab.devices.assigned')}
                            key={'programDevices' + program}
                            disabled={loading}
                        >
                            <ProgramDevicesIndex
                                program={program}
                                refresh={devicesRefresh}
                                setRefresh={setDevicesRefresh}
                            />
                        </TabPane>
                        <TabPane
                            tab={geti18nText('programs.tab.devices.other')}
                            key={'notProgramDevices' + program}
                            disabled={loading}
                        >
                            <ProgramDevicesIndex
                                program={program}
                                notDevices={true}
                                refresh={devicesRefresh}
                                setRefresh={setDevicesRefresh}
                            />
                        </TabPane>
                    </Tabs>
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

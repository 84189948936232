import { DashOutlined } from '@ant-design/icons';
import { geti18nText, NySession, NyUtils } from '@nybble/nyreact';
import { Layout, Menu, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { APPLICATION_KEY } from '../../utils/Constants';
import { hasAnyCompanyTier, hasAnyProjectRole } from '../../utils/Utils';
import { INyLayoutMenu } from './types';

const { Sider } = Layout;
const { SubMenu } = Menu;
const adminTierRoles = ['ROLE_SUPERADMIN'];

const LayoutMenu = ({
    menuHistory,
    menuItems,
    menuLocation,
    menuLogo,
    menuTheme = 'light',
    menuWidth = 220,
    siderClassName,
    menuClassName,
    noRoles = false,
}: INyLayoutMenu) => {
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [breakpoint, setBreakpoint] = useState<boolean>(false);
    const history = useHistory();

    const location: any = useLocation();

    useEffect(() => {
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname));
    }, []);

    useEffect(() => {
        if (findKey(menuItems, undefined, location.pathname)) {
            setSelectedKeys(findKey(menuItems, undefined, location.pathname));
        } else {
            setSelectedKeys([]);
        }
    }, [location]);

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const goToProject = (project: any) => {
        NySession.setProject(project);
        history.push('/');
        history.goBack();
    };

    const sameProjectCompany = (project: any) => {
        return project.company && NySession.getUser().company && project.company.id == NySession.getUser().company.id;
    };

    function generateMenu(menus: any, project: any = undefined) {
        let retValue = [];
        let menu: any;
        for (menu of menus) {
            if (hasAnyCompanyTier(menu.tier, project))
                if (NySession.hasAnyRole(menu.role) || noRoles || hasAnyProjectRole(menu.roleProject)) {
                    switch (menu.key) {
                        case 'menu.project':
                            if (NySession.getProjectId() != undefined) {
                                retValue.push(
                                    <SubMenu
                                        key={menu.key}
                                        onTitleClick={menuSelected}
                                        icon={menu.icon}
                                        title={
                                            <span
                                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}
                                            >
                                                {NySession.getProjectInfo()!.projectName}
                                            </span>
                                        }
                                    >
                                        {generateMenu(menu.submenu, NySession.getProjectInfo())}
                                    </SubMenu>
                                );
                            }
                            break;
                        case 'menu.project.accounts':
                        case 'menu.project.notifications':
                            if (
                                NySession.hasAnyRole(['ROLE_SUPERADMIN']) ||
                                hasAnyProjectRole(menu.roleProject) ||
                                sameProjectCompany(NySession.getProjectInfo())
                            ) {
                                retValue.push(
                                    <Menu.Item key={menu.key} icon={menu.icon}>
                                        {geti18nText(menu.i18n)}
                                    </Menu.Item>
                                );
                            }
                            break;
                        case 'menu.project.settings':
                            if (
                                NySession.hasAnyRole(['ROLE_SUPERADMIN']) ||
                                sameProjectCompany(NySession.getProjectInfo())
                            ) {
                                retValue.push(
                                    <Menu.Item key={menu.key} icon={menu.icon}>
                                        {geti18nText(menu.i18n)}
                                    </Menu.Item>
                                );
                            }
                            break;
                        case 'menu.privacyPolicy':
                            let parsedSettings: any = {};
                            const sessionSettings = sessionStorage.getItem(APPLICATION_KEY + 'settings');
                            if (sessionSettings && sessionSettings !== 'undefined') {
                                parsedSettings = JSON.parse(sessionSettings);
                            }
                            if (
                                parsedSettings &&
                                parsedSettings['application'] &&
                                parsedSettings['application'][
                                    'app.privacy.policy.url.' + NyUtils.getSelectedLanguage()
                                ] &&
                                parsedSettings['application'][
                                    'app.privacy.policy.url.' + NyUtils.getSelectedLanguage()
                                ]['value']
                            ) {
                                retValue.push(
                                    <Menu.Item
                                        key={menu.key}
                                        icon={menu.icon}
                                        onClick={() =>
                                            openInNewTab(
                                                parsedSettings['application'][
                                                    'app.privacy.policy.url.' + NyUtils.getSelectedLanguage()
                                                ]['value']
                                            )
                                        }
                                    >
                                        {geti18nText(menu.i18n)}
                                    </Menu.Item>
                                );
                            } else {
                                if (NySession.getSetting('privacy-url') != undefined) {
                                    NySession.getSetting('privacy-url').map((privacyPolicy: any) => {
                                        if (privacyPolicy[NyUtils.getSelectedLanguage()]) {
                                            retValue.push(
                                                <Menu.Item
                                                    key={menu.key}
                                                    icon={menu.icon}
                                                    onClick={() =>
                                                        openInNewTab(privacyPolicy[NyUtils.getSelectedLanguage()])
                                                    }
                                                >
                                                    {geti18nText(menu.i18n)}
                                                </Menu.Item>
                                            );
                                        }
                                    });
                                }
                            }
                            break;
                        case 'menu.project.subprojects':
                            project = NySession.getProjectInfo();
                            if (project && project.parent && project.parent.id) {
                                retValue.push(
                                    <Menu.Item
                                        key={'...'}
                                        icon={<DashOutlined />}
                                        onClick={(e: any) => {
                                            goToProject(project.parent);
                                        }}
                                    >
                                        {project.parent.projectName}
                                    </Menu.Item>
                                );
                            } else {
                                retValue.push(
                                    <Menu.Item key={menu.key} icon={menu.icon}>
                                        {geti18nText(menu.i18n)}
                                    </Menu.Item>
                                );
                            }
                            break;
                        default:
                            if (menu.submenu) {
                                retValue.push(
                                    <SubMenu
                                        key={menu.key}
                                        onTitleClick={menuSelected}
                                        icon={menu.icon}
                                        title={<span>{geti18nText(menu.i18n)}</span>}
                                    >
                                        {generateMenu(menu.submenu)}
                                    </SubMenu>
                                );
                            } else {
                                retValue.push(
                                    <Menu.Item key={menu.key} icon={menu.icon}>
                                        {geti18nText(menu.i18n)}
                                    </Menu.Item>
                                );
                            }
                    }
                }
        }

        return retValue;
    }

    function menuSelected(selected: { key: any }) {
        if (breakpoint && selected.hasOwnProperty('keyPath')) {
            setCollapsed(true);
        }
        setSelectedKeys(findKey(menuItems, selected.key));
    }

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                menuHistory.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            width={menuWidth}
            className={siderClassName}
            theme="light"
            onCollapse={(collapsed) => {
                setCollapsed(collapsed);
            }}
            onBreakpoint={(broken) => {
                setBreakpoint(broken);
            }}
            style={{
                height: '106vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <Menu
                theme="light"
                mode="inline"
                className={menuClassName}
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                defaultOpenKeys={['menu.project']}
            >
                {/*  */}
                {!collapsed && menuLogo}
                {generateMenu(menuItems)}
            </Menu>
        </Sider>
    );
};

export default LayoutMenu;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Select, DatePicker } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import moment from 'moment';
import { downloadFile } from '../../utils/Utils';
import { geti18nText, NyRequestResolver, NySession, RESPONSE, NyUtils } from '@nybble/nyreact';

const { Option } = Select;
const { RangePicker } = DatePicker;

const LightStatusActivityReport = (props: any) => {
    const [params, setParams] = useState<any>({});
    const [visible, setVisible] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [selected, setSelected] = useState<any>(undefined);

    useEffect(() => {
        onTimeRangeChange('this_month');
    }, []);

    const showModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };

    const downloadReport = () => {
        setLoading(true);
        let filename = 'report.csv';
        if (selected === 'today') {
            filename =
                moment().format('YYYY-MM-DD') +
                '_' +
                NySession.getProjectInfo()!.projectName +
                geti18nText('light.status.activity.report.filename') +
                '.csv';
        } else if (selected === 'this_week') {
            filename =
                moment().startOf('week').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('week').format('YYYY-MM-DD') +
                '_' +
                NySession.getProjectInfo()!.projectName +
                geti18nText('light.status.activity.report.filename') +
                '.csv';
        } else if (selected === 'this_month') {
            filename =
                moment().format('YYYY-MM') +
                '_' +
                NySession.getProjectInfo()!.projectName +
                geti18nText('light.status.activity.report.filename') +
                '.csv';
        } else if (selected === 'span' && params && params.timestampFrom && params.timestampTo) {
            filename =
                moment(params.timestampFrom).format('YYYY-MM-DD') +
                '_' +
                moment(params.timestampTo).format('YYYY-MM-DD') +
                '_' +
                NySession.getProjectInfo()!.projectName +
                geti18nText('light.status.activity.report.filename') +
                '.csv';
        } else {
            filename =
                NySession.getProjectInfo()!.projectName + geti18nText('light.status.activity.report.filename') + '.csv';
        }
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.LIGHT_STATUS_ACTIVITY.REPORT,
            { ...params, projectId: NySession.getProjectId(), lang: NyUtils.getSelectedLanguage() },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK && downloadFile(result, filename)) {
            } else {
                console.log(JSON.stringify(result.data.error));
            }
        });
    };

    function onTimeRangeChange(value: any) {
        setSelected(value);
        let timestampFrom: any = null;
        let timestampTo: any = null;
        if (value === 'today') {
            timestampFrom = moment().startOf('day').valueOf();
        } else if (value === 'this_week') {
            timestampFrom = moment().startOf('week').valueOf();
        } else if (value === 'this_month') {
            timestampFrom = moment().startOf('month').valueOf();
        }
        setParams((prevState: any) => {
            return {
                ...prevState,
                timestampFrom: timestampFrom,
                timestampTo: timestampTo,
            };
        });
    }

    function onRangeChange(dates: any) {
        const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
        setParams((prevState: any) => {
            return { ...prevState, timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
    }

    return (
        <React.Fragment>
            <Button onClick={showModal} type="primary">
                {geti18nText('light.status.activity.reports.title')}
            </Button>

            <Modal
                title={geti18nText('light.status.activity.reports.title')}
                visible={visible}
                onCancel={closeModal}
                destroyOnClose={true}
                footer={null}
            >
                <Row gutter={24} style={{ marginBottom: 10 }}>
                    <Col span={12}>
                        <Select
                            value={selected}
                            placeholder={geti18nText('devices.graph.filter.span')}
                            style={{ width: '100%' }}
                            onChange={onTimeRangeChange}
                        >
                            <Option value="today">{geti18nText('light.status.activity.report.today')}</Option>
                            <Option value="this_week">{geti18nText('light.status.activity.report.this_week')}</Option>
                            <Option value="this_month">{geti18nText('light.status.activity.report.this_month')}</Option>
                            <Option value="span">{geti18nText('report.time_range.span')}</Option>
                        </Select>
                    </Col>
                </Row>
                <Row gutter={24} hidden={selected != 'span'} style={{ marginBottom: 10 }}>
                    <Col span={24}>
                        <RangePicker showTime format="DD.MM.YYYY HH:mm:ss" onChange={onRangeChange} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            onClick={() => downloadReport()}
                            disabled={
                                loading || (selected === 'span' && (!params.timestampFrom || !params.timestampTo))
                            }
                        >
                            {geti18nText('light.status.activity.report.button')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    );
};

export default LightStatusActivityReport;

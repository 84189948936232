import React, { useState, useEffect, ReactText } from 'react';
import { Table, Col, Row, Button, Divider, Typography, Tooltip } from 'antd';
import { ICustomTable } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';
import { configure } from 'react-hotkeys';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';

configure({
    ignoreTags: [],
});

const CustomTable = ({
    rowKey = 'id',
    url,
    columns,
    headerTitle,
    addNewButtonText = geti18nText('app.default.button.add'),
    addedData,
    scroll,
    showRecordModal = false,
    showRowSelection = false,
    rowSelectionType = 'checkbox',
    readonly = false,
    hidePagination = false,
    hideButtons = false,
    hideNewButton = false,
    exportCSV = false,
    addedButtons,
    buttonsClassName,
    rowSelectionModal,
    setDefaultFilterValue,
    setDefaultSortOrder,
    onNewItem,
    onRowSelect,
    onRowMouseEnterHandler,
    onRowMouseLeaveHandler,
    onRowClickHandler,
    onSaveAndGetID,
    modalComponent,
    onDataLoaded,
    fetchWhenChange = 0,
    setDefaultCurrentPage = 1,
    setDefaultPageSize = 10,
    antdTableProps = {},
    exportRowsNumber = 1000,
    tableClassName,
    setRowClassName,
    defaultFilterDropdown,
    setDefaultSelectedRow = 0,
    onRowSelected,
    setDefaultSelectedRowKeys,
    shortcuts = false,
    nyTestId = undefined,
    selectOnSave = true,
    rowSelectionProps,
    colCSVHeaderType = 'TITLE',
    colCSVCustomization = undefined,
    csvColumns = undefined,
    CSVFileName = undefined,
    tableRowSize = 'small',
    onSelect,
    preserveSelectedRowKeys = false,
}: ICustomTable) => {
    const [pagination, setPagination] = useState<any>({
        defaultCurrent: setDefaultCurrentPage,
        defaultPageSize: setDefaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });
    const [hasAddedData, setHasAddedData] = useState<any>(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);
    const [current, setCurrent] = useState<any>(undefined);
    const [pageSize, setPageSize] = useState<any>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
    const [fetchParams, setFetchParams] = useState({});
    const [selectedRow, setSelectedRow] = useState(setDefaultSelectedRow);
    const [showTooltip, setShowTooltip] = useState(false);
    const { Title } = Typography;
    let history = useHistory();
    let location = useLocation();

    const onKeyDown = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            setShowTooltip(true);
        }
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (setDefaultSelectedRowKeys) {
            setSelectedRowKeys(setDefaultSelectedRowKeys);
        }
    }, [setDefaultSelectedRowKeys]);

    useEffect(() => {
        if (shortcuts) {
            window.addEventListener('keydown', onKeyDown);
            window.addEventListener('keyup', onKeyUp);
            return () => {
                window.removeEventListener('keydown', onKeyDown);
                window.removeEventListener('keyup', onKeyUp);
            };
        }
    }, []);

    useEffect(() => {
        if (addedData) {
            setHasAddedData(true);
        }
    }, [addedData]);

    useEffect(() => {
        fetch(getDefaultFilterValue());
    }, [hasAddedData]);

    useEffect(() => {
        if (fetchWhenChange > 0) {
            fetch(getDefaultFilterValue());
        }
    }, [fetchWhenChange]);

    function getDefaultFilterValue() {
        let sSearch: any = {};
        if (setDefaultFilterValue) {
            const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));
            if (stringUri.length > 0) {
                sSearch = { search: stringUri };
            }
        }

        if (setDefaultCurrentPage != undefined) {
            if (Object.keys(sSearch).length !== 0) {
                sSearch.offset = setDefaultCurrentPage - 1;
            } else {
                sSearch = { offset: setDefaultCurrentPage - 1 };
            }
        }

        if (setDefaultPageSize != undefined) {
            if (Object.keys(sSearch).length !== 0) {
                sSearch.max = setDefaultPageSize;
            } else {
                sSearch = { max: setDefaultPageSize };
            }
        }

        return sSearch;
    }

    function onPopupSave() {
        fetch(getDefaultFilterValue());
    }

    function handleTableChange(
        pagination: any,
        filters: Record<string, Object[] | null>,
        sorter: { [index: string]: any }
    ) {
        setPageSize(pagination.pageSize);
        setCurrent(pagination.current - 1);
        let params = {
            max: pagination.pageSize,
            offset: pagination.current - 1,
            ...getSort(sorter),
            ...getFilters(filters),
        };
        fetch(params);
    }

    function getFilters(filters: Record<string, Object[] | null>) {
        let transformedFilters: any = [];

        if (filters) {
            let deleteKey: any = [];
            for (var key in filters) {
                if (filters.hasOwnProperty(key)) {
                    const ff: any = filters[key];
                    if (ff != null && ff.length > 0) {
                        if (ff[0].value === '') {
                            deleteKey.push(key);
                        } else {
                            transformedFilters.push({ field: key, ...ff[0] });
                        }
                    }
                }
            }
            if (setDefaultFilterValue) {
                const defaultFilter = setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    if (transformedFilters.find(({ field }: any) => field == dFilter['field']) === undefined) {
                        transformedFilters.push(dFilter);
                    }
                }
            }
            deleteKey.forEach((key: any) => {
                transformedFilters = transformedFilters.filter((filter: any) => filter.field !== key);
            });
            return { search: encodeURI(JSON.stringify(transformedFilters)) };
        } else {
            if (setDefaultFilterValue) {
                const defaultFilter = setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    transformedFilters.push(dFilter);
                }
                return { search: encodeURI(JSON.stringify(transformedFilters)) };
            }
            return undefined;
        }
    }

    function getSort(sorter: { [index: string]: any }) {
        if (sorter.field) {
            const sort = { order: undefined, orderType: '' };
            sort.order = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;
            sort.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            return sort;
        } else {
            return undefined;
        }
    }

    function fetch(params: { [index: string]: any } = {}) {
        if (setDefaultSortOrder && !params.hasOwnProperty('order')) {
            params.order = setDefaultSortOrder.order;
            params.orderType = setDefaultSortOrder.orderType;
        }
        if (pageSize != null && params.max === undefined) {
            params.max = pageSize;
        }

        if (current != null && params.offset === undefined) {
            params.offset = current;
        }
        setFetchParams(params);
        setLoading(true);
        NyRequestResolver.requestGet(url, { ...params, ...addedData }).then((response) => {
            if (response && response.status === RESPONSE.OK) {
                const pageable = { ...pagination };
                if (response.data && typeof response.data !== 'string') {
                    const responseData: { [index: string]: any } | undefined = response.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];
                    setLoading(false);
                    setData(responseData.content);
                    if (responseData.content && responseData.content.length > 0) {
                        if (responseData.content.filter((item: any) => item.id === selectedRow)[0] === undefined) {
                            setSelectedRow(responseData.content[0].id);

                            if (onRowSelected != undefined) {
                                onRowSelected(responseData.content[0].id);
                            }
                        }
                    }
                    setPagination(pageable);
                    if (onDataLoaded != undefined) {
                        onDataLoaded(responseData.content, params);
                    }
                }
            } else {
                setData([]);
                setLoading(false);
            }
        });
    }

    function onNewItemHandler() {
        if (onNewItem) {
            onNewItem();
        } else {
            if (showRecordModal) {
                setPopupValue(undefined);
                setPopupVisible(true);
            } else {
                if (history) {
                    history.push(location.pathname + '/create');
                }
            }
        }
    }

    function onRowSelectHandler(record: any) {
        if (readonly === true) {
            return;
        }

        if (onRowSelect) {
            onRowSelect(record);
        } else {
            if (record && record[rowKey]) {
                if (showRecordModal) {
                    setPopupValue(record);
                    setPopupVisible(true);
                } else {
                    if (history) {
                        history.push(location.pathname + '/' + record[rowKey]);
                    }
                }
            } else {
                console.log('Record has no id field, no handler set');
            }
        }
    }

    const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: any) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        if (onSelect) {
            onSelect(selectedRowKeys, selectedRows);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: rowSelectionType,
        getCheckboxProps: rowSelectionProps ? rowSelectionProps : undefined,
        preserveSelectedRowKeys: preserveSelectedRowKeys,
    };

    function clearSelectedRowKeys() {
        setSelectedRowKeys([]);
    }

    const modalComponentOnSaveAndGetData = (data: any) => {
        if (showRowSelection && data && selectOnSave) {
            setSelectedRowKeys([data.id]);
            setSelectedRows([data]);
        }
    };

    return (
        <>
            <HotKeys
                keyMap={
                    shortcuts
                        ? {
                              ADD_NEW_ITEM_BUTTON: ['F1', 'alt+n'],
                              OPEN_FILTER_BUTTON: ['F3', 'alt+f'],
                              TABLE_ARROW_UP: ['ArrowUp'],
                              TABLE_ARROW_DOWN: ['ArrowDown'],
                              TABLE_ARROW_LEFT: ['ArrowLeft'],
                              TABLE_ARROW_RIGHT: ['ArrowRight'],
                              TABLE_ENTER: ['Enter'],
                          }
                        : {}
                }
                handlers={{
                    ADD_NEW_ITEM_BUTTON: () => {
                        onNewItemHandler();
                    },
                    OPEN_FILTER_BUTTON: (event: any) => {
                        event.preventDefault();
                        if (
                            defaultFilterDropdown !== null &&
                            defaultFilterDropdown !== undefined &&
                            defaultFilterDropdown != ''
                        ) {
                            let filterButton: any = document.querySelector(
                                `th.${defaultFilterDropdown} span.ant-dropdown-trigger`
                            );

                            let activeTh: any = document.querySelector('span.ant-table-filter-trigger-container-open');
                            if (activeTh) {
                                activeTh.querySelector('span.ant-dropdown-trigger').click();
                                activeTh = activeTh.parentNode.parentNode;
                                let allTh: any = document.querySelectorAll(
                                    'thead.ant-table-thead th.ant-table-cell:not(.ant-table-cell-scrollbar)'
                                );
                                if (allTh) {
                                    let index = Array.from(allTh).indexOf(activeTh);
                                    if (index >= 0) {
                                        index++;
                                        if (index > Array.from(allTh).length - 1) {
                                            index = 0;
                                        }
                                    }

                                    filterButton = allTh[index].querySelector('span.ant-dropdown-trigger');
                                }
                            }

                            if (filterButton) {
                                filterButton.click();
                                filterButton.focus();
                            }
                        }
                    },
                    TABLE_ENTER: (event: any) => {
                        if (event.target.nodeName !== 'INPUT') {
                            let currRow: any = document.querySelector(
                                'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                            );
                            if (currRow === null) {
                                currRow = document.querySelector(
                                    'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                                );
                            }
                            if (currRow !== undefined && currRow !== null) {
                                onRowSelectHandler({ id: parseInt(currRow.dataset.rowKey) });
                            }
                        }
                    },
                    TABLE_ARROW_UP: () => {
                        let list: any = document.querySelectorAll(
                            'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-table-row'
                        );
                        if (list.length === 0) {
                            list = document.querySelectorAll('tbody.ant-table-tbody > tr.ant-table-row');
                        }
                        let currRow: any = document.querySelector(
                            'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                        );
                        if (currRow === null) {
                            currRow = document.querySelector(
                                'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                            );
                        }
                        if (list.length > 0 && currRow !== undefined && currRow !== null) {
                            let nextRowId = 0;
                            Array.prototype.forEach.call(list, function (element, index) {
                                if (element.dataset.rowKey === currRow.dataset.rowKey) {
                                    nextRowId = index;
                                }
                            });
                            nextRowId--;
                            if (nextRowId < 0) {
                                nextRowId = list.length - 1;
                            }
                            let nexRowKey = parseInt(list[nextRowId].dataset.rowKey);
                            setSelectedRow(nexRowKey);

                            if (onRowSelected != undefined) {
                                onRowSelected(nexRowKey);
                            }
                        }
                    },
                    TABLE_ARROW_DOWN: () => {
                        let list: any = document.querySelectorAll(
                            'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-table-row'
                        );
                        if (list.length === 0) {
                            list = document.querySelectorAll('tbody.ant-table-tbody > tr.ant-table-row');
                        }

                        let currRow: any = document.querySelector(
                            'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                        );
                        if (currRow === null) {
                            currRow = document.querySelector(
                                'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                            );
                        }

                        if (list.length > 0 && currRow !== undefined && currRow !== null) {
                            let nextRowId = 0;
                            Array.prototype.forEach.call(list, function (element, index) {
                                if (element.dataset.rowKey === currRow.dataset.rowKey) {
                                    nextRowId = index;
                                }
                            });
                            nextRowId++;
                            if (nextRowId > list.length - 1) {
                                nextRowId = 0;
                            }
                            let nexRowKey = parseInt(list[nextRowId].dataset.rowKey);

                            setSelectedRow(nexRowKey);

                            if (onRowSelected != undefined) {
                                onRowSelected(nexRowKey);
                            }
                        }
                    },
                    TABLE_ARROW_LEFT: () => {
                        let prevButton: any = document.querySelector(
                            'div.ant-tabs-tabpane-active li.ant-pagination-prev'
                        );
                        if (prevButton === null) {
                            prevButton = document.querySelector('li.ant-pagination-prev');
                        }
                        if (prevButton !== null) {
                            if (!prevButton.className.includes('ant-pagination-disabled')) {
                                if (prevButton.children && prevButton.children.length > 0) {
                                    prevButton.children[0].click();
                                }
                            }
                        }
                    },
                    TABLE_ARROW_RIGHT: () => {
                        let nextButton: any = document.querySelector(
                            'div.ant-tabs-tabpane-active li.ant-pagination-next'
                        );
                        if (nextButton === null) {
                            nextButton = document.querySelector('li.ant-pagination-next');
                        }
                        if (nextButton !== null) {
                            if (!nextButton.className.includes('ant-pagination-disabled')) {
                                if (nextButton.children && nextButton.children.length > 0) {
                                    nextButton.children[0].click();
                                }
                            }
                        }
                    },
                }}
            >
                {headerTitle && (
                    <Divider orientation="left" plain>
                        <Title level={5}>{headerTitle}</Title>
                    </Divider>
                )}
                <Table
                    className={tableClassName}
                    size={tableRowSize}
                    columns={columns}
                    rowKey={(record: any) => record[rowKey]}
                    rowSelection={showRowSelection ? rowSelection : undefined}
                    pagination={hidePagination ? false : pagination}
                    dataSource={data}
                    loading={loading}
                    scroll={scroll}
                    onChange={handleTableChange}
                    onRow={(record: any) => {
                        return {
                            onDoubleClick: () => {
                                onRowSelectHandler(record);
                            },
                            onClick: () => {
                                setSelectedRow(record[rowKey]);
                                if (onRowSelected != undefined) {
                                    onRowSelected(record[rowKey]);
                                }
                                return onRowClickHandler ? onRowClickHandler(record) : undefined;
                            },
                            onMouseEnter: () => {
                                return onRowMouseEnterHandler ? onRowMouseEnterHandler(record) : undefined;
                            },
                            onMouseLeave: () => {
                                return onRowMouseLeaveHandler ? onRowMouseLeaveHandler(record) : undefined;
                            },
                        };
                    }}
                    rowClassName={(record) => {
                        return record[rowKey] != null && record[rowKey] === selectedRow
                            ? 'ant-select-item-option-selected'
                            : setRowClassName != null
                            ? setRowClassName(record)
                            : '';
                    }}
                    ny-test-id={nyTestId}
                    {...antdTableProps}
                />
            </HotKeys>
            {showRowSelection &&
                rowSelectionModal != undefined &&
                rowSelectionModal(
                    selectedRowKeys.length > 0,
                    selectedRowKeys,
                    selectedRows,
                    onPopupSave,
                    clearSelectedRowKeys
                )}
            {hideButtons !== true && (
                <Row gutter={[0, 24]} className={buttonsClassName}>
                    <Col span={24}>
                        {hideNewButton !== true && (
                            <Tooltip
                                placement="top"
                                visible={showTooltip}
                                title={geti18nText('app.default.shortcuts.addNewItem')}
                            >
                                <Button
                                    ny-test-id={nyTestId ? nyTestId + '-btn-new' : undefined}
                                    type="primary"
                                    onClick={() => onNewItemHandler()}
                                >
                                    {addNewButtonText}
                                </Button>
                            </Tooltip>
                        )}
                        {addedButtons != undefined && addedButtons(onPopupSave)}
                    </Col>
                </Row>
            )}
            {showRecordModal
                ? modalComponent != undefined &&
                  React.createElement(
                      modalComponent,
                      {
                          isModal: true,
                          visible: popupVisible,
                          setVisible: setPopupVisible,
                          value: popupValue,
                          onSave: onPopupSave,
                          onSaveAndGetID: onSaveAndGetID,
                          onSaveAndGetData: modalComponentOnSaveAndGetData,
                          addedData: addedData,
                      },
                      null
                  )
                : null}
        </>
    );
};

export default CustomTable;

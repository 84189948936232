import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, InputNumber, Modal, notification, Row, Tabs } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ, ENUMS_DEVICE_NOTIFICATION_TYPE } from '../../utils/Constants';
import { hasAnyCompanyTier, hasAnyProjectRole, setSearchFormat } from '../../utils/Utils';
import OverviewIndex from '../overview';
import ProgramIndex from '../program';
import { DeviceAdditionalData } from './DeviceAdditionalData';
import { DeviceDataChart } from './DeviceDataChart';
import { DeviceLightHealthCheck } from './DeviceLightHealthCheck';
import DeviceManagement from './DeviceManagement';
import DeviceStatus from './DeviceStatus';

const DeviceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('devices.edit.new'));
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(NySession.getProjectId());
    const [substation, setSubstation] = useState(null);
    const [device, setDevice] = useState(null);
    const [deviceName, setDeviceName] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [recordError, setRecordError] = useState(false);
    const [record, setRecord] = useState<any>(undefined);
    const [nameTaken, setNameTaken] = useState(false);
    const [macAddressTaken, setMacAddressTaken] = useState(false);
    const [deactivateVisible, setDeactivateVisible] = useState(false);
    const [exportGraph, setExportGraph] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [refreshOverviewInterval, setRefreshOverviewInterval] = useState<any>(null);
    const [refreshChartInterval, setRefreshChartInterval] = useState<any>(null);
    const [programs, setPrograms] = useState<any>(null);
    var ToggleActive: any = null;

    const [form] = Form.useForm();

    const { TabPane } = Tabs;

    useEffect(() => {
        if (!isCreate()) {
            fetch();
        }
    }, []);

    useEffect(() => {
        setExportGraph(0);
        setEditHeader(geti18nText('devices.edit.new'));
        setActiveTab('1');
        if (props.isModal && props.visible && !isCreate()) {
            fetch();
        }
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        setRecordError(false);
        NyRequestResolver.requestGet(getUrl()).then((result: any) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                let resultObject = result.data;
                if (resultObject.id) {
                    setRecord({ ...resultObject });
                    if (props.visible) {
                        setValues(resultObject);
                    }
                    if (props.openManagementTab) {
                        setActiveTab('2');
                    }
                } else {
                    setRecordError(true);
                }
            } else {
                setRecordError(true);
            }
        });
    }

    function setValues(device: any) {
        setEditHeader(
            geti18nText('devices.edit.name') +
                ': ' +
                device.name +
                ' (' +
                device.id +
                '), ' +
                (device.macAddress ? geti18nText('devices.table.column.mac') + ': ' + device.macAddress : '')
        );
        setDevice(device.id);
        setDeviceName(device.name);

        if (device.substation) {
            device.substation = setSearchFormat(device.substation, 'substationId');
            setSubstation(device.substation.id);
        }
        if (device.project) {
            setProject(device.project.id);
        }
        if (device.programs) {
            setPrograms(device.programs);
        }

        delete device.active;
        form.setFieldsValue(device);
    }

    function isCreate() {
        return props.value == undefined;
    }

    function closePopup(isSave = false) {
        if (refreshOverviewInterval) {
            clearInterval(refreshOverviewInterval);
            setRefreshOverviewInterval(null);
        }
        if (refreshChartInterval) {
            clearInterval(refreshChartInterval);
            setRefreshChartInterval(null);
        }
        props.setVisible(false);
        if (isSave === true && props.onSave) {
            props.onSave();
        }
        setActiveTab('1');
        form.resetFields();
    }

    function save(e: any) {
        e.preventDefault();
        if (props.onBeforeSave) {
            props.onBeforeSave();
        }
        form.validateFields().then((values: any) => {
            if (ToggleActive != null) {
                values['active'] = ToggleActive;
            }
            setLoading(true);
            makeRequest(values).then((result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.CREATED) {
                    setLoading(false);
                    okNotification();
                    const data: any = result.data;
                    if (data && data.message && data.message == 'device_and_program_type_exist') {
                        infoNotification(geti18nText('programs.notification.program.skipped'));
                    }
                    if (props.onSaveAndGetID && result.data.id) {
                        props.onSaveAndGetID(result.data.id);
                    }
                } else {
                    if (result && result.status === RESPONSE.BAD_REQUEST) {
                        if (result.data && result.data.field) {
                            switch (result.data.field) {
                                case 'name_exist':
                                    setNameTaken(true);
                                    props.form.validateFields(['name'], { force: true }, () => {
                                        setNameTaken(false);
                                    });
                                    return;
                                case 'mac_address_exist':
                                    setMacAddressTaken(true);
                                    props.form.validateFields(['macAddress'], { force: true }, () => {
                                        setMacAddressTaken(false);
                                    });
                                    openDeactivatePopup();
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                    if (result.data && result.data.error) {
                        error(JSON.stringify(result.data.error));
                    }
                }
                toggleActive(null);
                if (props.isModal) {
                    closePopup(true);
                }
            });
        });
    }

    function normalize(values: any) {
        let normalizedValues = { ...props.addedData };
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                if (values[key] instanceof Array) {
                    if (values[key].length === 1) {
                        normalizedValues[key] = values[key][0].key;
                    } else {
                        normalizedValues[key] = values[key].map((element: any) => '' + element.key);
                    }
                } else if (values[key] instanceof moment) {
                    if (props.momentsFormat && props.momentsFormat[key]) {
                        normalizedValues[key] = values[key].format(props.momentsFormat[key]);
                    } else {
                        normalizedValues[key] = values[key];
                    }
                } else if (values[key] instanceof Object && values[key]['key']) {
                    normalizedValues[key] = values[key]['key'];
                } else {
                    if (values[key] === undefined) {
                        normalizedValues[key] = null;
                    } else {
                        normalizedValues[key] = values[key];
                    }
                }
            }
        }

        return {
            ...normalizedValues,
            macAddress: normalizedValues.macAddress != null ? normalizedValues.macAddress.replace(/\:/g, '') : null,
            substation:
                normalizedValues.substation != null && normalizedValues.substation.constructor === String
                    ? { id: parseInt(normalizedValues.substation) }
                    : normalizedValues.substation,
            project: { id: project },
            programs: programs,
        };
    }

    function validateNameTaken(rule: any, value: any, callback: any) {
        if (nameTaken) {
            callback(geti18nText('devices.validation.name.taken'));
        } else {
            callback();
        }
    }

    function validateMacAddressTaken(rule: any, value: any, callback: any) {
        if (macAddressTaken) {
            callback(geti18nText('devices.validation.mac.taken'));
        } else {
            callback();
        }
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    function infoNotification(info: any) {
        notification.info({
            message: '',
            description: info,
            duration: 5,
        });
    }

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function hideActivate() {
        return isCreate() || record === undefined || record.active === true;
    }

    function hideDeactivate() {
        return isCreate() || record === undefined || record.active === false;
    }

    function toggleActive(value: any) {
        ToggleActive = value;
    }

    function makeRequest(values: any) {
        if (isCreate()) {
            return NyRequestResolver.requestPost(getUrl(), undefined, normalize(values));
        } else {
            return NyRequestResolver.requestPut(getUrl(), undefined, normalize(values));
        }
    }

    function getUrl() {
        return isCreate() ? CONSTANTS_REQ.DEVICE.EDIT : CONSTANTS_REQ.DEVICE.EDIT + '/' + props.value.id;
    }

    function getButtons() {
        return canCreate()
            ? [
                  <Button
                      key="back"
                      onClick={(e) => {
                          setExportGraph((exportGraph) => exportGraph + 1);
                      }}
                      style={activeTab === '3' ? { float: 'left' } : { display: 'none' }}
                  >
                      {geti18nText('app.default.export.csv')}
                  </Button>,
                  <Button key="back" onClick={(e) => closePopup()}>
                      {geti18nText('app.default.button.cancel')}
                  </Button>,
                  <Button
                      key="activate"
                      style={{ backgroundColor: 'green', color: 'white' }}
                      className={hideActivate() ? 'hidden' : ''}
                      onClick={(e) => {
                          toggleActive(true);
                          save(e);
                      }}
                      disabled={props.loading}
                  >
                      {' '}
                      {geti18nText('app.default.button.activate')}
                  </Button>,
                  <Button
                      key="deactivate"
                      type="primary"
                      danger
                      className={hideDeactivate() ? 'hidden' : ''}
                      disabled={props.loading}
                      onClick={(e) => {
                          toggleActive(false);
                          save(e);
                      }}
                  >
                      {' '}
                      {geti18nText('app.default.button.deactivate')}
                  </Button>,
                  <Button key="submit" type="primary" loading={props.loading} onClick={(e) => save(e)}>
                      {geti18nText('app.default.button.save')}
                  </Button>,
              ]
            : [
                  <Button key="back" onClick={(e) => closePopup()}>
                      {geti18nText('app.default.button.cancel')}
                  </Button>,
              ];
    }

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    function openDeactivatePopup() {
        setDeactivateVisible(true);
    }

    function closeDeactivatePopup() {
        setDeactivateVisible(false);
    }

    function deactivate() {
        if (props.form && props.form.getFieldValue('macAddress')) {
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEACTIVATE, {
                macAddress: props.form.getFieldValue('macAddress').replace(/\:/g, ''),
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    setMacAddressTaken(false);
                    props.form.validateFields(['macAddress'], { force: true }, () => {});
                }
                setLoading(false);
                closeDeactivatePopup();
            });
        } else {
            closeDeactivatePopup();
        }
    }

    function setDefaultFilterValue() {
        return [{ field: 'device_id', condition: 'equals', value: device }];
    }

    const dataColumns = [
        {
            title: geti18nText('devices.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('devices.table.data.column.code'),
            dataIndex: 'code',
            render: (text: any, row: any, index: any) => {
                return geti18nText(text);
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('devices.table.data.column.value'),
            dataIndex: 'value',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            align: 'right',
        },
        {
            title: geti18nText('devices.table.data.column.date'),
            dataIndex: 'ts_converted',
            render: (text: any, record: any) => {
                if (record.ts) {
                    return moment(record.ts).format('DD.MM.YYYY HH:mm:ss');
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
        },
    ];

    const activityColumns = [
        {
            title: geti18nText('devices.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('devices.table.data.column.date'),
            dataIndex: 'last_activity',
            render: (text: any, record: any) => {
                if (record.lastActivity) {
                    return moment(record.lastActivity).format('DD.MM.YYYY HH:mm:ss');
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
        },
    ];

    const notificationColumns = [
        {
            title: geti18nText('devices.notifications.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('devices.notifications.table.column.date'),
            dataIndex: 'notification_date',
            render: (text: any, record: any) => {
                if (record.notificationDate) {
                    return moment(record.notificationDate).format('DD.MM.YYYY HH:mm:ss');
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
        },
        {
            title: geti18nText('devices.notifications.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return <div>{geti18nText('enum.deviceNotificationType.' + record.type)}</div>;
            },
            ...getColumnSearchOption(ENUMS_DEVICE_NOTIFICATION_TYPE()),
        },
        {
            title: geti18nText('devices.notifications.table.column.value'),
            dataIndex: 'value',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return (
                    <div>{record.value ? geti18nText('app.default.active') : geti18nText('app.default.inactive')}</div>
                );
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        },
    ];

    return (
        <>
            <Modal
                visible={props.visible}
                title={editHeader}
                okText={geti18nText('app.default.button.save')}
                onCancel={(value: any) => closePopup(value)}
                onOk={save}
                width="90vw"
                footer={getButtons()}
            >
                <Form form={form} layout="vertical">
                    {fetching ? (
                        <NySpinner />
                    ) : (
                        <Tabs type="card" activeKey={activeTab} onChange={(tab: any) => setActiveTab(tab)}>
                            <TabPane tab={geti18nText('devices.tab.general')} key="1">
                                <React.Fragment>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="company" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="project" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    {!NySession.hasAnyRole(['ROLE_SUPERADMIN']) && (
                                        <>
                                            <Form.Item name="ipAddress" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="routerSnr" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="simCardSnr" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="minIp" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="maxIp" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="minTp" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="maxTp" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="updateWithSd" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="lastActivity" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="lastSync" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="timezone" style={{ display: 'none' }}>
                                                <Input />
                                            </Form.Item>
                                        </>
                                    )}
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('devices.edit.mac')}
                                                name="macAddress"
                                                rules={[{ validator: validateMacAddressTaken }]}
                                            >
                                                <Input />
                                                {/* <MaskedInput mask="**:**:**:**:**:**" size="large" /> */}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('devices.edit.name')}
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('devices.validation.name'),
                                                    },
                                                    { validator: validateNameTaken },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label={geti18nText('lights.edit.substation')} name="substation">
                                                <NySearchField
                                                    url={CONSTANTS_REQ.SUBSTATION.SEARCH}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="substationId"
                                                    disabled={false}
                                                    addedFilter={{
                                                        field: 'project_id',
                                                        condition: 'equals',
                                                        value: project,
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {NySession.hasAnyRole(['ROLE_SUPERADMIN']) && (
                                            <>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={geti18nText('devices.edit.ipAddress')}
                                                        name="ipAddress"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={geti18nText('devices.edit.router_snr')}
                                                        name="routerSnr"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={geti18nText('devices.edit.sim_card_snr')}
                                                        name="simCardSnr"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('devices.edit.min.ip')} name="minIp">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('devices.edit.max.ip')} name="maxIp">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('devices.edit.min.tp')} name="minTp">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('devices.edit.max.tp')} name="maxTp">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={geti18nText('devices.edit.calc.sd')}
                                                        name="updateWithSd"
                                                        valuePropName={'checked'}
                                                    >
                                                        <Checkbox></Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    {NySession.hasAnyRole(['ROLE_SUPERADMIN']) && (
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('devices.edit.lastActivity')}
                                                    name="lastActivity"
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label={geti18nText('devices.edit.lastSync')} name="lastSync">
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label={geti18nText('devices.edit.zone')} name="timezone">
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </React.Fragment>
                                <Modal
                                    visible={deactivateVisible}
                                    title={geti18nText('devices.validation.mac.modal.title')}
                                    okText={geti18nText('app.default.button.ok')}
                                    onCancel={closeDeactivatePopup}
                                    onOk={deactivate}
                                >
                                    {geti18nText('devices.validation.mac.modal.text')}
                                </Modal>
                            </TabPane>
                            {hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) && (
                                <TabPane
                                    tab={geti18nText('devices.tab.management')}
                                    key="2"
                                    disabled={isCreate() || loading}
                                >
                                    <DeviceManagement deviceId={device} />
                                </TabPane>
                            )}
                            <TabPane tab={geti18nText('devices.tab.graph')} key="3" disabled={isCreate() || loading}>
                                <DeviceDataChart
                                    power_url={CONSTANTS_REQ.DEVICE_DATA.LIST_GRAPH_HD_SD}
                                    energy_url={CONSTANTS_REQ.DEVICE_DATA.LIST_GRAPH}
                                    exportGraph={exportGraph}
                                    addedData={{ deviceId: device }}
                                    setRefreshInterval={setRefreshChartInterval}
                                ></DeviceDataChart>
                            </TabPane>
                            {hasAnyCompanyTier(['PRO'], NySession.getProjectInfo()) && (
                                <TabPane
                                    tab={geti18nText('devices.tab.lights')}
                                    key="4"
                                    disabled={isCreate() || loading}
                                >
                                    <DeviceLightHealthCheck
                                        addedData={{ deviceId: device, deviceName: deviceName }}
                                    ></DeviceLightHealthCheck>
                                </TabPane>
                            )}
                            {hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) && (
                                <TabPane tab={geti18nText('devices.tab.map')} key="5" disabled={isCreate() || loading}>
                                    <OverviewIndex
                                        addedData={{ deviceId: device, substationId: substation, mapHeight: 550 }}
                                        setRefreshInterval={setRefreshOverviewInterval}
                                    ></OverviewIndex>
                                </TabPane>
                            )}
                            {NySession.hasAnyRole(['ROLE_SUPERADMIN']) && (
                                <>
                                    <TabPane
                                        tab={geti18nText('devices.tab.deviceActivity')}
                                        key="6"
                                        disabled={isCreate() || loading}
                                    >
                                        <NyDataTable
                                            url={CONSTANTS_REQ.DEVICE.ACTIVITY}
                                            readonly={true}
                                            hideButtons={true}
                                            setDefaultFilterValue={setDefaultFilterValue}
                                            scroll={{ y: 10000, x: 400 }}
                                            columns={activityColumns}
                                        />
                                    </TabPane>
                                </>
                            )}
                            {hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) && (
                                <TabPane
                                    tab={geti18nText('devices.tab.programs')}
                                    key="8"
                                    disabled={isCreate() || loading}
                                >
                                    <ProgramIndex
                                        deviceId={device}
                                        selectedRows={programs}
                                        setSelectedRows={setPrograms}
                                    />
                                </TabPane>
                            )}
                            {NySession.hasAnyRole(['ROLE_SUPERADMIN']) && (
                                <TabPane
                                    tab={geti18nText('devices.tab.deviceNotifications')}
                                    key="9"
                                    disabled={isCreate() || loading}
                                >
                                    <NyDataTable
                                        url={CONSTANTS_REQ.DEVICE.NOTIFICATIONS}
                                        readonly={true}
                                        hideButtons={true}
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        scroll={{ y: 10000, x: 400 }}
                                        columns={notificationColumns}
                                    />
                                </TabPane>
                            )}
                            {hasAnyCompanyTier(['COMPACT', 'PRO'], NySession.getProjectInfo()) && (
                                <>
                                    <TabPane
                                        tab={geti18nText('devices.tab.deviceStatus')}
                                        key="10"
                                        disabled={isCreate() || loading}
                                    >
                                        <DeviceStatus deviceId={device} />
                                    </TabPane>
                                    <TabPane
                                        tab={geti18nText('devices.tab.additionalData')}
                                        key="11"
                                        disabled={isCreate() || loading}
                                    >
                                        <DeviceAdditionalData addedData={{ deviceId: device }}></DeviceAdditionalData>
                                    </TabPane>
                                </>
                            )}
                        </Tabs>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default DeviceEdit;

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, notification, Row, Table, Tabs } from 'antd';
import { useState } from 'react';
import { COMPANY_TIER } from '../../utils/Constants';

const CompanyAccountSettingsIndex = () => {
    const [loading, setLoading] = useState(false);
    const [selectedView, setSelectedView] = useState(1);
    const [selectedTier, setSelectedTier] = useState(
        NySession.getUser() != null && NySession.getUser().company != null && NySession.getUser().company.tier
            ? NySession.getUser().company.tier
            : 'LITE'
    );

    function error(message: any) {
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: message,
            duration: 0,
        });
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const tierChange = () => {
        // setLoading(true);
        // const body = { tier: selectedTier };
        // NyRequestResolver.requestPost(CONSTANTS_REQ.COMPANY_TIER_CHANGE.TIER_CHANGE, undefined, body).then(
        //     (result: any) => {
        //         if (result.status === RESPONSE.CREATED) {
        //             okNotification();
        //         } else {
        //             if (result.data && result.data.error) {
        //                 error(JSON.stringify(result.data.error));
        //             }
        //         }
        //         setLoading(false);
        //     }
        // );
    };

    const onCell = (record: any, index: number) => {
        if (record.category) {
            return { colspan: 4 };
        }
        return {};
    };

    const columns: any = [
        {
            onCell: onCell,
            width: '25%',
            title: <></>,
            render: (text: any, record: any) => {
                if (record.category) {
                    return <b>{record.value}</b>;
                } else {
                    return <div style={{ paddingLeft: '20px' }}>{record.value}</div>;
                }
            },
        },
        {
            onCell: onCell,
            width: '25%',
            title: (
                <div style={{ display: 'inline', verticalAlign: 'middle' }}>
                    <Checkbox
                        key={'cb_lite'}
                        onClick={(e) => setSelectedTier(COMPANY_TIER.LITE)}
                        checked={selectedTier == COMPANY_TIER.LITE}
                    ></Checkbox>
                    <span style={{ marginLeft: '10px' }}>
                        <b>{geti18nText('enum.companyTier.' + COMPANY_TIER.LITE)}</b>
                    </span>
                    <Button onClick={(e) => setSelectedTier(COMPANY_TIER.LITE)} style={{ float: 'right' }}>
                        {geti18nText('app.default.button.select')}
                    </Button>
                </div>
            ),
            render: (text: any, record: any) => {
                if (!record.category)
                    if (record.lite) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
            },
        },
        {
            onCell: onCell,
            width: '25%',
            title: (
                <div style={{ display: 'inline', verticalAlign: 'middle' }}>
                    <Checkbox
                        key={'cb_compact'}
                        onClick={(e) => setSelectedTier(COMPANY_TIER.COMPACT)}
                        checked={selectedTier == COMPANY_TIER.COMPACT}
                    ></Checkbox>
                    <span style={{ marginLeft: '10px' }}>
                        <b>{geti18nText('enum.companyTier.' + COMPANY_TIER.COMPACT)}</b>
                    </span>
                    <Button onClick={(e) => setSelectedTier(COMPANY_TIER.COMPACT)} style={{ float: 'right' }}>
                        {geti18nText('app.default.button.select')}
                    </Button>
                </div>
            ),
            render: (text: any, record: any) => {
                if (!record.category)
                    if (record.compact) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
            },
        },
        {
            onCell: onCell,
            width: '25%',
            title: (
                <div style={{ display: 'inline', verticalAlign: 'middle' }}>
                    <Checkbox
                        key={'cb_pro'}
                        onClick={(e) => setSelectedTier(COMPANY_TIER.PRO)}
                        checked={selectedTier == COMPANY_TIER.PRO}
                    ></Checkbox>
                    <span style={{ marginLeft: '10px' }}>
                        <b>{geti18nText('enum.companyTier.' + COMPANY_TIER.PRO)}</b>
                    </span>
                    <Button onClick={(e) => setSelectedTier(COMPANY_TIER.PRO)} style={{ float: 'right' }}>
                        {geti18nText('app.default.button.select')}
                    </Button>
                </div>
            ),
            render: (text: any, record: any) => {
                if (!record.category)
                    if (record.pro) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
            },
        },
    ];

    const sideData: any = [
        {
            value: geti18nText('companyTierChange.tierDescription.group.projects'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.projects'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.currentProject'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.subprojects'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.overview'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.substations'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.devices'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programs'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.loads'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.reports'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.projectAccounts'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.users'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.users'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.privacyPolicy'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.privacyPolicy'),
            lite: true,
            compact: true,
            pro: true,
        },
    ];

    const projectData: any = [
        {
            value: geti18nText('companyTierChange.tierDescription.group.overview'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.overviewSubstations'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.overviewLoads'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.substations'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.substations'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.devices'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.devices'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceSwitch'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.programs'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programsAstro'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programsUser'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programsIOMatrix'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.loads'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.loadMonitoring'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.reports'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.reportsPower'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.reportsEnergy'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.accounts'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.projectAccounts'),
            lite: true,
            compact: true,
            pro: true,
        },
    ];

    const deviceData: any = [
        {
            value: geti18nText('companyTierChange.tierDescription.group.general'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceMac'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceName'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceSubstation'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.management'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.managementStatus'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.managementManagement'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.managementAdditional'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.graphData'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceViewByPhases'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceOBIS'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceHDSD'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceDateFiltering'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.devicePowerGraph'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceExpectedPower'),
            lite: true,
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.loads'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.loadList'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.loadHealthCheck'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.map'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceMapBox'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.deviceMapLoad'),
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.programs'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programsList'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.programsAdd'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.activeStatus'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.activeStatus'),
            compact: true,
            pro: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.group.additionalData'),
            category: true,
        },
        {
            value: geti18nText('companyTierChange.tierDescription.description.additionalData'),
            compact: true,
            pro: true,
        },
    ];

    return (
        <>
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            dataSource={selectedView == 1 ? sideData : selectedView == 2 ? projectData : deviceData}
                            pagination={false}
                            scroll={{ y: 10000, x: 800 }}
                            sticky={true}
                            summary={() => (
                                <Table.Summary fixed="top">
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={4} className="no-padding">
                                            <Row>
                                                <Col
                                                    onClick={() => setSelectedView(1)}
                                                    span={8}
                                                    className="cell-summary-padded"
                                                    style={{
                                                        ...(selectedView == 1
                                                            ? {
                                                                  borderBottom: '2px solid #56b7e8',
                                                                  color: '#56b7e8',
                                                                  backgroundColor: '#82d2f5',
                                                              }
                                                            : {
                                                                  backgroundColor: '#56b7e8',
                                                              }),
                                                        ...{
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <b>
                                                        {geti18nText(
                                                            'companyTierChange.tierDescription.view.side'
                                                        ).toUpperCase()}
                                                    </b>
                                                </Col>
                                                <Col
                                                    onClick={() => setSelectedView(2)}
                                                    span={8}
                                                    className="cell-summary-padded"
                                                    style={{
                                                        ...(selectedView == 2
                                                            ? {
                                                                  borderBottom: '2px solid #56b7e8',
                                                                  color: '#56b7e8',
                                                                  backgroundColor: '#82d2f5',
                                                              }
                                                            : {
                                                                  backgroundColor: '#56b7e8',
                                                              }),
                                                        ...{
                                                            borderRight: '1px solid #e0ecef',
                                                            borderLeft: '1px solid #e0ecef',
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <b>
                                                        {geti18nText(
                                                            'companyTierChange.tierDescription.view.project'
                                                        ).toUpperCase()}
                                                    </b>
                                                </Col>
                                                <Col
                                                    onClick={() => setSelectedView(3)}
                                                    span={8}
                                                    className="cell-summary-padded"
                                                    style={{
                                                        ...(selectedView == 3
                                                            ? {
                                                                  borderBottom: '2px solid #56b7e8',
                                                                  color: '#56b7e8',
                                                                  backgroundColor: '#82d2f5',
                                                              }
                                                            : {
                                                                  backgroundColor: '#56b7e8',
                                                              }),
                                                        ...{
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <b>
                                                        {geti18nText(
                                                            'companyTierChange.tierDescription.view.device'
                                                        ).toUpperCase()}
                                                    </b>
                                                </Col>
                                            </Row>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        ></Table>
                    </Col>
                </Row>
                <div className="ant-row buttons-sticky">
                    <div className="ant-col ant-col-4 ant-col-offset-20">
                        <Button
                            type="primary"
                            onClick={() => tierChange()}
                            disabled={loading || !selectedTier}
                            style={{ float: 'right' }}
                        >
                            {geti18nText('companyTierChange.button')}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default CompanyAccountSettingsIndex;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

const SetActive = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [active, setActive] = useState('true');

    useEffect(() => {
        setHasSelected(props.hasSelected);
        setSelectedRowKeys(props.selectedRowKeys);
    }, [props]);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = (e: any) => {
        setVisible(false);
    };

    const onChangeActive = (value: any) => {
        setActive(value);
    };

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        NyRequestResolver.requestPost(CONSTANTS_REQ.LIGHT.SET_ACTIVE, undefined, {
            projectId: NySession.getProjectId(),
            lights: selectedRowKeys,
            active: active,
        }).then((result) => {
            if (result.status === RESPONSE.CREATED) {
                props.onPopupSave();
                setSelectedRowKeys([]);
                setHasSelected(false);
                setVisible(false);
                okNotification();
            }
        });
    };

    return (
        <React.Fragment>
            <Button disabled={!hasSelected} type="primary" onClick={showModal} style={{ marginLeft: '10px' }}>
                {geti18nText('lights.table.setActive')}
            </Button>

            <Modal
                title={geti18nText('lights.table.setActive')}
                visible={visible}
                onCancel={handleCancel}
                onOk={onSubmit}
                okText={geti18nText('lights.table.setActive')}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Form.Item label={geti18nText('lights.edit.status')}>
                        <Select onChange={onChangeActive} defaultValue={active}>
                            <Select.Option value="true">{geti18nText('lights.edit.status.activate')}</Select.Option>
                            <Select.Option value="false">{geti18nText('lights.edit.status.deactivate')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default SetActive;

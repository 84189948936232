import {
    ApartmentOutlined,
    AppstoreOutlined,
    BankOutlined,
    BarsOutlined,
    BulbOutlined,
    CodeSandboxOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    IdcardOutlined,
    MailOutlined,
    PieChartOutlined,
    ProjectOutlined,
    SafetyCertificateOutlined,
    ScheduleOutlined,
    SettingOutlined,
    SolutionOutlined,
    UserOutlined,
} from '@ant-design/icons';

export const projectMenu = [
    {
        key: 'menu.projects',
        path: '/project',
        icon: <ProjectOutlined />,
        i18n: 'dashboard.tab.projects',
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
    },
    {
        key: 'menu.project',
        path: '/projects',
        icon: <FolderOpenOutlined />,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        submenu: [
            {
                key: 'menu.project.subprojects',
                path: '/project/subprojects',
                icon: <FolderOutlined />,
                i18n: 'menu.subprojects',
                role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
                tier: ['PRO'],
            },
            {
                key: 'menu.project.overview',
                path: '/project/overview',
                icon: <PieChartOutlined />,
                i18n: 'menu.overview',
                roleProject: ['ROLE_PROJECT_ADMIN', 'ROLE_PROJECT_READONLY'],
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.project.cabinets',
                path: '/project/cabinets',
                icon: <ApartmentOutlined />,
                i18n: 'menu.substations',
                roleProject: ['ROLE_PROJECT_ADMIN', 'ROLE_PROJECT_READONLY'],
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.project.devices',
                path: '/project/devices',
                icon: <CodeSandboxOutlined />,
                i18n: 'menu.devices',
                roleProject: ['ROLE_PROJECT_ADMIN', 'ROLE_PROJECT_READONLY'],
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.project.programs',
                path: '/project/programs',
                icon: <ScheduleOutlined />,
                i18n: 'menu.programs',
                roleProject: ['ROLE_PROJECT_ADMIN'],
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.project.lights',
                path: '/project/lights',
                icon: <BulbOutlined />,
                i18n: 'menu.lights',
                roleProject: ['ROLE_PROJECT_ADMIN', 'ROLE_PROJECT_READONLY'],
                role: ['ROLE_SUPERADMIN'],
                tier: ['PRO'],
            },
            {
                key: 'menu.project.reports',
                path: '/project/reports',
                icon: <FileTextOutlined />,
                i18n: 'menu.reports',
                roleProject: ['ROLE_PROJECT_ADMIN', 'ROLE_PROJECT_READONLY'],
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.project.accounts',
                path: '/project/accounts',
                icon: <UserOutlined />,
                i18n: 'menu.accounts',
                roleProject: ['ROLE_PROJECT_ADMIN'],
                role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
            },
            {
                key: 'menu.project.notifications',
                path: '/project/notifications',
                icon: <MailOutlined />,
                i18n: 'menu.notification.settings',
                roleProject: ['ROLE_PROJECT_ADMIN'],
                role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
            },
            {
                key: 'menu.project.settings',
                path: '/project/settings',
                icon: <SettingOutlined />,
                i18n: 'menu.project.settings',
                role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
            },
        ],
    },
    {
        key: 'menu.accessToken',
        path: '/accessToken',
        icon: <IdcardOutlined />,
        i18n: 'menu.accessToken',
        role: ['ROLE_ACCESS_USER'],
    },
    {
        key: 'menu.users',
        path: '/user',
        icon: <UserOutlined />,
        i18n: 'dashboard.tab.users',
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
    },
    {
        key: 'menu.company',
        path: '/company',
        icon: <BankOutlined />,
        i18n: 'menu.companies',
        role: ['ROLE_SUPERADMIN', 'ROLE_ACCOUNT_ADMIN'],
    },
    {
        key: 'menu.company.account.settings',
        path: '/companyAccountSettings',
        icon: <SettingOutlined />,
        i18n: 'menu.company.account.settings',
        role: ['ROLE_COMPANY_ACCOUNT_HOLDER'],
    },
    {
        key: 'menu.administration',
        path: '/admin',
        icon: <AppstoreOutlined />,
        i18n: 'menu.administration',
        role: ['ROLE_SUPERADMIN', 'ROLE_ACCOUNT_ADMIN', 'ROLE_FINANCE_ADMIN'],
        submenu: [
            {
                key: 'menu.registration.requests',
                path: '/companyRegistration',
                icon: <SolutionOutlined />,
                i18n: 'menu.registration.requests',
                role: ['ROLE_ACCOUNT_ADMIN'],
            },
            {
                key: 'menu.tier.change.requests',
                path: '/companyTierChange',
                icon: <BarsOutlined />,
                i18n: 'menu.tier.change.requests',
                role: ['ROLE_FINANCE_ADMIN'],
            },
            {
                key: 'menu.database',
                path: '/admin/database',
                icon: <DatabaseOutlined />,
                i18n: 'menu.database',
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.reports',
                path: '/admin/report',
                icon: <FileTextOutlined />,
                i18n: 'dashboard.tab.reports',
                role: ['ROLE_SUPERADMIN'],
            },
            {
                key: 'menu.settings',
                path: '/settings',
                icon: <SettingOutlined />,
                i18n: 'menu.settings',
                role: ['ROLE_SUPERADMIN'],
            },
        ],
    },
    {
        key: 'menu.privacyPolicy',
        icon: <SafetyCertificateOutlined />,
        i18n: 'privacy.policy',
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
    },
];

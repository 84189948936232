import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, notification, Popconfirm } from 'antd';
import { ReactText } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

let clearKeys: any;

const AddToProject = (
    hasSelected: boolean,
    selectedRowKeys: ReactText[],
    selectedRows: any,
    onPopupSave: () => void,
    clearSelectedRowKeys: () => void
) => {
    clearKeys = clearSelectedRowKeys;

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        NyRequestResolver.requestPost(CONSTANTS_REQ.PROJECT.ADD_USER, undefined, {
            projectId: NySession.getProjectId(),
            users: selectedRowKeys,
        }).then((result) => {
            if (result.status === RESPONSE.CREATED) {
                onPopupSave();
                okNotification();
                if (clearKeys) {
                    clearKeys();
                }
            }
        });
    };

    return (
        <Popconfirm title={geti18nText('app.default.add.confirm')} onConfirm={onSubmit}>
            <Button disabled={!hasSelected} type="primary">
                {geti18nText('app.default.button.add')}
            </Button>
        </Popconfirm>
    );
};

export default AddToProject;

import { getColumnSearch, geti18nText, NyDataTable, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, notification } from 'antd';
import React, { ReactText, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

const ProgramDevicesIndex = (props: any) => {
    const { program, notDevices, refresh, setRefresh } = props;
    const [loading, setLoading] = useState(false);

    let clearKeys: any;

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    const rowSelectionModals = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        clearKeys = clearSelectedRowKeys;

        function okNotification() {
            notification.success({
                message: geti18nText('app.default.save.ok'),
                description: geti18nText('app.default.save.ok.desc'),
                duration: 3,
            });
        }

        function infoNotification(info: any) {
            notification.info({
                message: '',
                description: info,
                duration: 5,
            });
        }

        function addRemoveDevices(add: boolean, selectedAll: boolean) {
            setLoading(true);
            const body = {
                projectId: NySession.getProjectId(),
                programId: program,
                devices: !selectedAll ? selectedRowKeys : undefined,
                allDevices: selectedAll,
            };
            NyRequestResolver.requestPost(
                add ? CONSTANTS_REQ.PROGRAM.ADD_DEVICES : CONSTANTS_REQ.PROGRAM.REMOVE_DEVICES,
                undefined,
                body
            ).then((result) => {
                setLoading(false);
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    const data: any = result.data;
                    if (data && data.message && data.message == 'device_and_program_type_exist') {
                        infoNotification(geti18nText('programs.notification.device.skipped'));
                    }
                    clearKeys();
                    if (refresh != undefined && setRefresh) {
                        setRefresh(refresh + 1);
                    }
                }
            });
        }

        return notDevices ? (
            <>
                <Button
                    key="addDevices"
                    disabled={selectedRowKeys.length == 0 || loading}
                    onClick={() => addRemoveDevices(true, false)}
                >
                    {geti18nText('programs.edit.addDevices')}
                </Button>
                <Button
                    style={{ marginLeft: '10px' }}
                    key="addAllDevices"
                    disabled={loading}
                    onClick={() => addRemoveDevices(true, true)}
                >
                    {geti18nText('programs.edit.addAllDevices')}
                </Button>
            </>
        ) : (
            <>
                <Button
                    key="removeDevices"
                    disabled={selectedRowKeys.length == 0 || loading}
                    onClick={() => addRemoveDevices(false, false)}
                >
                    {geti18nText('programs.edit.removeDevices')}
                </Button>
                <Button
                    style={{ marginLeft: '10px' }}
                    key="removeAllDevices"
                    disabled={loading}
                    onClick={() => addRemoveDevices(false, true)}
                >
                    {geti18nText('programs.edit.removeAllDevices')}
                </Button>
            </>
        );
    };

    return (
        <>
            <NyDataTable
                url={CONSTANTS_REQ.PROGRAM.DEVICES}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={false}
                showRowSelection={true}
                selectOnSave={false}
                readonly={true}
                scroll={{ y: 10000, x: 800 }}
                fetchWhenChange={refresh}
                setDefaultFilterValue={setDefaultFilterValue}
                addedData={{
                    programId: program,
                    notDevices: notDevices,
                }}
                rowSelectionModal={rowSelectionModals}
                hideButtons={true}
                columns={[
                    {
                        title: geti18nText('devices.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('devices.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('devices.table.column.mac'),
                        dataIndex: 'macAddress',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('devices.table.column.substationId'),
                        dataIndex: ['substation', 'substationId'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    ...(!NySession?.getProjectInfo()?.parent?.id
                        ? [
                              {
                                  title: geti18nText('devices.table.column.project'),
                                  dataIndex: ['project', 'projectName'],
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnSearch('string'),
                              },
                          ]
                        : []),
                ]}
            />
        </>
    );
};

export default ProgramDevicesIndex;

import { getColumnSearch, geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Divider, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { byteFormat, numberFormat } from '../../utils/Utils';

const DatabaseIndex = () => {
    const [dbTableData, setDbTableData] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState<any>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });

    useEffect(() => {
        getDatabaseTables();
    }, []);

    const getDatabaseTables = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SYSTEM.DATABASE_TABLES, {}).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.CREATED) {
                setDbTableData(result.data);
            }
        });
    };

    const onFilter = (value: any, record: any) => {
        if (value && value.value) {
            if (value.condition === 'contains') {
                return record.toLowerCase().includes(value.value.toLowerCase());
            } else if (value.condition === 'starts_with') {
                return record.toLowerCase().startsWith(value.value.toLowerCase());
            } else if (value.condition === 'ends_with') {
                return record.toLowerCase().endsWith(value.value.toLowerCase());
            } else if (value.condition === 'equals_string') {
                return record.toLowerCase() === value.value.toLowerCase();
            } else if (value.condition === 'not_equals_string') {
                return record.toLowerCase() !== value.value.toLowerCase();
            } else if (value.condition === 'equals_float') {
                return Number(record) === Number(value.value);
            } else if (value.condition === 'not_equals_float') {
                return Number(record) !== Number(value.value);
            }
        }
        return true;
    };

    const dbTableColumns: any = [
        {
            title: geti18nText('system.databaseTable.table.column.schemaName'),
            dataIndex: 'schemaName',
            sorter: (a: any, b: any) => a.schemaName.localeCompare(b.schemaName),
            ...getColumnSearch('string'),
            onFilter: (value: any, record: any) => onFilter(value, record.schemaName),
        },
        {
            title: geti18nText('system.databaseTable.table.column.tableName'),
            dataIndex: 'tableName',
            sorter: (a: any, b: any) => a.tableName.localeCompare(b.tableName),
            ...getColumnSearch('string'),
            onFilter: (value: any, record: any) => onFilter(value, record.tableName),
        },
        {
            title: geti18nText('system.databaseTable.table.column.dataSize'),
            dataIndex: 'dataSize',
            sorter: (a: any, b: any) => a.dataSize - b.dataSize,
            ...getColumnSearch('number'),
            onFilter: (value: any, record: any) => onFilter(value, record.dataSize),
            render: (text: any, record: any) => {
                return byteFormat(record.dataSize ? record.dataSize : 0, 0);
            },
            align: 'right',
        },
    ];

    return (
        <React.Fragment>
            <Divider orientation="left" plain>
                <Title level={5}>{geti18nText('system.databaseTable.table.header')}</Title>
            </Divider>

            <Table
                rowKey={(record: any) => record.schemaName + '.' + record.tableName}
                dataSource={dbTableData}
                columns={dbTableColumns}
                size="small"
                pagination={pagination}
                scroll={{ y: 10000, x: 800 }}
            />
        </React.Fragment>
    );
};

export default DatabaseIndex;

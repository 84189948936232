import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import UserEdit from './edit';

const UserIndex = () => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <NyDataTable
            url={CONSTANTS_REQ.ADMIN.USERS_LIST}
            headerTitle={geti18nText('users.table.header')}
            addNewButtonText={geti18nText('users.table.add')}
            buttonsClassName={'buttons-sticky'}
            showRecordModal={true}
            modalComponent={UserEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            scroll={{ y: 10000, x: 800 }}
            columns={[
                {
                    title: geti18nText('users.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('users.table.column.username'),
                    dataIndex: 'username',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.last_name'),
                    dataIndex: 'last_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.first_name'),
                    dataIndex: 'first_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.role'),
                    dataIndex: 'role',
                },
                {
                    title: geti18nText('users.edit.company'),
                    dataIndex: ['company', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default UserIndex;

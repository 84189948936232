import { useState, useEffect } from 'react';
import { Col, Row, Select, Statistic, Tabs, Button, DatePicker, Switch } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import { geti18nText, NyRequestResolver, NySearchField, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { downloadFile } from '../../utils/Utils';
import MyComposedChart from '../../components/composed-chart';
import MyLineChart from '../../components/line-chart';
import LightStatusActivityList from './LightStatusActivityList';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProjectReportIndex = (props: any) => {
    const [params, setParams] = useState<any>({});
    const [selected, setSelected] = useState<any>(null);
    const [device, setDevice] = useState<any>(null);
    const [substations, setSubstations] = useState<any[]>([]);
    const [chartPower, setChartPower] = useState<any>(null);
    const [chartEnergy, setChartEnergy] = useState<any>(null);
    const [energyMax, setEnergyMax] = useState<any>(null);
    const [powerMax, setPowerMax] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [reportLoading, setReportLoading] = useState<any>(false);
    const [selectedYearDate, setSelectedYearDate] = useState<any>(moment());
    const [isYearOpen, setIsYearOpen] = useState<any>(false);

    useEffect(() => {
        onTimeRangeChange('this-month');
    }, []);

    useEffect(() => {
        if (params && params.period) {
            fetch();
        }
    }, [params]);

    function fetch() {
        setChartPower([]);
        setChartEnergy([]);
        setPowerMax(0);
        setEnergyMax(0);
        if (
            params &&
            (params.period !== 'span' ||
                (params.period === 'span' && params.timestampFrom !== null && params.timestampTo !== null))
        ) {
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.REPORT.CONSUMPTION_POWER, {
                ...params,
                ...{ projectId: NySession.getProjectId() },
                lang: NyUtils.getSelectedLanguage(),
            }).then((response: any) => {
                setLoading(false);
                if (response.status === RESPONSE.OK) {
                    setChartPower(response.data[0]);
                    setChartEnergy(response.data[1]);
                    setPowerMax(response.data[2] ? response.data[2] : 0);
                    setEnergyMax(response.data[3]);
                }
            });
        }
    }

    function setDeviceNull() {
        return props.addedData && props.addedData.deviceId ? props.addedData.deviceId : null;
    }

    function handlePanelChange(value: any) {
        setSelectedYearDate(value);
        setIsYearOpen(false);
        if (value) {
            const year = value.year();
            setParams((prevState: any) => {
                return { ...prevState, year: year };
            });
        }
    }

    function handleOpenChange(status: any) {
        if (status) {
            setIsYearOpen(true);
        } else {
            setIsYearOpen(false);
        }
    }

    function clearValue() {
        setSelectedYearDate(moment());
        setIsYearOpen(false);
        const year = moment().year();
        setParams((prevState: any) => {
            return { ...prevState, year: year };
        });
    }

    function onTimeRangeChange(value: any) {
        setSelected(value);
        setDevice(setDeviceNull());
        setParams((prevState: any) => {
            return {
                ...prevState,
                timestampFrom: null,
                timestampTo: null,
                year: null,
                deviceId: setDeviceNull(),
                period: value,
            };
        });
    }

    function onRangeChange(dates: any) {
        const timestampFrom = dates[0] ? moment(dates[0]).startOf('day').valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).endOf('day').valueOf() : null;
        setParams((prevState: any) => {
            return { ...prevState, timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
    }

    function onDeviceChange(device: any) {
        setDevice(device);
        setParams((prevState: any) => {
            return { ...prevState, deviceId: device ? device.id : null };
        });
    }

    function onSubstationChange(substations: any) {
        setSubstations(substations);
        let substationParam = substations.map((substation: any) => {
            return substation.id;
        });
        setParams((prevState: any) => {
            return {
                ...prevState,
                substations: substationParam && substationParam.length > 0 ? substationParam : null,
            };
        });
    }

    function downloadMeasurementReport(reportType: any) {
        setReportLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.REPORT.MEASUREMENT_REPORT,
            {
                ...params,
                lang: NyUtils.getSelectedLanguage(),
                projectId: NySession.getProjectId(),
                reportType: reportType,
                period: selected,
            },
            false,
            true
        ).then((result: any) => {
            setReportLoading(false);
            if (result.status === RESPONSE.OK && downloadFile(result, reportType + '-xls.xlsx')) {
            } else {
                console.log(JSON.stringify(result.data.error));
            }
        });
    }

    const MeasurementReportButton = (reportType: any) => {
        return (
            <Button
                style={{ marginLeft: 10 }}
                type="primary"
                onClick={() => downloadMeasurementReport(reportType)}
                disabled={
                    reportLoading ||
                    (selected === 'span' && (params.timestampFrom === null || params.timestampTo === null))
                }
            >
                {geti18nText('report.consumption.download')}
            </Button>
        );
    };

    function setDefaultFilterValue() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_parent_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    }

    return (
        <Tabs type="card">
            <TabPane tab={geti18nText('report.tab.consumption')} key="1">
                <Row gutter={24}>
                    <Col xs={24} md={12} xl={8}>
                        {geti18nText('report.consumption.period')}
                        <Select
                            value={selected}
                            placeholder={geti18nText('devices.graph.filter.span')}
                            style={{ width: '100%' }}
                            onChange={onTimeRangeChange}
                        >
                            <Option value="initial">{geti18nText('report.time_range.initial')}</Option>
                            <Option value="last-24h">{geti18nText('report.time_range.last-24h')}</Option>
                            <Option value="last-week">{geti18nText('report.time_range.last-week')}</Option>
                            <Option value="this-week">{geti18nText('report.time_range.this-week')}</Option>
                            <Option value="last-month">{geti18nText('report.time_range.last-month')}</Option>
                            <Option value="this-month">{geti18nText('report.time_range.this-month')}</Option>
                            <Option value="last-year">{geti18nText('report.time_range.last-year')}</Option>
                            <Option value="span">{geti18nText('report.time_range.span')}</Option>
                        </Select>
                    </Col>
                    <Col xs={24} md={12} xl={8} hidden={selected == 'initial'}>
                        *{geti18nText('report.consumption.substation')}
                        <NySearchField
                            mode="multiple"
                            value={substations}
                            setDefaultFilterValue={setDefaultFilterValue}
                            onChange={onSubstationChange}
                            style={{ width: '100%' }}
                            url={CONSTANTS_REQ.SUBSTATION.SEARCH}
                            map={{ id: 'id', label: 'text', project: 'project' }}
                            searchBy="substationId"
                            renderOption={(option: any) => (
                                <span style={{ display: 'block' }}>
                                    {option.text}
                                    {option.project && option.project.projectName
                                        ? ' (' + option.project.projectName + ')'
                                        : ''}
                                </span>
                            )}
                            disabled={false}
                        />
                    </Col>
                    <Col xs={24} md={12} xl={8} hidden={selected != 'initial'}>
                        *{geti18nText('report.consumption.device')}
                        <NySearchField
                            setDefaultFilterValue={setDefaultFilterValue}
                            value={device}
                            onChange={onDeviceChange}
                            style={{ width: '100%' }}
                            url={CONSTANTS_REQ.DEVICE.SEARCH}
                            map={{ id: 'id', label: 'text', project: 'project' }}
                            searchBy="name"
                            renderOption={(option: any) => (
                                <span style={{ display: 'block' }}>
                                    {option.text}
                                    {option.project && option.project.projectName
                                        ? ' (' + option.project.projectName + ')'
                                        : ''}
                                </span>
                            )}
                            disabled={false}
                        />
                    </Col>
                    <Col xs={24} md={12} xl={8} hidden={selected != 'span'}>
                        {geti18nText('report.consumption.span')}
                        <br />
                        <RangePicker
                            disabledDate={(current: any) => {
                                return moment().add(-5, 'years') >= current;
                            }}
                            format="DD.MM.YYYY"
                            onChange={onRangeChange}
                        />
                    </Col>
                    <Col xs={24} md={12} xl={8} hidden={selected != 'last-year'}>
                        {geti18nText('report.consumption.year')}
                        <br />
                        <DatePicker
                            disabledDate={(current: any) => {
                                return moment().add(-5, 'years') >= current;
                            }}
                            value={selectedYearDate}
                            open={isYearOpen}
                            mode="year"
                            format="YYYY"
                            onOpenChange={handleOpenChange}
                            onPanelChange={handlePanelChange}
                            onChange={clearValue}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={24}>
                    <Col xs={24} xl={12} style={{ overflowX: 'auto' }}>
                        <MyComposedChart
                            showTooltip={true}
                            loading={loading}
                            height={280}
                            customButton={() => MeasurementReportButton('power')}
                            barColor={'#79daa3'}
                            lineColor={'#ff0000'}
                            title={geti18nText('report.consumption.power')}
                            statistic={
                                <Statistic
                                    title={geti18nText('report.consumption.maximum')}
                                    value={(powerMax ? powerMax : 0) + ' kW'}
                                />
                            }
                            data={chartPower}
                            xAxisDataKey={'converted'}
                            yAxisUnit={' kW'}
                        />
                    </Col>
                    <Col xs={24} xl={12} style={{ overflowX: 'auto' }}>
                        <MyLineChart
                            showTooltip={true}
                            loading={loading}
                            height={280}
                            customButton={() => MeasurementReportButton('energy')}
                            lineColor={'#79daa3'}
                            title={geti18nText('report.consumption.energy')}
                            statistic={
                                <Statistic
                                    title={geti18nText('report.consumption.amount')}
                                    value={(energyMax ? energyMax : 0) + ' kWh'}
                                />
                            }
                            data={chartEnergy}
                            xAxisDataKey={'converted'}
                            yAxisUnit={' kWh'}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={geti18nText('report.tab.lights')} key="2">
                <LightStatusActivityList />
            </TabPane>
        </Tabs>
    );
};

export default ProjectReportIndex;
